import React from 'react';
import {Column} from 'devextreme-react/data-grid';
import {Button, DateBox, TextBox} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import {HeaderFilter} from 'devextreme-react/tree-list';
import {Item} from 'devextreme-react/form';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';

export const gridViewEditFormComponent = (e) => {
    let currentRow = LeopardStaticUIConfig.Global_GridViewCurrentEditRowData;
    if (LDH.IsObjectNull(currentRow) ||
        LDH.IsObjectNull(currentRow.data)) {
        currentRow = null;
    }
    let data = currentRow === null ? "" : currentRow.data[e.name];
    if (LDH.IsValueEmpty(data)) data = "";

    return (
        <TextBox disabled={true} defaultValue={data}></TextBox>
    );
};

export const LeopardGridViewColumnBuilder = (gridDefinition, columnFieldList) => {
    let columnComponent = [];
    if (columnFieldList === undefined || columnFieldList === null || columnFieldList.length === 0) {
        return null;
    }
    for (var colIndex = 0; colIndex < columnFieldList.length; colIndex++) {
        let columnName = columnFieldList[colIndex];
        let columnDef = null;

        columnDef = gridDefinition.columnDefinition.filter(c => {
            return c.columnName === columnName;
        });
        if (columnDef !== undefined && columnDef !== null && columnDef.length > 0) {
            columnDef = columnDef[0];
        }

        let columnType = "string";
        if (columnDef !== null && columnDef.columnType !== undefined &&
            columnDef.columnType !== null && columnDef.columnType !== "") {
            columnType = columnDef.columnType;
        }

        let columnCustomHeader = columnDef.columnName;
        if (columnDef !== null && columnDef.columnCustomHeader !== undefined &&
            columnDef.columnCustomHeader !== null && columnDef.columnCustomHeader !== "") {
            columnCustomHeader = columnDef.columnCustomHeader;
        }

        let allowSorting = true;
        if (columnDef !== null && columnDef.allowSorting !== undefined &&
            columnDef.allowSorting !== null && columnDef.allowSorting !== "") {
            allowSorting = columnDef.allowSorting;
        }

        let allowFiltering = true;
        if (columnDef !== null && columnDef.allowFiltering !== undefined &&
            columnDef.allowFiltering !== null && columnDef.allowFiltering !== "") {
            allowFiltering = columnDef.allowFiltering;
        }

        let allowGrouping = true;
        if (columnDef !== null && columnDef.allowGrouping !== undefined &&
            columnDef.allowGrouping !== null && columnDef.allowGrouping !== "") {
            allowGrouping = columnDef.allowGrouping;
        }

        let allowReordering = true;
        if (columnDef !== null && columnDef.allowReordering !== undefined &&
            columnDef.allowReordering !== null && columnDef.allowReordering !== "") {
            allowReordering = columnDef.allowReordering;
        }

        let allowResizing = true;
        if (columnDef !== null && columnDef.allowResizing !== undefined &&
            columnDef.allowResizing !== null && columnDef.allowResizing !== "") {
            allowResizing = columnDef.allowResizing;
        }

        let isFixed = false;
        if (columnDef !== null && columnDef.isFixed !== undefined &&
            columnDef.isFixed !== null && columnDef.isFixed !== "") {
            isFixed = columnDef.isFixed;
        }

        let columnAlign = "left";
        if (columnDef !== null && columnDef.columnAlign !== undefined &&
            columnDef.columnAlign !== null && columnDef.columnAlign !== "") {
            columnAlign = columnDef.columnAlign;
        }

        let minimumWidth = "50";
        if (columnDef !== null && columnDef.minimumWidth !== undefined &&
            columnDef.minimumWidth !== null && columnDef.minimumWidth !== "") {
            minimumWidth = columnDef.minimumWidth;
        }

        let width = null;
        if (columnDef !== null && columnDef.width !== undefined &&
            columnDef.width !== null && columnDef.width !== "") {
            width = columnDef.width;
        }

        let groupIndex = null;
        if (columnDef !== null && columnDef.groupIndex !== undefined &&
            columnDef.groupIndex !== null && columnDef.groupIndex !== "") {
            groupIndex = columnDef.groupIndex;
        }

        let visibleIndex = colIndex;
        if (columnDef !== null && columnDef.visibleIndex !== undefined &&
            columnDef.visibleIndex !== null && columnDef.visibleIndex !== "") {
            visibleIndex = columnDef.visibleIndex;
        }

        let isVisible = true;
        if (columnDef !== null && columnDef.isVisible !== undefined &&
            columnDef.isVisible !== null && columnDef.isVisible !== "") {
            isVisible = columnDef.isVisible;
        }

        let allowHeaderFilter = true;
        if (columnDef !== null && columnDef.allowHeaderFilter !== undefined &&
            columnDef.allowHeaderFilter !== null && columnDef.allowHeaderFilter !== "") {
            allowHeaderFilter = columnDef.allowHeaderFilter;
        }

        let sortOrder = null;
        if (columnDef !== null && columnDef.sortOrder !== undefined &&
            columnDef.sortOrder !== null && columnDef.sortOrder !== "") {
            sortOrder = columnDef.sortOrder;
        }

        let filterOperations = [];
        if (LDH.IsValueEmpty(columnType) || columnType === "string") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForString;
        } else if (columnType === "number") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "date") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "datetime") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        }
        if (columnDef !== null && columnDef.filterOperations !== undefined &&
            columnDef.filterOperations !== null && columnDef.filterOperations !== "") {
            filterOperations = columnDef.filterOperations;
        }

        let defaultOperation = "";
        if (LDH.IsValueEmpty(columnType) || columnType === "string") {
            defaultOperation = "contains";
        } else if (columnType === "number") {
            defaultOperation = "=";
        } else if (columnType === "date") {
            defaultOperation = "between";
        } else if (columnType === "datetime") {
            defaultOperation = "between";
        }
        if (columnDef !== null && columnDef.defaultOperation !== undefined &&
            columnDef.defaultOperation !== null && columnDef.defaultOperation !== "") {
            defaultOperation = columnDef.defaultOperation;
        }

        let hidingPriority = columnFieldList.length - (colIndex + 1);
        if (gridDefinition.adaptiveColumn === undefined ||
            gridDefinition.adaptiveColumn === null ||
            gridDefinition.adaptiveColumn === false) {
            hidingPriority = null;
        }

        let cssClass = "leopard_gridview_column_template";
        if (allowSorting === true){
            cssClass += " allow-sorting";
        }
        else{
            cssClass += " disallow-sorting";
        }

        if (columnType === "date") {
            columnComponent.push(
                <Column key={colIndex} dataField={columnName} dataType={columnType}
                        format="dd/MM/yyyy" cssClass={cssClass}
                        caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                        filterOperations={filterOperations}
                        allowSorting={allowSorting} allowFiltering={allowFiltering}
                        selectedFilterOperation={defaultOperation}
                        allowGrouping={allowGrouping} allowReordering={allowReordering}
                        allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                        minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                        visibleIndex={visibleIndex} visible={isVisible} sortOrder={sortOrder}
                        hidingPriority={hidingPriority}>
                    <HeaderFilter allowSearch={true}/>
                </Column>
            );
        } else if (columnType === "datetime") {
            columnComponent.push(
                <Column key={colIndex} dataField={columnName} dataType={columnType}
                        format="dd/MM/yyyy HH:mm" cssClass={cssClass}
                        caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                        filterOperations={filterOperations}
                        allowSorting={allowSorting} allowFiltering={allowFiltering}
                        selectedFilterOperation={defaultOperation}
                        allowGrouping={allowGrouping} allowReordering={allowReordering}
                        allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                        minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                        visibleIndex={visibleIndex} visible={isVisible} sortOrder={sortOrder}
                        hidingPriority={hidingPriority}>
                    <HeaderFilter allowSearch={true}/>
                </Column>
            );
        } else {
            columnComponent.push(
                <Column key={colIndex} dataField={columnName} dataType={columnType}
                        cssClass={cssClass}
                        caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                        filterOperations={filterOperations}
                        allowSorting={allowSorting} allowFiltering={allowFiltering}
                        selectedFilterOperation={defaultOperation}
                        allowGrouping={allowGrouping} allowReordering={allowReordering}
                        allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                        minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                        visibleIndex={visibleIndex} visible={isVisible} sortOrder={sortOrder}
                        hidingPriority={hidingPriority}>
                    <HeaderFilter allowSearch={true}/>
                </Column>
            );
        }
    }

    return columnComponent;
};

export const LeopardGridViewEditFormBuilder = (gridDefinition, columnFieldList, disabledFieldsForEditing,
                                               hiddenFieldsForEditing) => {
    let columnComponent = [];
    if (columnFieldList === undefined || columnFieldList === null || columnFieldList.length === 0) {
        return null;
    }
    for (var colIndex = 0; colIndex < columnFieldList.length; colIndex++) {
        let columnName = columnFieldList[colIndex];
        let columnDef = null;

        columnDef = gridDefinition.columnDefinition.filter(c => {
            return c.columnName === columnName;
        });
        if (columnDef !== undefined && columnDef !== null && columnDef.length > 0) {
            columnDef = columnDef[0];
        }

        let columnType = "string";
        if (columnDef !== null && LDH.IsValueEmpty(columnDef.columnType) === false) {
            columnType = columnDef.columnType;
        }

        let visible = true;
        if (!LDH.IsObjectNull(hiddenFieldsForEditing) &&
            hiddenFieldsForEditing.length > 0) {
            for (var i = 0; i < hiddenFieldsForEditing.length; i++) {
                if (hiddenFieldsForEditing[i] === columnName) {
                    visible = false;
                    break;
                }
            }
        }

        let disabled = false;
        if (!LDH.IsObjectNull(disabledFieldsForEditing) &&
            disabledFieldsForEditing.length > 0) {
            for (var j = 0; j < disabledFieldsForEditing.length; j++) {
                if (disabledFieldsForEditing[j] === columnName) {
                    disabled = true;
                    break;
                }
            }
        }

        if (visible === false || disabled === false) {
            columnComponent.push(
                <Item key={colIndex} dataField={columnName} dataType={columnType} visible={visible}></Item>
            );
        } else {
            columnComponent.push(
                <Item key={colIndex} dataField={columnName} dataType={columnType} visible={visible}
                      component={() => gridViewEditFormComponent({name: columnName})}></Item>
            );
        }
    }

    return columnComponent;
};

export const LeopardGridViewToolbar =
    ({
         groupingButtonOnClick, groupingEnabled, addRowButtonOnClick, clearFilterButtonOnClick,
         viewOptionsButtonOnClick, minHeight, gridViewId, exportButtonOnClick, allowEditing,
         refreshButtonOnClick, applyFilterButtonOnClick, showAddButton, explicitFilterOption,
         gridDefinition
     }) => {
        return (
            <div className={"leopard-gridview-toolbar"} style={{minHeight: minHeight}}>
            <span id={"gridViewToobar_" + gridViewId} className="leopard-gridview-dataloading">
                <i className="fas fa-spinner fa-pulse"
                   style={{color: "rgb(255, 128, 0)", fontSize: "18px"}}></i>
            </span>
                <span style={{padding: "0 2px 0 0"}}>
                            <Button text={'Clear Filter'} className="leopard-buttonlink"
                                    id={"GridView_TopBar_ClearFilter_" + gridViewId}
                                    onClick={(e) => clearFilterButtonOnClick({e: e, gridViewId: gridViewId})}/>
                        </span>
                {
                    explicitFilterOption === "auto" ? "" :
                        <span style={{padding: "0 2px 0 0"}}>
                            <Button text={'Apply Filter'} className="leopard-buttonlink"
                                    id={"GridView_TopBar_ApplyFilter_" + gridViewId}
                                    onClick={(e) => applyFilterButtonOnClick({e: e, gridViewId: gridViewId})}/>
                        </span>
                }
                <span style={{padding: "0 2px 0 0"}}>
                            <Button text={'Refresh'} className="leopard-buttonlink"
                                    id={"GridView_TopBar_Refresh_" + gridViewId}
                                    onClick={(e) => refreshButtonOnClick({e: e, gridViewId: gridViewId})}/>
                        </span>
                {
                    gridDefinition.optimizePagerForLargeDataset === false ?
                        <span style={{padding: "0 2px 0 2px"}}>
                            <Button text={'Export'} className="leopard-buttonlink"
                                    id={"GridView_TopBar_Export_" + gridViewId}
                                    onClick={(e) => exportButtonOnClick({e: e, gridViewId: gridViewId})}/>
                        </span> :
                        <span style={{padding: "0 2px 0 2px"}}>
                            <Button text={'Export'} className="leopard-buttonlink"
                                    id={"GridView_TopBar_Export_" + gridViewId} disabled={true}/>
                        </span>
                }
                <span style={{padding: "0 2px 0 2px"}}>
                            <Button text={'Columns'} className="leopard-buttonlink"
                                    id={"GridView_TopBar_ViewOptions_" + gridViewId}
                                    onClick={(e) => viewOptionsButtonOnClick({e: e, gridViewId: gridViewId})}/>
                        </span>
                {   /* <span style={{ padding: "0 2px 0 2px" }}>
                        <Button text={'Group Panel'} className="leopard-buttonlink"
                            type={groupingEnabled ? "success" : "normal"}
                            id={"GridView_TopBar_GroupPanel_" + gridViewId}
                            onClick={(e) => groupingButtonOnClick({ e: e })} />
                        </span>
                   */}
                {
                    allowEditing === false || showAddButton === false ? "" :
                        <span style={{padding: "0 2px 0 2px"}}>
                            <Button text={'Add Record'} className="leopard-buttonlink"
                                    id={"GridView_TopBar_AddRecord_" + gridViewId}
                                    onClick={(e) => addRowButtonOnClick({e: e, gridViewId: gridViewId})}/>
                        </span>
                }
                <div className={"drag-and-drop-selection-count"} id={gridViewId + "_DragAndDropSelectionCount"}></div>
            </div>
        );
    };

export const LeopardGridViewAdvancedFilter = ({gridViewId, gridViewName, minHeight}) => {
    return (
        <div id={gridViewId + "_AdvancedFilter"} className={"leopard-gridview-advancedFilter"}
             style={{minHeight: minHeight}}>
            <span style={{padding: "0px 5px 0px 2px", float: "right"}}>
                <Button className={"leopard-standard-button"} icon={'fas fa-search'}/>
            </span>
            <span style={{padding: "0 2px 0 2px", float: "right"}}>
                <TextBox placeholder={"Order Number"} width={140}/>
            </span>
            <span style={{padding: "0 2px 0 2px", float: "right"}}>
                <TextBox placeholder={"Employee Name"} width={140}/>
            </span>
            <span style={{padding: "0 2px 0 2px", float: "right"}}>
                <DateBox placeholder={"To Date"} showClearButton={true} width={140}
                         useMaskBehavior={true} type={'date'}/>
            </span>
            <span style={{padding: "0 2px 0 2px", float: "right"}}>
                <DateBox placeholder={"From Date"} showClearButton={true} width={140}
                         useMaskBehavior={true} type={'date'}/>
            </span>
        </div>
    );
};