import 'babel-polyfill/dist/polyfill';
import 'whatwg-fetch/fetch';
import 'devextreme/data/odata/store';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
import './css/fontawesome/css/all.min.css';

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import LeopardReducer from './foundation/LeopardReducer';
import { Auth, Analytics } from 'aws-amplify';
import LeopardStaticUIConfig from './foundation/LeopardStaticUIConfig';
import LeopardAuthenticator from './LeopardAuthenticator';
const store = createStore(LeopardReducer);

Analytics.configure({ disabled: true });
Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

ReactDOM.render(
    <div style={{ overflow: "hidden" }}>
        <CookiesProvider>
            <LeopardAuthenticator store={store} />
        </CookiesProvider>
    </div>
    , document.getElementById('root'));
