import React, {Component} from 'react';
import LRH from '../helpers/LeopardReactHelper';
import LeopardGridViewConfiguration from '../components/LeopardGridViewConfiguration';
import LeopardChartConfiguration from '../components/LeopardChartConfiguration';
import LDH from '../helpers/LeopardDataHelper';
import {connect} from 'react-redux';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';

class LeopardSingleDataView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnNames: null,
            gridViewName: null,
            mapId: null,
            chartDefinition: null,
            gridViewDefinition: null,
            styleDefinition: null,
            dataInitializedOnControls: false
        }
    }

    loadGridViewDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = this.props.state.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let dataViewNote = data.dataViewNote;
            let limitedColumns = [];

            for (var i = 0; i < dataViewNote.gridViewDefinition.columnDefinition.length; i++) {
                dataViewNote.gridViewDefinition.columnDefinition[i].hasSorted = false;
                if (!LDH.IsValueEmpty(dataViewNote.gridViewDefinition.columnDefinition[i].isEnabled) &&
                    dataViewNote.gridViewDefinition.columnDefinition[i].isEnabled === false){
                    limitedColumns.push(dataViewNote.gridViewDefinition.columnDefinition[i].columnName);
                }
            }

            let version = LeopardStaticUIConfig.CssVersion;
            LRH.ApplyCustomStyle(dataViewNote.styleDefinition, version);

            that.setState({
                gridViewDefinition: dataViewNote.gridViewDefinition,
                styleDefinition: dataViewNote.styleDefinition
            });
            let tableName = data.dataSourceUrl;
            let url = window.ReportsUrlParameter + data.dataSourceUrl;

            LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(url, tableName, limitedColumns, function (columns) {
                that.setState({columnNames: columns, gridViewName: data.dataViewName});
                if (callback !== undefined && callback !== null) callback();
            }, function (error, sessionTimeout) {
                if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                    error.message.indexOf("Cannot read property '") !== -1 &&
                    error.message.indexOf("' of undefined") !== -1) {
                    LRH.ShowToast("Your Data View might have been deleted or moved. " +
                        "Please refresh this page to update the Control Centre.", "error", 5000);
                } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve data for your Data View. " +
                        "Please check your network connection status and try again.", "error", 5000);
                    localStorage.setItem("LoadFirstMenuItem", false);
                }
                LRH.ShowOrHideMenuLoadingProgress(false);
                LRH.ShowUnhandledExceptionForMasterContent();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadChartDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = this.props.state.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let dataViewNote = data.dataViewNote;
            that.setState({chartDefinition: dataViewNote});
            if (callback !== undefined && callback !== null) callback();
        });
    };

    updateGridViewDefinitionOnSingleDataView = (definition) => {
        this.setState({gridViewDefinition: definition});
    };

    dataInitializedOnControlsUpdateRequest = () => {
        this.setState({dataInitializedOnControls: true});
    };

    componentDidMount = () => {
        let that = this;
        if (this.props.dataViewType === "datagrid") {
            this.loadGridViewDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "chart") {
            this.loadChartDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }
    };

    chartDefinitionUpdateForPreview = (data) => {
        let definitionClone = LDH.DeepClone(this.state.chartDefinition);
        definitionClone.chartDefinition = data.definition;

        this.setState({chartDefinition: definitionClone});
    };

    renderLoadingProgressIndicator = () => {
        if (LRH.IsControlCentreInTestMode() === false) {
            LRH.ShowOrHideMenuLoadingProgress(true);
        }
        return (
            <React.Fragment>
        <span className={"leopard-loading-icon"} style={{width: "100%", height: "100%", marginLeft: "0px"}}>
          <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
          <span className="leopard-largeloading-progress-text">Loading data...</span>
        </span>
            </React.Fragment>
        );
    };

    render() {
        if (this.props.dataViewType === "datagrid") {
            if (this.state.columnNames === null || this.state.columnNames.fullColumns === null ||
                this.props.dataViewId === null || this.state.gridViewName === null) {
                return this.renderLoadingProgressIndicator();
            }
            let gridViewDefinition = this.state.gridViewDefinition;
            let styleDefinition = this.state.styleDefinition;
            let displayAttr = this.state.dataInitializedOnControls ? "block" : "none";

            return (
                <React.Fragment>
                    {
                        this.state.dataInitializedOnControls === true ? "" : this.renderLoadingProgressIndicator()
                    }
                    <div custom_attr_menuitemid={this.props.menuItemId} style={{display: displayAttr}}>
                        <LeopardGridViewConfiguration
                            dataSourceUrl={this.props.dataSourceUrl}
                            viewOptionsText={this.state.gridViewName}
                            columnFieldList={this.state.columnNames}
                            gridViewName={this.state.gridViewName}
                            windowHeight={this.props.windowHeight}
                            setGridViewInstance={this.props.setGridViewInstance}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                            dataInitializedOnControlsUpdateRequest={(e) => this.dataInitializedOnControlsUpdateRequest(e)}
                            dataInitializedOnControls={this.state.dataInitializedOnControls}
                            updateGridViewDefinitionOnSingleDataView={(e) => this.updateGridViewDefinitionOnSingleDataView(e)}
                            gridViewDefinition={gridViewDefinition}
                            styleDefinition={styleDefinition}
                            gridViewId={this.props.dataViewId}/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "chart") {
            if (this.props.dataViewId === undefined || this.props.dataViewId === null ||
                this.state.chartDefinition === undefined || this.state.chartDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            let displayAttr = this.state.dataInitializedOnControls ? "block" : "none";

            return (
                <React.Fragment>
                    {
                        this.state.dataInitializedOnControls === true ? "" : this.renderLoadingProgressIndicator()
                    }
                    <div custom_attr_menuitemid={this.props.menuItemId} style={{display: displayAttr}}>
                        <LeopardChartConfiguration
                            dataSourceUrl={this.props.dataSourceUrl}
                            palette={['#c3a2cc', '#b7b5e0', '#e48cba']}
                            customizeTextYAxis={"#value#"}
                            definition={this.state.chartDefinition}
                            dataViewId={this.props.dataViewId}
                            chartDefinitionUpdateForPreview={(e) => this.chartDefinitionUpdateForPreview(e)}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                            dataInitializedOnControlsUpdateRequest={(e) => this.dataInitializedOnControlsUpdateRequest(e)}
                            dataInitializedOnControls={this.state.dataInitializedOnControls}
                            setChartInstance={this.props.setChartInstance}
                            chartType={this.state.chartDefinition.chartType}/>
                    </div>
                </React.Fragment>
            );
        }
        return (<React.Fragment>
            <div>Data not found</div>
        </React.Fragment>);
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

export default connect(RetrieveDataFromReducer)(LeopardSingleDataView);