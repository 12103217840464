import React, {Component} from 'react';
import {Popover} from 'devextreme-react/popover';
import LRH from "../helpers/LeopardReactHelper";

class LeopardTooltipWithText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    static TooltipAnimationConfig = {
        show: {
            type: 'pop',
            from: {
                scale: 0
            },
            to: {
                scale: 1
            }
        },
        hide: {
            type: 'fade',
            from: 1,
            to: 0
        }
    };

    onMouseEnter = () => {
        this.setState({visible: true});
    }

    onMouseLeave = () => {
        this.setState({visible: false});
    }

    contentTemplate = () => {
        return (<div style={{textAlign: "center"}}>
            {this.props.text}
        </div>)
    }

    render() {
        if (LRH.IsInternetExplorerBrowser() === true) {
            return (
                <React.Fragment>
                    <span id={this.props.elementId} className={"leopard-link-with-tooltip"}>
                        {this.props.labelText}
                    </span>
                </React.Fragment>);
        } else {
            return (
                <React.Fragment>
                <span id={this.props.elementId} className={"leopard-link-with-tooltip"}
                      onMouseEnter={() => this.onMouseEnter()}
                      onMouseLeave={() => this.onMouseLeave()}>{this.props.labelText}</span>
                    <div style={{position: "absolute"}}>
                        <Popover target={"#" + this.props.elementId} position={'top'}
                                 width={this.props.width} visible={this.state.visible}
                                 contentComponent={(e) => this.contentTemplate(e)}
                                 animation={LeopardTooltipWithText.TooltipAnimationConfig}>
                            {this.props.text}
                        </Popover>
                    </div>
                </React.Fragment>);
        }
    }
}

export default LeopardTooltipWithText;