import React, {Component} from 'react';
import {Popover} from 'devextreme-react/popover';
import LRH from "../helpers/LeopardReactHelper";

class LeopardTooltipWithIcon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    static TooltipAnimationConfig = {
        show: {
            type: 'pop',
            from: {
                scale: 0
            },
            to: {
                scale: 1
            }
        },
        hide: {
            type: 'fade',
            from: 1,
            to: 0
        }
    };

    onMouseEnter = () => {
        this.setState({visible: true});
    }

    onMouseLeave = () => {
        this.setState({visible: false});
    }

    render() {
        if (LRH.IsInternetExplorerBrowser() === true) {
            return ("");
        } else {
            return (
                <React.Fragment>
                    <i id={this.props.elementId}
                       className={"fas fa-info-circle leopard-field-attention-info"}
                       onMouseEnter={() => this.onMouseEnter()}
                       onMouseLeave={() => this.onMouseLeave()}></i>
                    <div style={{position: "absolute"}}>
                        <Popover target={"#" + this.props.elementId}
                                 showTitle={true} title={this.props.title}
                                 position={'top'} width={300} visible={this.state.visible}
                                 animation={LeopardTooltipWithIcon.TooltipAnimationConfig}>
                            {this.props.text}
                        </Popover>
                    </div>
                </React.Fragment>);
        }
    }
}

export default LeopardTooltipWithIcon;