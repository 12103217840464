import React, { Component } from 'react';
import { connect } from 'react-redux';
import AceEditor from 'react-ace';

import 'brace/ext/language_tools';
import 'brace/mode/css';
import 'brace/theme/tomorrow';

import { Button } from 'devextreme-react';
import { CheckBox } from 'devextreme-react/check-box';
import $ from 'jquery';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';

class LeopardStyleBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gridViewId: null,
            cssValue: "",
            defaultStyleEnabled: true
        };

        this.customCSSValue = "";
    }

    componentDidMount() {
        let that = this;
        let definition = this.props.styleDefinition;
        if (definition !== undefined && definition !== null && definition !== "") {
            let definitionObj = definition;
            this.customCSSValue = definitionObj;
            this.setState({ cssValue: definitionObj, gridViewId: this.props.gridViewId }, function () {
                that.previewButtonOnClick();
            });
        }
        else {
            this.customCSSValue = "";
            this.setState({ cssValue: "", gridViewId: this.props.gridViewId });
        }
    }

    previewButtonOnClick = (callback) => {
        let version = LeopardStaticUIConfig.CssVersion;

        var defaultStyle = '<link id="leopard-default-style" rel="stylesheet" type="text/css" href="/css/custom.css?v=' + version + '" />';
        var customStyle = '<style id="leopard-custom-style" type="text/css">' + this.customCSSValue + '</style>';

        $("#leopard-default-style").remove();
        if (this.state.defaultStyleEnabled) $("head").append(defaultStyle);

        $("#leopard-custom-style").remove();
        $("head").append(customStyle);

        this.setState({ cssValue: this.customCSSValue }, function(){
            if (callback !== undefined && callback !== null){
                callback();
            }
        });
    };

    saveButtonOnClick = () => {
        let that = this;
        $(".leopard-styledesigner-loading-progress").css("visibility", "visible");

        let definition = this.customCSSValue;
        let gridViewId = this.state.gridViewId;
        let userProfile = this.props.state.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

        setTimeout(() => {
            that.customCSSValue = definition;
            that.setState({ showLoadingProgress: true, cssValue: that.customCSSValue }, function () {
                LeopardAjaxHelper.UpdateGridViewStyle(userId, organizationId, gridViewId, definition, function () {
                    LRH.ShowToast("Your Grid View configuration has been successfully saved.", "success", 5000);
                    $(".leopard-styledesigner-loading-progress").css("visibility", "hidden");
                    $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    }
                    else if (!LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message.error) &&
                        error.message.error.indexOf("size has exceeded") > -1) {
                        LRH.ShowToast("Failed to save your configuration. The data has exceeded the maximum size of 400 KB.", "error", 5000);
                    }
                    else {
                        LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                    }
                    $(".leopard-styledesigner-loading-progress").css("visibility", "hidden");
                });
            });
        }, 100);
    };

    cancelButtonOnClick = () => {
        let that = this;
        $(".leopard-styledesigner-loading-progress").css("visibility", "visible");

        setTimeout(function () {
            that.props.styleBuilderCancelButtonOnClick();
        }, 100);
    };

    aceEditorOnValueChanged = (newValue) => {
        this.customCSSValue = newValue;
    };

    overrideOptionOnChange = (e) => {
        this.setState({ defaultStyleEnabled: e.value });
        this.setState({ cssValue: this.customCSSValue });
    };

    render() {
        if (this.props.styleBuilderVisible === false) return "";

        return (
            <React.Fragment>
                <div className={"leopard-editgridview-title"} style={{ marginBottom: "10px" }}>
                    Edit Style
                </div>
                <div className={"leopard-gray-panel-container"} style={{ marginTop: "5px" }}>
                    <AceEditor mode="css" theme="tomorrow" name={this.state.gridViewId + "_LeopardStyleEditor"}
                        showPrintMargin={true} showGutter={true} highlightActiveLine={true}
                        value={this.state.cssValue} height={"200px"} width={"100%"}
                        onChange={this.aceEditorOnValueChanged} setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2
                        }}
                    />
                </div>
                <div className={'dx-fieldset'} style={{ width: "380px", marginTop: "0px", display: "none" }}>
                    <div className={'dx-field'}>
                        <div className={'dx-field-label'}>Leopard Base Style</div>
                        <div className={'dx-field-value'}>
                            <CheckBox style={{ float: "left" }} value={this.state.defaultStyleEnabled}
                                onValueChanged={(e) => this.overrideOptionOnChange(e)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ padding: "10px 0px 10px 10px" }}>
                    <span style={{ padding: "0 10px 0 0" }}>
                        <Button className={"leopard-standard-button"} style={{ minWidth: "100px" }} text={"Preview"}
                            onClick={() => this.previewButtonOnClick()} />
                    </span>
                    <span style={{ padding: "0 10px 0 0" }}>
                        <Button className={"leopard-standard-button"} style={{ minWidth: "100px" }} text={"Save"}
                            onClick={(e) => this.saveButtonOnClick(e)} />
                    </span>
                    <span style={{ padding: "0 0 0 0" }}>
                        <Button className={"leopard-standard-button"} style={{ minWidth: "100px" }} text={"Close"}
                            onClick={(e) => this.cancelButtonOnClick({ e: e, state: this.state })} />
                    </span>
                    <span className="leopard-styledesigner-loading-progress"
                        style={{ marginRight: "15px", marginLeft: "30px", position: "relative", top: "5px", visibility: "hidden" }}>
                        <i className="fas fa-spinner fa-pulse" style={{ color: "#FF8000", fontSize: "25px" }}></i>
                    </span>
                </div>
                <hr />
                <div className={"leopard-editgridview-title"} style={{ marginBottom: "10px", marginTop: "20px" }}>
                    Preview Layout
                </div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return { state: state };
};

export default connect(RetrieveDataFromReducer)(LeopardStyleBuilder);