import React, {Component} from 'react';
import LeopardGridView from '../foundation/LeopardGridViewEngine';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LDH from '../helpers/LeopardDataHelper';
import AdministrativeTools from "./LeopardGridViewAdministrativeTools";
import {connect} from "react-redux";

const RenderLeopardGridViewConfiguration =
    ({
         gridViewId, gridViewName, dataSourceUrl, viewOptionsText, gridViewHeight, useStateStore, allowEditing,
         allowDragging, allowDropping, columnFieldList, windowHeight, setGridViewInstance, columnResizingMode,
         gridViewDefinition, filterBuilderValue, applyGridViewConfigurationOnDataView, dataInitializedOnControls,
         dataInitializedOnControlsUpdateRequest, commandButtons, disabledFieldsForEditing, hiddenFieldsForEditing,
         explicitFilterOption
     }) => {
        if (LDH.IsValueEmpty(dataSourceUrl) === true) return null;
        return (
            <React.Fragment>
                <LeopardGridView
                    GridViewId={gridViewId} GridViewName={gridViewName} GetDataFromUrl={dataSourceUrl}
                    viewOptionsText={viewOptionsText} gridViewHeight={gridViewHeight}
                    useStateStore={useStateStore} minHeightToolbar={"30px"} allowEditing={allowEditing}
                    columnResizingMode={columnResizingMode} explicitFilterOption={explicitFilterOption}
                    minHeightAdvancedFilter={"31px"} filterBuilderValue={filterBuilderValue}
                    dataInitializedOnControls={dataInitializedOnControls}
                    allowDragging={allowDragging} allowDropping={allowDropping} columnFieldList={columnFieldList}
                    dataInitializedOnControlsUpdateRequest={(e) => dataInitializedOnControlsUpdateRequest(e)}
                    setGridViewInstance={setGridViewInstance} gridDefinition={gridViewDefinition}
                    commandButtons={commandButtons} disabledFieldsForEditing={disabledFieldsForEditing}
                    hiddenFieldsForEditing={hiddenFieldsForEditing}
                    applyGridViewConfigurationOnDataView={(e) => applyGridViewConfigurationOnDataView(e)}
                    windowHeight={windowHeight}/>
            </React.Fragment>
        );
    };

class LeopardGridViewConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceUrl: null,
            allowEditingGridView: false,
            allowDraggingGridView: false,
            allowDroppingGridView: false,
            explicitFilterOption: LeopardStaticUIConfig.GridView_ExplicitFilterOption,
            filterBuilderValueGridView: "",
            commandButtonsGridView: [],
            disabledFieldsForEditingGridView: [],
            hiddenFieldsForEditingGridView: [],
            columnResizingModeGridView: LeopardStaticUIConfig.GridView_ColumnResizingMode
        }
    }

    applyGridViewConfigurationOnDataView = (data) => {
        this.setState({
            columnResizingModeGridView: data.columnResizingMode,
            filterBuilderValueGridView: data.filterBuilderValue,
            allowDraggingGridView: data.allowDragging,
            allowDroppingGridView: data.allowDropping,
            commandButtonsGridView: data.commandButtons,
            explicitFilterOption: data.explicitFilterOption,
            disabledFieldsForEditingGridView: data.disabledFieldsForEditing,
            hiddenFieldsForEditingGridView: data.hiddenFieldsForEditing
        });
        this.props.updateWindowDimensionsRequired();
    };

    render() {
        return (
            <React.Fragment>
                <AdministrativeTools
                    gridViewDefinition={this.props.gridViewDefinition}
                    gridViewId={this.props.gridViewId}
                    parentComp={this}
                    styleDefinition={this.props.styleDefinition}
                    dataSourceUrl={this.props.dataSourceUrl}
                    columnFieldList={this.props.columnFieldList}
                    applyGridViewConfigurationOnDataView={this.applyGridViewConfigurationOnDataView}
                    updateGridViewDefinitionOnSingleDataView={this.props.updateGridViewDefinitionOnSingleDataView}
                />
                <RenderLeopardGridViewConfiguration
                    gridViewId={this.props.gridViewId}
                    gridViewName={this.props.gridViewName}
                    viewOptionsText={this.props.viewOptionsText}
                    dataSourceUrl={this.props.dataSourceUrl}
                    useStateStore={false}
                    explicitFilterOption={this.state.explicitFilterOption}
                    gridViewHeight={this.props.windowHeight - 165}
                    dataInitializedOnControls={this.props.dataInitializedOnControls}
                    dataInitializedOnControlsUpdateRequest={(e) => this.props.dataInitializedOnControlsUpdateRequest(e)}
                    gridViewDefinition={this.props.gridViewDefinition}
                    columnResizingMode={this.state.columnResizingModeGridView}
                    filterBuilderValue={this.state.filterBuilderValueGridView}
                    allowDragging={this.state.allowDraggingGridView}
                    allowDropping={this.state.allowDroppingGridView}
                    columnFieldList={this.props.columnFieldList}
                    setGridViewInstance={this.props.setGridViewInstance}
                    commandButtons={this.state.commandButtonsGridView}
                    disabledFieldsForEditing={this.state.disabledFieldsForEditingGridView}
                    hiddenFieldsForEditing={this.state.hiddenFieldsForEditingGridView}
                    applyGridViewConfigurationOnDataView={(e) => this.applyGridViewConfigurationOnDataView(e)}/>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

export default connect(RetrieveDataFromReducer)(LeopardGridViewConfiguration);