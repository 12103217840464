import React, {Component} from 'react';
import {TagBox} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';

class LeopardDesignerTagBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fieldValue: []
        };

        this.dropdownList = [];
    }

    componentDidMount = () => {
        this.dropdownList = this.props.dropdownList;
        this.setState({fieldValue: this.props.fieldValue});
    };

    selectFieldValueOnChange = (e) => {
        this.setState({fieldValue: e.value});
        this.props.leopardDesignerTagBoxValueOnChange({
            fieldType: this.props.fieldType,
            fieldValue: e.value
        });
    };

    render() {
        let attributes = null;
        if (this.props.fieldType === "filterOperations") {
            attributes = {width: "200px"};
        }

        if (!LDH.IsObjectNull(this.props.addUIObjectInstance)) {
            return (
                <React.Fragment>
                    <TagBox dataSource={this.dropdownList} multiline={false} {...attributes}
                            showSelectionControls={true} valueExpr={'value'} displayExpr={'text'}
                            value={this.state.fieldValue}
                            ref={(e) => this.props.addUIObjectInstance({
                                uiObjectName: this.props.fieldType + this.props.columnName,
                                ref: e
                            })}
                            onValueChanged={(e) => this.selectFieldValueOnChange(e)}
                            applyValueMode={'useButtons'}/>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <TagBox dataSource={this.dropdownList} multiline={false} {...attributes}
                            showSelectionControls={true} valueExpr={'value'} displayExpr={'text'}
                            value={this.state.fieldValue}
                            onValueChanged={(e) => this.selectFieldValueOnChange(e)}
                            applyValueMode={'useButtons'}/>
                </React.Fragment>
            );
        }
    }
}

export default LeopardDesignerTagBox;