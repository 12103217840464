import React, {Component} from 'react';
import LeopardGridView from './LeopardGridViewEngine';

const RenderLeopardGridView =
    ({
         gridViewId, gridViewName, dataSourceUrl, viewOptionsText, gridViewHeight, useStateStore, gridDefinition,
         allowEditing, allowDragging, allowDropping, minHeightToolbar, explicitFilterOption,
         minHeightAdvancedFilter, columnFieldList, setGridViewInstance, columnResizingMode, filterBuilderValue,
         commandButtons, disabledFieldsForEditing, hiddenFieldsForEditing
     }) => {

        return (
            <React.Fragment>
                <LeopardGridView GridViewId={gridViewId} GridViewName={gridViewName} GetDataFromUrl={dataSourceUrl}
                                 viewOptionsText={viewOptionsText} gridViewHeight={gridViewHeight}
                                 useStateStore={useStateStore}
                                 minHeightToolbar={minHeightToolbar} minHeightAdvancedFilter={minHeightAdvancedFilter}
                                 updateParentGridViewDimensionOnDataLoad={true}
                                 gridDefinition={gridDefinition} allowEditing={allowEditing}
                                 filterBuilderValue={filterBuilderValue} explicitFilterOption={explicitFilterOption}
                                 commandButtons={commandButtons} disabledFieldsForEditing={disabledFieldsForEditing}
                                 hiddenFieldsForEditing={hiddenFieldsForEditing}
                                 setGridViewInstance={setGridViewInstance} columnResizingMode={columnResizingMode}
                                 allowDragging={allowDragging} allowDropping={allowDropping}
                                 columnFieldList={columnFieldList}/>
            </React.Fragment>
        );
    };

class LeopardChildGridView extends Component {
    constructor(props) {
        super(props);

        this.allowDraggingGridView = this.props.gridDefinition.allowDragging;
        this.allowDroppingGridView = this.props.gridDefinition.allowDropping;
        this.filterBuilderValueGridView = this.props.gridDefinition.filterBuilderValue;
        this.commandButtonsGridView = this.props.gridDefinition.commandButtons;
        this.disabledFieldsForEditingGridView = this.props.gridDefinition.disabledFieldsForEditing;
        this.hiddenFieldsForEditingGridView = this.props.gridDefinition.hiddenFieldsForEditing;
        this.columnResizingModeGridView = this.props.gridDefinition.columnResizingMode;
        this.explicitFilterOption = this.props.gridDefinition.explicitFilterOption;

        this.gridViewInstance = null;
    }

    render() {
        return (
            <React.Fragment>
                <RenderLeopardGridView gridViewId={this.props.gridViewId} gridViewName={this.props.gridViewName}
                                       viewOptionsText={this.props.viewOptionsText}
                                       dataSourceUrl={this.props.dataSourceUrl}
                                       gridViewHeight={this.props.gridViewHeight} useStateStore={true}
                                       allowDragging={this.allowDraggingGridView}
                                       allowDropping={this.allowDroppingGridView}
                                       minHeightToolbar={this.props.minHeightToolbar}
                                       explicitFilterOption={this.explicitFilterOption}
                                       minHeightAdvancedFilter={this.props.minHeightAdvancedFilter}
                                       columnResizingMode={this.columnResizingModeGridView}
                                       filterBuilderValue={this.filterBuilderValueGridView}
                                       columnFieldList={this.props.columnFieldList}
                                       setGridViewInstance={this.props.setGridViewInstance}
                                       commandButtons={this.commandButtonsGridView}
                                       disabledFieldsForEditing={this.disabledFieldsForEditingGridView}
                                       hiddenFieldsForEditing={this.hiddenFieldsForEditingGridView}
                                       gridDefinition={this.props.gridDefinition}/>
            </React.Fragment>
        );
    }
}

export default LeopardChildGridView;