import {Component} from 'react';
import LRH from './LeopardReactHelper';
import Auth, {API} from "aws-amplify";
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardTestHelper from './LeopardTestHelper';
import LeopardDataHelper from './LeopardDataHelper';
import LeopardSecurity from '../security/LeopardSecurity';

class LeopardAjaxHelper extends Component {
    static IsResponseContainsError = (response, callbackError) => {
        if (response === null || response === "null") {
            callbackError(response, false);
            return true;
        } else if (response.message !== undefined && response.message !== null &&
            response.message.statusCode !== undefined &&
            response.message.statusCode === 400 &&
            response.message.error !== undefined && response.message.error !== null &&
            response.message.error.indexOf("Item not found") !== -1) {
            return false;
        } else if (response.message !== undefined && response.message !== null &&
            response.message.statusCode !== undefined &&
            response.message.statusCode === 400) {
            callbackError(response, false);
            return true;
        } else if (response.errorMessage !== undefined && response.errorMessage !== null &&
            response.errorMessage !== "") {
            callbackError(response, false);
            return true;
        } else if (response.message !== undefined && response.message !== null &&
            response.message.error !== undefined &&
            response.message.error !== "") {
            callbackError(response, false);
            return true;
        } else if (LeopardAjaxHelper.IsLoginSessionTimedOut(response)) {
            callbackError(response, true);
            return true;
        }
        return false;
    };

    static IsLoginSessionTimedOut = (error) => {
        try {
            if (error !== undefined && error !== null && error === "null") {
                LeopardSecurity.UserLogout();
                return true;
            } else if (error !== null &&
                LeopardDataHelper.IsValueEmpty(error.message) === false && (
                    error.message.indexOf("security token") !== -1 ||
                    error.message.indexOf("request is expired") !== -1 ||
                    error.message.indexOf("Authentication Token") !== -1 ||
                    error.message.indexOf("is forbidden") !== -1 ||
                    error.message.indexOf("Unauthenticated access") !== -1)) {
                LeopardSecurity.UserLogout();
                return true;
            }
            return false;
        } catch (ex) {
            return false;
        }
    };

    static HandleSessionTimeoutEvent = (error) => {
        if (error === "not authenticated") {
            LeopardSecurity.UserLogout();
            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
        }
    }

    static GetLeftMenuItems = (userId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_Main_LeftMenuItems";

                    if (profile.ParentGroup.Configs === undefined ||
                        profile.ParentGroup.Configs === null) {
                        profile.ParentGroup.Configs = [];
                    }

                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateLeftMenuItemForDashboard = (userId, organizationId, menuItemId, menuItemName,
                                             dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                    menuItemsObj.push({
                        contentPageType: "dashboard-view",
                        menuItemName: menuItemName,
                        menuItemId: menuItemId,
                        dashboardId: dashboardId,
                        menuItemType: "dashboard",
                        menuItemVersion: LeopardStaticUIConfig.LeftMenuItemVersion,
                        parentMenuItemName: "Dashboards"
                    });

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateLeftMenuItemForDashboard = (userId, organizationId, menuItemName,
                                             dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }

                    let dashboardItem = null;
                    let menuItemsIndex = 0;
                    for (var i = 0; i < menuItemsObj.length; i++) {
                        if (menuItemsObj[i].menuItemType !== "dashboard") {
                            continue;
                        }
                        if (menuItemsObj[i].dashboardId === dashboardId) {
                            dashboardItem = menuItemsObj[i];
                            menuItemsIndex = i;
                            break;
                        }
                    }

                    if (dashboardItem === null && callbackError !== undefined && callbackError !== null) {
                        callbackError({error: "Dashboard item not found"});
                    }
                    if (dashboardItem === null) return;

                    dashboardItem.menuItemVersion = LeopardStaticUIConfig.LeftMenuItemVersion;
                    dashboardItem.menuItemName = menuItemName;
                    menuItemsObj[menuItemsIndex] = dashboardItem;
                    Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(dashboardItem);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateLeftMenuItemForDataView = (userId, organizationId, menuItemId, menuItemName, menuItemType,
                                            dataViewType, dataViewNote, dataTableName, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                    let parentMenuItemName = "Data Views";
                    if (menuItemType === "system-view") {
                        parentMenuItemName = "System Views";
                    }

                    menuItemsObj.push({
                        contentPageType: "single-data-view",
                        menuItemName: menuItemName,
                        menuItemId: menuItemId,
                        dataViewId: dataViewId,
                        dataTableName: dataTableName,
                        dataViewType: dataViewType,
                        dataViewNote: dataViewNote,
                        menuItemType: menuItemType,
                        menuItemVersion: LeopardStaticUIConfig.LeftMenuItemVersion,
                        parentMenuItemName: parentMenuItemName
                    });

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateLeftMenuItemForDataView = (userId, organizationId, menuItemName, dataViewId,
                                            callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    let dataViewItem = null;
                    let menuItemsIndex = 0;
                    for (var i = 0; i < menuItemsObj.length; i++) {
                        if (!LeopardDataHelper.IsValueEmpty(menuItemsObj[i].menuItemType) &&
                            menuItemsObj[i].menuItemType !== "data-view" &&
                            menuItemsObj[i].menuItemType !== "system-view") {
                            continue;
                        }
                        if (menuItemsObj[i].dataViewId === dataViewId) {
                            dataViewItem = menuItemsObj[i];
                            menuItemsIndex = i;
                            break;
                        }
                    }
                    if (dataViewItem === null && callbackError !== undefined && callbackError !== null) {
                        callbackError({error: "Data view item not found"});
                    }
                    if (dataViewItem === null) return;

                    dataViewItem.menuItemVersion = LeopardStaticUIConfig.LeftMenuItemVersion;
                    dataViewItem.menuItemName = menuItemName;
                    menuItemsObj[menuItemsIndex] = dataViewItem;
                    Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(dataViewItem);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteLeftMenuItemForDataView = (userId, organizationId, dataViewId, callbackSuccess,
                                            callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.DeleteDataView(organizationId, dataViewId, function () {
                    LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                        if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                            menuItemsObj = [];
                        }
                        for (var i = 0; i < menuItemsObj.length; i++) {
                            if (!LeopardDataHelper.IsValueEmpty(menuItemsObj[i].menuItemType) &&
                                menuItemsObj[i].menuItemType !== "data-view" &&
                                menuItemsObj[i].menuItemType !== "system-view") {
                                continue;
                            }
                            if (menuItemsObj[i].dataViewId === dataViewId) {
                                menuItemsObj.splice(i, 1);
                                break;
                            }
                        }
                        Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                        let appConfig = menuItemsObj;
                        let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                        url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                        API.put("aws-api-gateway", url, {
                            headers: {"Content-Type": "application/json"},
                            body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                        }).then(response => {
                            if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                                return;
                            }
                            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                                callbackSuccess(response);
                            }
                        }).catch(error => {
                            if (callbackError !== undefined && callbackError !== null) {
                                let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                                callbackError(error, sessionTimeout);
                            }
                        });
                    }, function (error) {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteLeftMenuItemForDashboard = (userId, organizationId, dashboardId, callbackSuccess,
                                             callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.DeleteDashboard(organizationId, dashboardId, function () {
                    LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                        if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                            menuItemsObj = [];
                        }
                        for (var i = 0; i < menuItemsObj.length; i++) {
                            if (menuItemsObj[i].menuItemType !== "dashboard") {
                                continue;
                            }
                            if (menuItemsObj[i].dashboardId === dashboardId) {
                                menuItemsObj.splice(i, 1);
                                break;
                            }
                        }
                        Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                        let appConfig = menuItemsObj;
                        let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                        url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                        API.put("aws-api-gateway", url, {
                            headers: {"Content-Type": "application/json"},
                            body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                        }).then(response => {
                            if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                                return;
                            }
                            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                                callbackSuccess(response);
                            }
                        }).catch(error => {
                            if (callbackError !== undefined && callbackError !== null) {
                                let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                                callbackError(error, sessionTimeout);
                            }
                        });
                    }, function (error) {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateDashboard = (organizationId, dashboardName, dashboardNote, dashboardId,
                              dataViewIds, dashboardLayout, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Dashboard_" + dashboardId);

                let config = {
                    dashboardName: dashboardName,
                    dashboardId: dashboardId,
                    dashboardLayout: dashboardLayout,
                    dashboardNote: dashboardNote,
                    contentPageType: "dashboard-view",
                    dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                };

                API.put("aws-api-gateway", url, {
                    headers: {"Content-Type": "application/json"},
                    body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(config, false)}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        callbackError(error, false);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDashboard = (userId, organizationId, dashboardId, dashboardLayout, dashboardName,
                              callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    let dashboardLayoutFromDb = dashboard.dashboardLayout;

                    for (var i = 0; i < dashboardLayoutFromDb.length; i++) {
                        for (var j = 0; j < dashboardLayout.length; j++) {
                            if (dashboardLayoutFromDb[i].i === dashboardLayout[j].i) {
                                dashboardLayoutFromDb[i].definition = dashboardLayout[j].definition;
                                break;
                            }
                        }
                    }

                    Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);

                    let config = {
                        dashboardName: dashboardName,
                        dashboardId: dashboard.dashboardId,
                        dashboardLayout: dashboardLayoutFromDb,
                        dashboardNote: dashboard.dashboardNote,
                        contentPageType: dashboard.contentPageType,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    };

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDashboardLayout = (userId, organizationId, dashboardId, dashboardLayout,
                                    callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    for (var i = 0; i < dashboard.dashboardLayout.length; i++) {
                        for (var j = 0; j < dashboardLayout.length; j++) {
                            if (dashboard.dashboardLayout[i].i === dashboardLayout[j].i) {
                                dashboardLayout[j].definition = dashboard.dashboardLayout[i].definition;
                                break;
                            }
                        }
                    }
                    Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);

                    let config = {
                        dashboardName: dashboard.dashboardName,
                        dashboardId: dashboard.dashboardId,
                        dashboardLayout: dashboardLayout,
                        dashboardNote: dashboard.dashboardNote,
                        contentPageType: dashboard.contentPageType,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    };

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateDataView = (organizationId, dataViewName, dataViewType, dataViewNote, dataSourceUrl,
                             dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);
                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                let dataToSend = {
                    dataSourceUrl: dataSourceUrl,
                    dataViewName: dataViewName,
                    dataViewId: dataViewId,
                    dataViewType: dataViewType,
                    dataViewNote: dataViewNote,
                    contentPageType: "single-data-view",
                    dataViewConfigVersion: LeopardStaticUIConfig.DataViewConfigVersion
                };

                API.put("aws-api-gateway", url, {
                    headers: {"Content-Type": "application/json"},
                    body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(dataToSend, false)}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        callbackError(error, false);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDataView = (userId, organizationId, dataViewName, dataViewId,
                             callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    config.dataViewName = dataViewName;
                    config.dataViewConfigVersion = LeopardStaticUIConfig.DataViewConfigVersion;

                    if (config.dataViewType === "chart") {
                        let dataViewNote = config.dataViewNote;
                        dataViewNote.chartDefinition.chartTitle = dataViewName;
                        config.dataViewNote = dataViewNote;
                    }

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateChartDefintion = (userId, organizationId, dataViewId, definition,
                                   callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    let dataViewNoteObj = config.dataViewNote;
                    dataViewNoteObj.chartDefinition = definition;
                    config.dataViewNote = dataViewNoteObj;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateGridViewDefintion = (userId, organizationId, dataViewId, definition,
                                      callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }

                    let dataViewNoteObj = config.dataViewNote;
                    dataViewNoteObj.gridViewDefinition = definition;
                    config.dataViewNote = dataViewNoteObj;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateGridViewStyle = (userId, organizationId, dataViewId, styleDefinition,
                                  callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    let dataViewNoteObj = config.dataViewNote;
                    dataViewNoteObj.styleDefinition = styleDefinition;
                    config.dataViewNote = dataViewNoteObj;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: {"Content-Type": "application/json"},
                        body: {AppConfig: LeopardDataHelper.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetUserProfile = (userId, callbackSuccess, callbackError) => {
        // --------------------- For Test Mode Only --------------------------
        if (LRH.IsControlCentreInTestMode()) {
            let config = LeopardTestHelper.GetTestUserProfile();
            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                callbackSuccess(config);
            }
            return;
        }
        // ------------------------------------------------------------------

        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);
                let url = window.UserProfileUrlPath.replace("{userId}", userId);

                API.get("aws-api-gateway", url, {
                    headers: {"Content-Type": "application/json"}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        let msg = LeopardDataHelper.UnconvertJsonDataNullOrEmptyValues(response.message, false);
                        callbackSuccess(msg);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDataSourceList = (callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                API.get('aws-api-gateway', window.GetDataSourceList, {
                    headers: {"Content-Type": "application/json"}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        let result = [];
                        for (var i = 0; i < response.length; i++) {
                            result.push({id: response[i], name: response[i]});
                        }
                        callbackSuccess(result);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDataViewById = (userId, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDashboardById = (userId, dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_Dashboard_" + dashboardId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteDataView = (organizationId, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                let url = window.DeleteGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                API.del('aws-api-gateway', url, {
                    headers: {"Content-Type": "application/json"}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteDashboard = (organizationId, dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardStaticUIConfig.AmplifyAuthAndAPIConfig);

                let url = window.DeleteGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Dashboard_" + dashboardId);

                API.del('aws-api-gateway', url, {
                    headers: {"Content-Type": "application/json"}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDataTableColumnsByDataSourceUrl = (url, tableName, limitDataColumns, callbackSuccess,
                                                 callbackError) => {
        // --------------------- For Test Mode Only --------------------------
        if (LRH.IsControlCentreInTestMode()) {
            let config = LeopardTestHelper.GetTestDataTableColumns();
            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                callbackSuccess({fullColumns: config, limitedColumns: config});
            }
            return;
        }
        // ------------------------------------------------------------------

        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                API.get('aws-api-gateway', url, {
                    headers: {"Content-Type": "application/json"}
                }).then(response => {
                    let newData = [];

                    if (limitDataColumns !== undefined && limitDataColumns !== null && limitDataColumns.length > 0) {
                        for (var i = 0; i < response.length; i++) {
                            let found = false;
                            for (var j = 0; j < limitDataColumns.length; j++) {
                                if (limitDataColumns[j] === response[i]) {
                                    found = true;
                                    break;
                                }
                            }

                            if (found === false) {
                                newData.push(response[i]);
                            }
                        }
                    } else {
                        newData = response;
                    }

                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        if (callbackError !== undefined && callbackError !== null) {
                            callbackError({fullColumns: response, limitedColumns: newData}, false);
                        }
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess({fullColumns: response, limitedColumns: newData});
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };
}

export default LeopardAjaxHelper;