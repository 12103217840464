import React, { Component } from 'react';
import { connect } from 'react-redux';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import { InitChartData, InitCustomStoreForChart } from './LeopardActionCreators';
import Chart, {
    ValueAxis, Label, Legend, Series, Export, Point, SeriesTemplate, CommonSeriesSettings,
    ZoomAndPan, ArgumentAxis, Tooltip, ScrollBar, ConstantLine, Animation
} from 'devextreme-react/chart';
import PieChart, { Connector, SmallValuesGrouping } from 'devextreme-react/pie-chart';

class LeopardChartEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // ---- For "UseStateStore => True" Only ----
            customStore: null,
            chartData: null
            // ------------------------------------------
        };

        this.uiObjectInstance = {
            chartInstance: null
        };

        // -------------------- NON-GENERIC CODE START --------------------
        this.barchartConfiguration = {
            palette: this.props.palette,
            paletteIndex: 0
        };
        // -------------------- NON-GENERIC CODE END ----------------------
    }

    setChartInstance = (ref) => {
        if (ref === null || ref.instance === null) return;

        this.uiObjectInstance.chartInstance = ref.instance;
        this.props.setChartInstance({
            instance: ref.instance,
            id: this.props.dataViewId,
            type: "chart",
            isDataView: true
        });
    };

    componentWillUnmount = () => {
        let instance = this.uiObjectInstance.chartInstance;
        if (instance !== undefined && instance !== null) {
            instance.dispose();
        }
        this.props.setChartInstance({
            instance: null,
            id: this.props.dataViewId,
            type: "chart",
            isDataView: true
        });
    };

    componentDidMount = () => {
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            this.initializeCustomStoreForReduxState();
        }
        else {
            this.initializeCustomStoreForLocalState();
        }
    };

    initializeCustomStoreForReduxState = () => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let url = this.props.dataSourceUrl;

        let store = LRH.InitCustomStoreForChartView(url, "GET", function (data) {
            if (data !== undefined) {
                that.props.InitChartData(data, dataViewId);
                that.props.updateWindowDimensionsRequired();
            }
        });
        this.props.InitCustomStoreForChart(store, dataViewId);
    };

    initializeCustomStoreForLocalState = () => {
        let url = this.props.dataSourceUrl;
        let that = this;
        let store = LRH.InitCustomStoreForChartView(url, "GET", function (data) {
            if (data !== undefined) {
                that.setState({ chartData: data });
                that.props.updateWindowDimensionsRequired();
            }
        });
        this.setState({ customStore: store });
    };

    // -------------------- NON-GENERIC CODE START --------------------

    static barChartConfiguration_customizeText(e, templateValue) {
        if (LDH.IsValueEmpty(templateValue)) return e.valueText;
        return templateValue.replace("#value#", e.valueText);
    }

    static doughnutChartConfiguration_PointClickHandler(arg) {
        arg.target.select();
    }

    static doughnutChartConfiguration_customizeLabel(point) {
        return `${point.argumentText}`;
    }

    // -------------------- NON-GENERIC CODE END ---------------------

    renderCommonSeriesSettingsForBarChart = (chartDefinition) => {
        let genericCommonSeriesSettingsForBarChartProps = {
            argumentField: chartDefinition.seriesArgumentField,
            valueField: chartDefinition.seriesValueField,
            type: chartDefinition.seriesTypeOfSeries,
            cornerRadius: chartDefinition.seriesStyleCornerRadius,
            barWidth: chartDefinition.seriesStyleBarWidth,
            barPadding: chartDefinition.seriesStyleBarPadding,
            ignoreEmptyPoints: chartDefinition.seriesIgnoreEmptyPoint
        };
        if (chartDefinition.seriesTypeOfSeries !== "bar") return ("");
        return (<CommonSeriesSettings {...genericCommonSeriesSettingsForBarChartProps} />);
    };

    renderSeriesTemplateForBarChart = (chartDefinition) => {
        if (chartDefinition.seriesTypeOfSeries !== "bar") return ("");
        return (<SeriesTemplate nameField={chartDefinition.seriesArgumentField} />);
    };

    renderSeriesHighAverageForBarChart = (chartDefinition) => {
        let genericConstantLineHighAverageProps = {
            width: 2,
            value: chartDefinition.valueAxisHighAverage,
            color: '#ff7c7c',
            dashStyle: 'dash'
        };
        if (LDH.IsValueEmpty(chartDefinition.valueAxisHighAverage)) {
            return ("");
        }
        return (
            <ConstantLine {...genericConstantLineHighAverageProps}>
                <Label text={chartDefinition.valueAxisHighAverageText} />
            </ConstantLine>
        )
    };

    renderSeriesLowAverageForBarChart = (chartDefinition) => {
        let genericConstantLineLowAverageProps = {
            width: 2,
            value: chartDefinition.valueAxisLowAverage,
            color: '#8c8cff',
            dashStyle: 'dash'
        };
        if (LDH.IsValueEmpty(chartDefinition.valueAxisLowAverage)) {
            return ("");
        }
        return (
            <ConstantLine {...genericConstantLineLowAverageProps}>
                <Label text={chartDefinition.valueAxisLowAverageText} />
            </ConstantLine>
        )
    };

    onDrawn = () => {
        if (this.props.dataInitializedOnControls !== undefined &&
            this.props.dataInitializedOnControls === false) {
            this.props.dataInitializedOnControlsUpdateRequest();
        }
    };

    render() {
        let chartState = this.props.state.chartState;
        let currentState = this.state;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            currentState = chartState.filter(c => { return c.dataViewId === this.props.dataViewId; });
            if (currentState !== undefined && currentState !== null && currentState.length > 0) {
                currentState = currentState[0];
            }
        }

        if (currentState === undefined || currentState === null || currentState.customStore === null) {
            return (<div className="leopard-wait-panel">Retrieving data, please wait...</div>);
        }
        let chartDefinition = this.props.definition.chartDefinition;
        let genericChartProps = {
            ref: this.setChartInstance,
            dataSource: currentState.customStore,
            id: this.props.dataViewId,
            className: "leopard-chart-container",

            title: chartDefinition.chartTitle,
            resolveLabelOverlapping: chartDefinition.resolveLabelOverlapping,
            redrawOnResize: chartDefinition.chartRedrawOnResize,
            negativesAsZeroes: chartDefinition.chartNegativeValuesAsZeroes,
            pointSelectionMode: chartDefinition.chartPointSelectionMode,
            paletteExtensionMode: chartDefinition.chartPaletteExtMode,
            barGroupWidth: chartDefinition.chartBarGroupWitdh,
            barGroupPadding: chartDefinition.chartBarGroupPadding,
            containerBackgroundColor: chartDefinition.chartContainerBgColor,
            maxBubbleSize: chartDefinition.chartMaxBubbleSize,
            minBubbleSize: chartDefinition.chartMinBubbleSize,
            palette: chartDefinition.chartPalette
        };

        let genericZoomAndPanProps = {
            allowMouseWheel: chartDefinition.chartAllowMouseWheel,
            allowTouchGestures: chartDefinition.chartAllowTouchGuestures,
            dragToZoom: chartDefinition.chartDragToZoom
        };

        let genericValueAxisProps = {
            valueType: chartDefinition.valueAxisValueType,
            allowDecimals: chartDefinition.valueAxisAllowDecimals,
            autoBreaksEnabled: chartDefinition.valueAxisEnableAutoBreak,
            maxAutoBreakCount: chartDefinition.valueAxisMaxAutoBreakCount,
            maxValueMargin: chartDefinition.valueAxisMaxValueMargin,
            customizeText: chartDefinition.valueAxisCustomizeText,
            endOnTick: chartDefinition.valueAxisEndOnTick,
            inverted: chartDefinition.valueAxisInvertAxis,
            showZero: chartDefinition.valueAxisShowZero,
            type: chartDefinition.valueAxisType,
            position: chartDefinition.valueAxisPosition
        };

        let genericArgumentAxisProps = {
            valueType: chartDefinition.argAxisValueType,
            allowDecimals: chartDefinition.argAxisAllowDecimals,
            autoBreaksEnabled: chartDefinition.argAxisEnableAutoBreak,
            maxAutoBreakCount: chartDefinition.argAxisMaxAutoBreakCount,
            maxValueMargin: chartDefinition.argAxisMaxValueMargin,
            customizeText: chartDefinition.argAxisCustomizeText,
            endOnTick: chartDefinition.argAxisEndOnTick,
            inverted: chartDefinition.argAxisInvertAxis,
            showZero: chartDefinition.argAxisShowZero,
            type: chartDefinition.argAxisType,
            position: chartDefinition.argAxisPosition
        };

        let genericTooltipProps = {
            argumentFormat: chartDefinition.tooltipArgumentFormat,
            arrowLength: chartDefinition.tooltipArrowLength,
            color: chartDefinition.tooltipColor,
            enabled: chartDefinition.tooltipEnabled,
            location: chartDefinition.tooltipLocation,
            opacity: chartDefinition.tooltipOpacity,
            paddingLeftRight: chartDefinition.tooltipPaddingLeftRight,
            paddingTopBottom: chartDefinition.tooltipPaddingTopBottom
        };

        let genericExportProps = {
            backgroundColor: chartDefinition.chartBgColorForExport,
            margin: chartDefinition.chartMarginForExport,
            enabled: chartDefinition.chartEnableExport,
        };

        let genericScrollbarProps = {
            visible: chartDefinition.chartShowScrollbar,
            position: chartDefinition.chartScrollbarPosition,
            offset: chartDefinition.chartScrollbarOffset
        };

        let genericSeriesBasicProps = {
            argumentField: chartDefinition.seriesArgumentField,
            valueField: chartDefinition.seriesValueField,
            type: chartDefinition.seriesTypeOfSeries,
            hoverMode: chartDefinition.seriesHoverMode,
            selectionMode: chartDefinition.seriesSelectionMode,
            ignoreEmptyPoints: chartDefinition.seriesIgnoreEmptyPoint,
            axis: chartDefinition.seriesAxisBinding,
            barWidth: chartDefinition.seriesStyleBarWidth,
            barPadding: chartDefinition.seriesStyleBarPadding,
            cornerRadius: chartDefinition.seriesStyleCornerRadius,
            dashStyle: chartDefinition.seriesStyleDashStyle,
            innerColor: chartDefinition.seriesStyleInnerColor
        };

        let genericSeriesLabelProps = {
            visible: chartDefinition.labelVisibility,
            alignment: chartDefinition.labelAlignment,
            argumentFormat: chartDefinition.labelArgumentFormat,
            backgroundColor: chartDefinition.labelBgColor,
            format: chartDefinition.labelLabelFormat,
            horizontalOffset: chartDefinition.labelHorizontalOffset,
            verticalOffset: chartDefinition.labelVerticalOffset,
            position: chartDefinition.labelPosition,
            rotationAngle: chartDefinition.labelRotationAngle,
            showForZeroValues: chartDefinition.labelShowForZero
        };

        let genericSeriesPointProps = {
            visible: chartDefinition.pointVisibility,
            color: chartDefinition.pointColor,
            hoverMode: chartDefinition.pointHoverMode,
            selectionMode: chartDefinition.pointSelectionMode,
            size: chartDefinition.pointSizeOfPoint,
            symbol: chartDefinition.pointCustomSymbol
        };

        let genericLegendBasicProps = {
            visible: chartDefinition.legendVisibility,
            columnCount: chartDefinition.legendColumnCount,
            rowCount: chartDefinition.legendRowCount,
            hoverMode: chartDefinition.legendHoverMode,
            markerSize: chartDefinition.legendMarkerSize,
            orientation: chartDefinition.legendOrientation,
            itemTextPosition: chartDefinition.legendItemTextPosition,
            position: chartDefinition.legendPosition,
            itemsAlignment: chartDefinition.legendItemsAlignment,
            horizontalAlignment: chartDefinition.legendHorizontalAlignment,
            verticalAlignment: chartDefinition.legendVerticalAlignment,
            backgroundColor: chartDefinition.legendBgColor,
            columnItemSpacing: chartDefinition.legendColumnItemSpacing,
            rowItemSpacing: chartDefinition.legendRowItemSpacing,
            paddingLeftRight: chartDefinition.legendPaddingLeftRight,
            paddingTopBottom: chartDefinition.legendPaddingTopBottom,
            margin: chartDefinition.legendMargin
        };

        let genericSmallValuesGroupingProps = {
            topCount: chartDefinition.seriesSmallValueGroupingTopCount,
            mode: chartDefinition.seriesSmallValueGroupingMode
        };

        // -------------------- NON-GENERIC CODE START --------------------

        if (this.props.chartType === "bar-chart" || this.props.chartType === "line-chart" ||
            this.props.chartType === "spline-chart" || this.props.chartType === "area-chart" ||
            this.props.chartType === "scatter-chart") {
            return (
                <React.Fragment>
                    <Chart {...genericChartProps} onDrawn={(e) => this.onDrawn(e)}>
                        {this.renderCommonSeriesSettingsForBarChart(chartDefinition)}
                        <ZoomAndPan {...genericZoomAndPanProps} />
                        <Animation enabled={true} duration={1000}></Animation>
                        <ValueAxis {...genericValueAxisProps}>
                            <Label customizeText={(e) => LeopardChartEngine.barChartConfiguration_customizeText(e,
                                genericValueAxisProps.customizeText)} />
                            {this.renderSeriesHighAverageForBarChart(chartDefinition)}
                            {this.renderSeriesLowAverageForBarChart(chartDefinition)}
                        </ValueAxis>
                        <ArgumentAxis {...genericArgumentAxisProps}>
                            <Label customizeText={(e) => LeopardChartEngine.barChartConfiguration_customizeText(e,
                                genericArgumentAxisProps.customizeText)} />
                        </ArgumentAxis>
                        <Tooltip {...genericTooltipProps} />
                        <ScrollBar {...genericScrollbarProps} />
                        <Series {...genericSeriesBasicProps}>
                            <Label {...genericSeriesLabelProps} />
                            <Point {...genericSeriesPointProps} />
                        </Series>
                        {this.renderSeriesTemplateForBarChart(chartDefinition)}
                        <Legend {...genericLegendBasicProps} />
                        <Export {...genericExportProps} />
                    </Chart>
                </React.Fragment>
            );
        }

        if (this.props.chartType === "doughnut-chart") {
            return (
                <React.Fragment>
                    <PieChart {...genericChartProps} onDrawn={(e) => this.onDrawn(e)}
                              onPointClick={LeopardChartEngine.doughnutChartConfiguration_PointClickHandler} type={'doughnut'} >
                        <Animation enabled={true} duration={1000}></Animation>
                        <ZoomAndPan {...genericZoomAndPanProps} />
                        <Tooltip {...genericTooltipProps} />
                        <ScrollBar {...genericScrollbarProps} />
                        <Series {...genericSeriesBasicProps}>
                            <Label {...genericSeriesLabelProps}>
                                <Connector visible={true} width={1} />
                            </Label>
                            <Point {...genericSeriesPointProps} />
                            <SmallValuesGrouping {...genericSmallValuesGroupingProps} />
                        </Series>
                        <Legend {...genericLegendBasicProps} />
                        <Export {...genericExportProps} />
                    </PieChart>
                </React.Fragment>
            );
        }

        // -------------------- NON-GENERIC CODE END ---------------------

        return null;
    }
}

const RetrieveDataFromReducer = (state) => {
    return { state: state };
};

const SendDataToReducer = (dispatch) => {
    return {
        InitChartData: (data, id) => {
            dispatch(InitChartData(data, id));
        },
        InitCustomStoreForChart: (store, id) => {
            dispatch(InitCustomStoreForChart(store, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardChartEngine);