import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';

import DataGrid, {
    RemoteOperations, Paging, Pager, ColumnChooser, FilterRow,
    ColumnFixing, Sorting, HeaderFilter, LoadPanel,
    Editing, Popup, Position, Export, Form
} from 'devextreme-react/data-grid';

import LeopardSecurity from '../security/LeopardSecurity';
import LDH from '../helpers/LeopardDataHelper';
import LRH from '../helpers/LeopardReactHelper';
import {InitGridData, InitCustomStore, UpdateGridViewDefinition} from './LeopardActionCreators';
import {
    LeopardGridViewColumnBuilder, LeopardGridViewToolbar, LeopardGridViewEditFormBuilder
} from '../datashaping/LeopardGridViewBuilders';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';

class LeopardGridView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupingEnabled: false,
            currentRowDataBeingEdited: null,

            // ---- For "UseStateStore => True" Only ----
            customStore: null
            // ------------------------------------------
        };

        this.totalRecordCount = 0;
        this.optimizePagerForLargeDataset = true;
        this.gridDefinition = {columnDefinition: []};

        this.uiObjectInstance = {
            gridViewInstance: null
        };

        this.temporaryGridViewFilterData = {
            records: [], column: null
        };
    }

    setGridViewInstance = (ref) => {
        if (ref === null || ref.instance === null) return;
        this.uiObjectInstance.gridViewInstance = ref.instance;

        this.props.setGridViewInstance({
            instance: ref.instance,
            id: this.props.GridViewId,
            type: "datagrid",
            isDataView: true,
            optimizePagerForLargeDataset: this.optimizePagerForLargeDataset
        });
    };

    componentDidMount = () => {
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            this.initializeCustomStoreForReduxState();
        } else {
            this.initializeCustomStoreForLocalState();
        }
    };

    componentWillUnmount = () => {
        let instance = this.uiObjectInstance.gridViewInstance;
        if (instance !== undefined && instance !== null) {
            instance.dispose();
        }
        this.props.setGridViewInstance({
            instance: null,
            id: this.props.GridViewId,
            type: "datagrid",
            isDataView: true,
            optimizePagerForLargeDataset: this.optimizePagerForLargeDataset
        });
    };

    initializeCustomStoreForLocalState = () => {
        let thisComp = this;
        let gridViewId = this.props.GridViewId;
        let url = this.props.GetDataFromUrl;
        let filterData = this.temporaryGridViewFilterData;
        let gridDefinition = thisComp.props.gridDefinition;
        let limitedColumns = [];

        for (var u = 0; u < gridDefinition.columnDefinition.length; u++) {
            if (!LDH.IsValueEmpty(gridDefinition.columnDefinition[u].isEnabled) &&
                gridDefinition.columnDefinition[u].isEnabled === false) {
                limitedColumns.push(gridDefinition.columnDefinition[u].columnName);
            }
        }

        let store = LRH.InitCustomStoreForGridView(gridDefinition, gridViewId, url, "GET",
            filterData, limitedColumns, function (gridData, operation) {
                let dataset = operation === "filtering" ? gridData.records : gridData;
                thisComp.totalRecordCount = dataset.totalCount;

                if (operation === "filtering") {
                    thisComp.temporaryGridViewFilterData = gridData;
                }
                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);

                let dataLength = null;
                if (!LDH.IsObjectNull(gridData.records) && LDH.IsObjectNull(gridData.data)) {
                    dataLength = gridData.records.length;
                } else if (!LDH.IsObjectNull(gridData.data) && LDH.IsObjectNull(gridData.records)) {
                    dataLength = gridData.data.length;
                }
                if (dataLength !== null) {
                    thisComp.disableOrEnablePagerBasedOnRowCount(dataLength, gridViewId);
                }

                LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                    },
                    function (error) {
                        if (error === "not authenticated") {
                            LeopardSecurity.UserLogout();
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        }
                    });
            });

        if (thisComp.props.dataInitializedOnControls === false) {
            thisComp.props.dataInitializedOnControlsUpdateRequest();
        }
        this.gridDefinition = thisComp.props.gridDefinition;
        this.setState({customStore: store});
    };

    initializeCustomStoreForReduxState = (callback) => {
        let thisComp = this;
        let gridViewId = this.props.GridViewId;
        let filterData = this.temporaryGridViewFilterData;
        let gridDefinition = this.props.gridDefinition;
        let limitedColumns = [];

        for (var u = 0; u < gridDefinition.columnDefinition.length; u++) {
            if (!LDH.IsValueEmpty(gridDefinition.columnDefinition[u].isEnabled) &&
                gridDefinition.columnDefinition[u].isEnabled === false) {
                limitedColumns.push(gridDefinition.columnDefinition[u].columnName);
            }
        }

        let url = window.GetDataTableByName + this.props.GetDataFromUrl;
        let store = LRH.InitCustomStoreForGridView(gridDefinition, gridViewId, url, "GET",
            filterData, limitedColumns, function (gridData, operation) {
                let dataset = operation === "filtering" ? gridData.records : gridData;
                thisComp.totalRecordCount = dataset.totalCount;
                thisComp.props.InitGridData(dataset, gridViewId);

                if (operation === "filtering") {
                    thisComp.temporaryGridViewFilterData = gridData;
                }

                if (callback !== undefined && callback !== null) callback();
                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);

                let dataLength = null;
                if (!LDH.IsObjectNull(gridData.records) && LDH.IsObjectNull(gridData.data)) {
                    dataLength = gridData.records.length;
                } else if (!LDH.IsObjectNull(gridData.data) && LDH.IsObjectNull(gridData.records)) {
                    dataLength = gridData.data.length;
                }
                if (dataLength !== null) {
                    thisComp.disableOrEnablePagerBasedOnRowCount(dataLength, gridViewId);
                }

                LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                    },
                    function (error) {
                        if (error === "not authenticated") {
                            LeopardSecurity.UserLogout();
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        }
                    });
            });

        this.props.InitCustomStore(store, gridViewId);
        if (thisComp.props.dataInitializedOnControls === false) {
            thisComp.props.dataInitializedOnControlsUpdateRequest();
        }
        this.props.UpdateGridViewDefinition(gridDefinition, gridViewId);

        if (LRH.IsControlCentreInTestMode() === false) {
            this.props.applyGridViewConfigurationOnDataView({
                filterBuilderValue: gridDefinition.filterBuilderValue,
                commandButtons: gridDefinition.commandButtons,
                explicitFilterOption: gridDefinition.explicitFilterOption,
                disabledFieldsForEditing: gridDefinition.disabledFieldsForEditing,
                hiddenFieldsForEditing: gridDefinition.hiddenFieldsForEditing,
                columnResizingMode: gridDefinition.columnResizingMode
            });
        }
    };

    onRowExpanding = (e) => {
        e.component.collapseAll(-1);
    };

    onToolbarPreparing = (e) => {
        let id = this.props.GridViewId.toString();
        let headerPanelId = "#" + id + " .dx-datagrid-header-panel";
        if (this.state.groupingEnabled === false) {
            $(headerPanelId).hide();
        } else {
            $(headerPanelId).show();
        }

        let allowEditing = false;
        if (this.props.commandButtons !== undefined && this.props.commandButtons !== null &&
            this.props.commandButtons.length > 0) {
            for (let k = 0; k < this.props.commandButtons.length; k++) {
                if (this.props.commandButtons[k] === "modify-row") {
                    allowEditing = true;
                }
                if (this.props.commandButtons[k] === "add-row") {
                    allowEditing = true;
                }
                if (this.props.commandButtons[k] === "delete-row") {
                    allowEditing = true;
                }
            }
        }

        let addRowId = "#" + id + "_toolbar-button-addrow";
        if (allowEditing === false) {
            $(addRowId).hide();
        } else {
            $(addRowId).show();
        }

        let toolbarItems = e.toolbarOptions.items;
        for (var i = 0; i < toolbarItems.length; i++) {
            if (toolbarItems[i].name === "addRowButton" ||
                toolbarItems[i].name === "exportButton") {
                toolbarItems[i].visible = false;
            }
        }
    };

    onInitialized = (e) => {
        e.component.columnOption("command:edit", "width", 100);
    };

    onDataErrorOccurred = () => {
        LRH.ShowToast("There is an unknown error occurred on the Data View.", "error", 5000);
    };

    registerGridViewColumnHeaderEvents = () => {
        let that = this;
        $(".dx-texteditor-input-container", "#" + this.props.GridViewId).each(function () {
            $("input.dx-texteditor-input", $(this)).each(function () {
                $(this).off("keypress").on("keypress", function (e) {
                    if (e.which === 13) {
                        let id = that.props.GridViewId;
                        if ($("#GridView_TopBar_ApplyFilter_" + id).hasClass("leopard-ui-disabled")) {
                            return;
                        }
                        $(".dx-datagrid-toolbar-button.dx-apply-button", "#" + id).trigger("click");
                    }
                })
            });
        });

        $(".dx-overlay-wrapper.dx-datagrid-filter-range-overlay .dx-texteditor-input-container").each(function () {
            $("input.dx-texteditor-input", $(this)).each(function () {
                $(this).off("keypress").on("keypress", function (e) {
                    if (e.which === 13) {
                        let id = that.props.GridViewId;
                        if ($("#GridView_TopBar_ApplyFilter_" + id).hasClass("leopard-ui-disabled")) {
                            return;
                        }
                        $(".dx-datagrid-toolbar-button.dx-apply-button", "#" + id).trigger("click");
                    }
                })
            });
        });
    };

    onContentReady = (e) => {
        let that = this;
        let $grid = $("#" + this.props.GridViewId);

        // ------ Temporary hide the group panel for now until we work on it. ----------
        let headerPanelId = "#" + this.props.GridViewId + " .dx-datagrid-header-panel";
        $(headerPanelId).hide();
        // -----------------------------------------------------------------------------

        let gridViewId = this.props.GridViewId;
        if (that.optimizePagerForLargeDataset === true) {
            $(".leopard-page-number", "#GridViewPager_" + gridViewId).each(function () {
                $(this).off("click").on("click", function () {
                    if ($("#GridViewPager_" + gridViewId).hasClass("leopard-ui-disabled")) {
                        return false;
                    }
                    let $id = $("#GridViewPager_" + gridViewId);
                    $(".leopard-page-number", $id).removeClass("selected");
                    $(this).addClass("selected");
                    let pageSize = parseInt($(this).attr("pageSize").trim());
                    that.uiObjectInstance.gridViewInstance.pageSize(pageSize);
                    return false;
                });
            });
        }

        $(".leopard_gridview_column_template .dx-editor-with-menu", $grid).each(function () {
            $(this).off("mousedown").on("mousedown", function (e) {
                setTimeout(function () {
                    that.registerGridViewColumnHeaderEvents();
                }, 100);
            });
        });
        $(".leopard_gridview_column_template .dx-menu-item-wrapper", $grid).each(function () {
            $(this).off("mouseenter").on("mouseenter", function (e) {
                setTimeout(function () {
                    $(".dx-overlay-wrapper .dx-menu-item-content").each(function () {
                        $(this).off("click").on("click", function () {
                            setTimeout(function () {
                                that.registerGridViewColumnHeaderEvents();
                            }, 500);
                        })
                    });
                }, 500);
            });
        });
        that.registerGridViewColumnHeaderEvents();
    };

    // ------------------------------------------------------------------------------
    // Phase 2 features. Will be replaced with the current onContentReady method.
    // ------------------------------------------------------------------------------
    onContentReady_New = () => {
        let gridViewId = this.props.GridViewId;
        let allowDragging = this.props.allowDragging;
        let instance = this.uiObjectInstance.gridViewInstance;

        if (this.initDraggingOnGridViewTimeout !== null) {
            clearTimeout(this.initDraggingOnGridViewTimeout);
        }

        this.initDraggingOnGridViewTimeout = setTimeout(() => {
            $('.dx-data-row', $("#" + gridViewId)).each(function () {
                $(this).addClass("gridViewId_" + gridViewId);

                if (allowDragging !== undefined && allowDragging === true) {
                    $(this).addClass("draggable");
                } else {
                    $(this).removeClass("draggable");
                }
            });
            LRH.DestoryDraggingOnGridView(gridViewId);
            if (allowDragging !== undefined && allowDragging === true) {
                LRH.InitDraggingOnGridView(gridViewId, instance, allowDragging);
            }
            this.initDraggingOnGridViewTimeout = null;
        }, 100);
    };
    // ------------------------------------------------------------------------------

    groupingButtonOnClick = () => {
        let id = this.props.GridViewId;
        let headerPanelId = "#" + id.toString() + " .dx-datagrid-header-panel";
        if (this.state.groupingEnabled === false) {
            this.setState({groupingEnabled: true});
            $(headerPanelId).show();
        } else {
            this.setState({groupingEnabled: false});
            $(headerPanelId).hide();
        }
        this.uiObjectInstance.gridViewInstance.updateDimensions();
    };

    viewOptionsButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ViewOptions_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.uiObjectInstance.gridViewInstance.showColumnChooser();
    };

    addRowButtonOnClick = () => {
        this.uiObjectInstance.gridViewInstance.addRow();
    };

    exportButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Export_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        if (this.totalRecordCount > 2000) {
            LRH.ShowToast("We are unable to export your data. Make sure your data has less than 2000 rows.", "error", 5000);
            return;
        }
        this.uiObjectInstance.gridViewInstance.exportToExcel(false);
    };

    refreshButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Refresh_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.uiObjectInstance.gridViewInstance.refresh();
    };

    applyFilterButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ApplyFilter_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        $("#" + data.gridViewId + " .dx-datagrid-toolbar-button.dx-apply-button").trigger("click");
    };

    clearFilterButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ClearFilter_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.uiObjectInstance.gridViewInstance.clearFilter();
        this.uiObjectInstance.gridViewInstance.clearSorting();
    };

    onEditingStart = (data) => {
        this.setState({currentRowDataBeingEdited: data});
    };

    buttonPrevOnClick = (data) => {
        let $root = $("#GridViewPager_" + data.gridViewId);
        if ($(".leopard-page-button-container.previous", $root).hasClass("disabled") ||
            $root.hasClass("leopard-ui-disabled")) {
            return;
        }
        let pageIndex = this.uiObjectInstance.gridViewInstance.pageIndex();
        this.uiObjectInstance.gridViewInstance.pageIndex(pageIndex - 1);
    };

    buttonNextOnClick = (data) => {
        let $root = $("#GridViewPager_" + data.gridViewId);
        if ($(".leopard-page-button-container.next", $root).hasClass("disabled") ||
            $root.hasClass("leopard-ui-disabled")) {
            return;
        }
        let pageIndex = this.uiObjectInstance.gridViewInstance.pageIndex();
        this.uiObjectInstance.gridViewInstance.pageIndex(pageIndex + 1);
    };

    disableOrEnablePagerBasedOnRowCount = (rowCount, gridViewId) => {
        let $root = $("#GridViewPager_" + gridViewId);
        if ($root.length === 0) return;

        let currentPageInt = 1;
        let instance = this.uiObjectInstance.gridViewInstance;
        if (instance !== undefined && instance !== null) {
            currentPageInt = instance.pageIndex() + 1;
        }

        let firstPageDisabled = false;
        if (currentPageInt <= 1) firstPageDisabled = true;

        if (rowCount === 0) {
            $(".leopard-page-button-container.previous", $root).addClass("disabled");
            $(".leopard-page-button-container.next", $root).addClass("disabled");
        } else {
            let pageSize = parseInt($(".leopard-page-number.selected", $root).attr("pagesize"));
            if (pageSize <= rowCount) {
                $(".leopard-page-button-container.next", $root).removeClass("disabled");
            } else {
                $(".leopard-page-button-container.next", $root).addClass("disabled");
            }
            if (firstPageDisabled) {
                $(".leopard-page-button-container.previous", $root).addClass("disabled");
            } else {
                $(".leopard-page-button-container.previous", $root).removeClass("disabled");
            }
        }
        $(".leopard-pagenumber-current", $root).text(currentPageInt);
    };

    onEditorPreparing = (data) => {
        let e = data.e;
        if (e.parentType === "filterRow" && (e.dataType === "string" || e.dataType === "number")) {
            let columnDefinitionList = data.gridDefinition.columnDefinition;
            let continueProceed = false;
            let columnDef = null;

            for (var i = 0; i < columnDefinitionList.length; i++) {
                if (!LDH.IsObjectNull(columnDefinitionList[i].allowAutoComplete) &&
                    columnDefinitionList[i].allowAutoComplete === true &&
                    columnDefinitionList[i].columnName === e.dataField) {
                    continueProceed = true;
                    columnDef = columnDefinitionList[i];
                }
            }
            if (continueProceed === false) return;

            let autoCompleteOperation = "startswith";
            if (!LDH.IsObjectNull(columnDef.autoCompleteOperation) &&
                columnDef.autoCompleteOperation.length > 0) {
                autoCompleteOperation = columnDef.autoCompleteOperation;
            }

            let url = this.props.GetDataFromUrl;
            if (this.props.useStateStore === null || this.props.useStateStore === false) {
                url = window.GetDataTableByName + this.props.GetDataFromUrl;
            }
            e.editorName = "dxSelectBox";
            e.editorOptions.displayExpr = e.dataField;
            e.editorOptions.valueExpr = e.dataField;
            e.editorOptions.searchEnabled = true;
            e.editorOptions.searchMode = "contains";
            e.editorOptions.searchTimeout = 1000;
            e.editorOptions.minSearchLength = 1;
            e.editorOptions.showDropDownButton = false;
            e.editorOptions.showDataBeforeSearch = false;
            e.editorOptions.noDataText = "Enter value to search...";
            e.editorOptions.dataSource = LRH.InitCustomStoreForAutoComplete(
                url, data.gridViewId, autoCompleteOperation);
        }
    }

    render() {
        let gridViewState = this.props.state.gridViewState;
        let currentState = this.state;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            currentState = gridViewState.filter(gridView => {
                return gridView.gridViewId === this.props.GridViewId;
            });
            if (currentState !== undefined && currentState !== null && currentState.length > 0) {
                currentState = currentState[0];
            }
        } else {
            if (this.state.customStore === null) {
                return (<div>Retrieving data, please wait...</div>);
            }
        }

        if (currentState === undefined || currentState === null || currentState.length === 0) {
            return (<div>Retrieving data, please wait...</div>);
        }

        let showAddButton = false;
        let showDeleteButton = false;
        let showEditButton = false;

        if (this.props.commandButtons !== undefined && this.props.commandButtons !== null &&
            this.props.commandButtons.length > 0) {
            for (var k = 0; k < this.props.commandButtons.length; k++) {
                if (this.props.commandButtons[k] === "modify-row") {
                    showEditButton = true;
                }
                if (this.props.commandButtons[k] === "add-row") {
                    showAddButton = true;
                }
                if (this.props.commandButtons[k] === "delete-row") {
                    showDeleteButton = true;
                }
            }
        }

        let allowEditing = false;
        if (showEditButton || showAddButton || showDeleteButton) {
            allowEditing = true;
        }

        let gridDefinition = null;
        if (this.props.useStateStore === true) {
            gridDefinition = this.gridDefinition;
        } else {
            gridDefinition = currentState.gridDefinition;
        }

        return (
            <React.Fragment>
                <LeopardGridViewToolbar groupingEnabled={this.state.groupingEnabled}
                                        groupingButtonOnClick={(e) => this.groupingButtonOnClick(e)}
                                        gridViewId={this.props.GridViewId} minHeight={this.props.minHeightToolbar}
                                        addRowButtonOnClick={(e) => this.addRowButtonOnClick(e)}
                                        explicitFilterOption={this.props.explicitFilterOption}
                                        gridDefinition={gridDefinition}
                                        applyFilterButtonOnClick={(e) => this.applyFilterButtonOnClick(e)}
                                        clearFilterButtonOnClick={(e) => this.clearFilterButtonOnClick(e)}
                                        refreshButtonOnClick={() => this.refreshButtonOnClick({gridViewId: this.props.GridViewId})}
                                        viewOptionsButtonOnClick={() => this.viewOptionsButtonOnClick({gridViewId: this.props.GridViewId})}
                                        exportButtonOnClick={() => this.exportButtonOnClick({gridViewId: this.props.GridViewId})}
                                        showAddButton={showAddButton}/>

                <BuildLeopardGridView setGridViewInstance={this.setGridViewInstance} thisComp={this}
                                      currentState={currentState} gridDefinition={gridDefinition}
                                      onRowExpanding={(e) => this.onRowExpanding(e)}
                                      onInitialized={(e) => this.onInitialized(e)}
                                      onDataErrorOccurred={(e) => this.onDataErrorOccurred(e)}
                                      onToolbarPreparing={(e) => this.onToolbarPreparing(e)}
                                      gridViewId={this.props.GridViewId}
                                      viewOptionsText={this.props.viewOptionsText}
                                      groupingEnabled={false} buttonNextOnClick={(e) => this.buttonNextOnClick(e)}
                                      buttonPrevOnClick={(e) => this.buttonPrevOnClick(e)}
                                      allowEditing={allowEditing}
                                      onEditorPreparing={(e) => this.onEditorPreparing(e)}
                                      explicitFilterOption={this.props.explicitFilterOption}
                                      columnResizingMode={this.props.columnResizingMode}
                                      filterBuilderValue={this.props.filterBuilderValue}
                                      onEditingStart={(e) => this.onEditingStart(e)}
                                      limitedColumns={this.props.columnFieldList.limitedColumns}
                                      showAddButton={showAddButton} showDeleteButton={showDeleteButton}
                                      showEditButton={showEditButton} onContentReady={(e) => this.onContentReady(e)}
                                      currentRowDataBeingEdited={this.state.currentRowDataBeingEdited}
                                      disabledFieldsForEditing={this.props.disabledFieldsForEditing}
                                      hiddenFieldsForEditing={this.props.hiddenFieldsForEditing}/>
            </React.Fragment>
        );
    }
}

const BuildLeopardGridView =
    ({
         setGridViewInstance, currentState, onEditingStart, onContentReady, explicitFilterOption,
         onToolbarPreparing, onRowExpanding, gridViewId, groupingEnabled, buttonNextOnClick,
         buttonPrevOnClick, viewOptionsText, allowEditing, limitedColumns, onInitialized,
         columnResizingMode, filterBuilderValue, onDataErrorOccurred, showAddButton, showDeleteButton,
         showEditButton, disabledFieldsForEditing, hiddenFieldsForEditing, currentRowDataBeingEdited,
         thisComp, onEditorPreparing, gridDefinition
     }) => {
        if (LDH.IsValueEmpty(explicitFilterOption) === true) {
            explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
        }

        thisComp.optimizePagerForLargeDataset = gridDefinition.optimizePagerForLargeDataset;
        if (LDH.IsValueEmpty(thisComp.optimizePagerForLargeDataset)) {
            thisComp.optimizePagerForLargeDataset = true;
        }

        LDH.ParseDevExtremeFilterString(filterBuilderValue);
        let columnComponent = LeopardGridViewColumnBuilder(gridDefinition, limitedColumns);

        LeopardStaticUIConfig.Global_GridViewCurrentEditRowData = currentRowDataBeingEdited;
        let editFormComponent = LeopardGridViewEditFormBuilder(gridDefinition, limitedColumns,
            disabledFieldsForEditing, hiddenFieldsForEditing);

        return (
            <React.Fragment>
                <DataGrid cacheEnabled={LeopardStaticUIConfig.GridView_CacheEnabled}
                          dataSource={currentState.customStore} showBorders={LeopardStaticUIConfig.showBorders}
                          onEditingStart={(e) => onEditingStart(e)} onContentReady={(e) => onContentReady(e)}
                          allowColumnResizing={LeopardStaticUIConfig.GridView_AllowColumnResizing}
                          columnMinWidth={LeopardStaticUIConfig.GridView_ColumnMinWidth}
                          columnAutoWidth={LeopardStaticUIConfig.GridView_ColumnAutoWidth}
                          highlightChanges={LeopardStaticUIConfig.GridView_HighlightChanges}
                          onEditorPreparing={(e) => onEditorPreparing({
                              e: e, gridViewId: gridViewId, gridDefinition: gridDefinition
                          })}
                          repaintChangesOnly={LeopardStaticUIConfig.GridView_RepaintChangesOnly}
                          columnResizingMode={columnResizingMode} filterValue={filterBuilderValue}
                          allowColumnReordering={LeopardStaticUIConfig.GridView_AllowColumnReordering}
                          hoverStateEnabled={LeopardStaticUIConfig.GridView_HoverStateEnabled} id={gridViewId}
                          onRowExpanding={(e) => onRowExpanding(e)} ref={setGridViewInstance}
                          className="leopard-gridview-control" onDataErrorOccurred={(e) => onDataErrorOccurred(e)}
                          onInitialized={(e) => onInitialized(e)} onToolbarPreparing={(e) => onToolbarPreparing(e)}
                          rowAlternationEnabled={LeopardStaticUIConfig.GridView_RowAlternationEnabled}>
                    {
                        LDH.IsObjectNull(gridDefinition) ? "" : columnComponent
                    }
                    <ColumnChooser enabled={LeopardStaticUIConfig.ColumnChooser_Enabled} title={viewOptionsText}/>
                    {
                        allowEditing === false ? "" :
                            <Editing mode={'popup'} allowUpdating={allowEditing && showEditButton}
                                     allowDeleting={allowEditing && showDeleteButton}
                                     allowAdding={allowEditing && showAddButton} refreshMode={"repaint"}>
                                <Popup title={'Add/Edit Record'} showTitle={true} width={700} height={345}>
                                    <Position my={'middle'} at={'middle'} of={window}/>
                                </Popup>
                                <Form>{editFormComponent}</Form>
                            </Editing>
                    }
                    <Export enabled={true} fileName={viewOptionsText} allowExportSelectedData={true}/>
                    <LoadPanel enabled={LeopardStaticUIConfig.LoadPanel_ShowIndicator}
                               showPane={LeopardStaticUIConfig.LoadPanel_ShowPane}/>
                    <FilterRow visible={LeopardStaticUIConfig.GridView_FilterRow}
                               applyFilter={explicitFilterOption}/>
                    {/* <Selection mode={LeopardStaticUIConfig.Selection_Mode}
                    selectAllMode={LeopardStaticUIConfig.Selection_SelectAllMode}
                    showCheckBoxesMode={LeopardStaticUIConfig.Selection_ShowCheckBoxesMode} /> */}
                    <Sorting mode={LeopardStaticUIConfig.Sorting_Mode}/>
                    <HeaderFilter visible={LeopardStaticUIConfig.HeaderFilter_Visible}/>
                    <ColumnFixing enabled={LeopardStaticUIConfig.ColumnFixing_Enabled}/>
                    <RemoteOperations sorting={LeopardStaticUIConfig.RemoteOperations_Sorting}
                                      paging={LeopardStaticUIConfig.RemoteOperations_Paging}
                                      summary={LeopardStaticUIConfig.RemoteOperations_Summary}
                                      grouping={LeopardStaticUIConfig.RemoteOperations_Grouping}
                                      filtering={LeopardStaticUIConfig.RemoteOperations_Filtering}
                                      groupPaging={LeopardStaticUIConfig.RemoteOperations_GroupPaging}/>
                    <Paging defaultPageSize={LeopardStaticUIConfig.Paging_DefaultPageSize}/>
                    <Pager visible={thisComp.optimizePagerForLargeDataset === false}
                           showPageSizeSelector={thisComp.optimizePagerForLargeDataset === false}
                           allowedPageSizes={LeopardStaticUIConfig.Pager_allowedPageSizes}
                           showInfo={thisComp.optimizePagerForLargeDataset === false}
                           showNavigationButtons={thisComp.optimizePagerForLargeDataset === false}/>
                    {/* <StateStoring enabled={LeopardStaticUIConfig.StateStoring_Enabled}
                    storageKey={"PersistState_" + gridViewId}
                    type={LeopardStaticUIConfig.StateStoring_Type} />
                <MasterDetail enabled={false} component={
                    (e) => LeopardGridViewPlaceHolder(e, currentState, gridViewId, uiObjectInstance,
                        gridDefinitionAsChild)
                } /> */}
                </DataGrid>
                {thisComp.optimizePagerForLargeDataset === false ? "" :
                    <div className={"leopard-page-container"} id={"GridViewPager_" + gridViewId}>
                        <div className={"leopard-page-number selected"} pagesize="10">10</div>
                        <div className={"leopard-page-number"} pagesize="25">25</div>
                        <div className={"leopard-page-number"} pagesize="50">50</div>

                        <div className={"leopard-page-button-container next"}>
                            <i className={"fas fa-chevron-right"} style={{fontSize: "25px", float: "right"}}
                               onClick={(e) => buttonNextOnClick({e: e, gridViewId: gridViewId})}></i>
                            <a className="leopard-page-button" style={{marginRight: "5px"}} href={"#_"}
                               onClick={(e) => buttonNextOnClick({e: e, gridViewId: gridViewId})}>Next</a>
                        </div>
                        <div className={"leopard-page-button-container previous"}>
                            <i className={"fas fa-chevron-left"} style={{fontSize: "25px"}}
                               onClick={(e) => buttonPrevOnClick({e: e, gridViewId: gridViewId})}></i>
                            <a className="leopard-page-button" style={{marginRight: "10px"}} href={"#_"}
                               onClick={(e) => buttonPrevOnClick({e: e, gridViewId: gridViewId})}>Previous</a>
                        </div>
                        <div className={"leopard-page-infotext"}>
                            Page <span className={"leopard-pagenumber-current"}></span>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    };

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

const SendDataToReducer = (dispatch) => {
    return {
        InitGridData: (data, id) => {
            dispatch(InitGridData(data, id));
        },
        InitCustomStore: (store, id) => {
            dispatch(InitCustomStore(store, id));
        },
        UpdateGridViewDefinition: (data, id) => {
            dispatch(UpdateGridViewDefinition(data, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardGridView);