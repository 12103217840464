import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import RGL, {WidthProvider} from "react-grid-layout";
import {Button} from 'devextreme-react';

import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardChildGridView from '../foundation/LeopardChildGridView';
import LeopardChildChart from '../foundation/LeopardChildChart';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

class LeopardTemplateView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            templateLayout: null,
            dataViewList: [],
            dashboardInitialized: false
        };

        this.uiInstances = [];
        this.ajaxLoadDataCount = 0;
        this.dashboardLayout = [];
        this.dataViewListToAdd = [];
        this.addDataViewListProcess = null;
    }

    componentDidMount = () => {
        this.loadLayoutDefinitionByAjaxRequest();
    };

    componentWillUnmount = () => {
        this.uiInstances = [];
        this.setState({templateLayout: []});
    };

    loadLayoutDefinitionByAjaxRequest = () => {
        let that = this;

        let userProfile = that.props.state.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let dashboardId = this.props.menuItem.dashboardId;

        // Get dashboard by id.
        LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (data) {
            let layout = data.dashboardLayout;
            that.setState({templateLayout: layout});
            that.dashboardLayout = layout;
            that.dataViewListToAdd = [];

            for (var i = 0; i < layout.length; i++) {
                let dataViewId = layout[i].definition.dataViewId;

                // Get each data view by id.
                LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (dataView) {
                    if (dataView === null || dataView.length === 0) {
                        that.ajaxLoadDataCount += 1;
                        if (that.ajaxLoadDataCount >= layout.length) {
                            that.setState({dashboardInitialized: true});
                        }
                    } else {
                        // Get column list.
                        let tableName = dataView.dataSourceUrl;
                        let url = window.ReportsUrlParameter + dataView.dataSourceUrl;
                        let limitedColumns = [];

                        if (!LDH.IsObjectNull(dataView.dataViewNote) && !LDH.IsObjectNull(dataView.dataViewNote.gridViewDefinition)) {
                            for (var u = 0; u < dataView.dataViewNote.gridViewDefinition.columnDefinition.length; u++) {
                                if (!LDH.IsValueEmpty(dataView.dataViewNote.gridViewDefinition.columnDefinition[u].isEnabled) &&
                                    dataView.dataViewNote.gridViewDefinition.columnDefinition[u].isEnabled === false) {
                                    limitedColumns.push(dataView.dataViewNote.gridViewDefinition.columnDefinition[u].columnName);
                                }
                            }
                        }

                        LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(url, tableName, limitedColumns, function (data) {
                            dataView.columnFieldList = data;
                            that.dataViewListToAdd.push(dataView);
                            that.ajaxLoadDataCount += 1;

                            if (that.ajaxLoadDataCount >= layout.length) {
                                that.setState({dashboardInitialized: true});
                            }
                        }, function (error, sessionTimeout) {
                            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                                error.message.indexOf("Cannot read property '") !== -1 &&
                                error.message.indexOf("' of undefined") !== -1) {
                                LRH.ShowToast("Your Dashboard might have been deleted or moved. " +
                                    "Please refresh this page to update the Control Centre.", "error", 5000);
                            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve data for your Dashboard. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                                localStorage.setItem("LoadFirstMenuItem", false);
                            }
                            LRH.ShowOrHideMenuLoadingProgress(false);
                            LRH.ShowUnhandledExceptionForMasterContent();
                        });
                    }
                }, function (error, sessionTimeout) {
                    if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                        error.message.indexOf("Cannot read property '") !== -1 &&
                        error.message.indexOf("' of undefined") !== -1) {
                        LRH.ShowToast("Your Dashboard might have been deleted or moved. " +
                            "Please refresh this page to update the Control Centre.", "error", 5000);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve data for your Dashboard. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    LRH.ShowOrHideMenuLoadingProgress(false);
                    LRH.ShowUnhandledExceptionForMasterContent();
                })
            }
            that.addDataViewList();
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Dashboard might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Dashboard. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    addDataViewList = () => {
        let that = this;
        let count = 0;

        that.addDataViewListProcess = setInterval(() => {
            if (this.state.dashboardInitialized === true) {
                this.setState({...this.state, dataViewList: this.dataViewListToAdd}, function () {
                    LRH.ShowOrHideMenuLoadingProgress(false);
                });
                clearInterval(that.addDataViewListProcess);
            }
            count++;
            if (count >= 600) {
                LRH.ShowOrHideMenuLoadingProgress(false);
                LRH.ShowUnhandledExceptionForMasterContent();
                clearInterval(that.addDataViewListProcess);
                LRH.ShowToast("Failed to load data. Please refresh your browser and try again.", "error", 5000);
            }
        }, 100);
    };

    updateWindowDimensionsRequiredForChart = () => {
        LRH.TriggerWindowResizeEvent();
    };

    updateWindowDimensionsRequiredForMap = () => {
        LRH.TriggerWindowResizeEvent();
    };

    onResizeCallback = (layout, oldItem, newItem, placeholder, e, element) => {
        let $parent = $(element).closest(".react-grid-item");
        if ($parent === null || $parent.length === 0) return;

        let $layout = $(".leopard-templateview-cell-layout", $parent);
        if ($layout === null || $layout.length === 0) return;

        let layoutId = $layout.attr("custom_attr_id").trim();
        let height = $parent.height();
        this.dashboardLayout = layout;

        for (var i = 0; i < this.uiInstances.length; i++) {
            let instanceObj = this.uiInstances[i];
            if (instanceObj.instance === null) continue;
            if (instanceObj.id === layoutId && instanceObj.type === "chart") {
                instanceObj.instance.option("size", {height: height - 50});
            }

            if (instanceObj.id === layoutId && instanceObj.type === "map") {
                instanceObj.instance.option("height", height - 40);
            }

            if (instanceObj.id === layoutId && instanceObj.type === "datagrid") {
                if (instanceObj.optimizePagerForLargeDataset){
                    height = height - 33;
                }
                instanceObj.instance.option("height", height - 75);
            }
        }
    };

    resizeOnAllPanels = () => {
        let that = this;
        var $root = $(".leopard-right-panel-container");
        $(".react-grid-item", $root).each(function () {
            let $handle = $(".react-resizable-handle", $(this));
            that.onResizeCallback(null, null, null, null, null, $handle);
        });
    };

    setGridViewInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (var i = 0; i < this.uiInstances.length; i++) {
            if (this.uiInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiInstances.push(instanceData);
        }
        this.props.setGridViewInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setChartInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (var i = 0; i < this.uiInstances.length; i++) {
            if (this.uiInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiInstances.push(instanceData);
        }
        this.props.setChartInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setMapInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (var i = 0; i < this.uiInstances.length; i++) {
            if (this.uiInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiInstances.push(instanceData);
        }
        this.props.setMapInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    onButtonSaveLayoutOnClick = () => {
        let that = this;
        if (this.dashboardLayout === null || this.dashboardLayout.length === 0) {
            LRH.ShowToast("Your Dashboard layout has been successfully saved.", "success", 5000);
            return;
        }

        $(".leopard-savelayout-loading").css("visibility", "visible");
        setTimeout(() => {
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let dashboardId = that.props.menuItem.dashboardId;

            var organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            LRH.ConsoleLog(that, "LeopardTemplateView_OnButtonSaveLayoutOnClick",
                that.dashboardLayout);

            LeopardAjaxHelper.UpdateDashboardLayout(userId, organizationId,
                dashboardId, that.dashboardLayout, function () {
                    LRH.ShowToast("Your Dashboard layout has been successfully saved.", "success", 5000);
                    LRH.ConsoleLog(that, "LeopardTemplateView_SaveLayout_Success", true);
                    $(".leopard-savelayout-loading").css("visibility", "hidden");
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your Dashboard layout.", "error", 5000);
                    }
                    LRH.ConsoleLog(that, "LeopardTemplateView_SaveLayout_Failed", error);
                    $(".leopard-savelayout-loading").css("visibility", "hidden");
                });
        }, 100);
    };

    renderLoadingProgressIndicator = () => {
        if (this.state.dashboardInitialized && this.dataViewListToAdd.length === 0) {
            LRH.ShowOrHideMenuLoadingProgress(false);
            return (
                <React.Fragment>
                    <div style={{textAlign: "center", marginTop: "120px", fontFamily: "Arial", fontSize: "20px"}}>
                        <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                        <div style={{
                            width: "300px",
                            marginLeft: "auto",
                            fontSize: "12px",
                            color: "#7F7F7F",
                            marginRight: "auto"
                        }}>
                            Oops! There is no content available to display. Please assign a Data View to this Dashboard
                            first.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        LRH.ShowOrHideMenuLoadingProgress(true);
        return (
            <React.Fragment>
        <span className={"leopard-loading-icon"} style={{width: "100%", height: "100%", marginLeft: "0px"}}>
          <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
          <span className="leopard-largeloading-progress-text">Loading data...</span>
        </span>
            </React.Fragment>
        );
    };

    renderBlankDataViewContent = (item) => {
        return (
            <div key={item.i}>
                <div className="leopard-templateview-cell-layout" custom_attr_id={item.definition.dashboardItemId}>
                    <div className={"leopard-templateview-cell-topbar leopard-bgcolor-orange"}>
                        <div className="topmenu-arrow-indicator"></div>
                        <span className="leopard-moving-handler-text">
              Empty Data View
            </span>
                    </div>
                    <div className="leopard-templateview-cell-content">
                        <div style={{textAlign: "center", marginTop: "120px", fontFamily: "Arial", fontSize: "20px"}}>
                            <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                            <div style={{
                                width: "250px",
                                marginLeft: "auto",
                                fontSize: "12px",
                                color: "#7F7F7F",
                                marginRight: "auto"
                            }}>
                                Oops! This content is currently blank due to the original Data View being deleted.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    initializeGridViewLayout = () => {
        let that = this;

        let dataViewList = this.state.dataViewList;
        var dataView = null;
        var dataViewNote = null;
        var dataSourceUrl = null;

        let result = this.state.templateLayout.map(function (item) {
            if (item.definition.dashboardType === "datagrid") {
                dataView = null;
                for (var j = 0; j < dataViewList.length; j++) {
                    if (item.definition.dataViewId === dataViewList[j].dataViewId) {
                        dataView = dataViewList[j];
                        break;
                    }
                }
                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }
                dataViewNote = dataView.dataViewNote;

                var gridDefinition = dataViewNote.gridViewDefinition;
                dataSourceUrl = window.GetDataTableByName + "?tableName=" + dataView.dataSourceUrl;

                let version = LeopardStaticUIConfig.CssVersion;
                LRH.ApplyCustomStyle(dataViewNote.styleDefinition, version);

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}>
                                <div className="topmenu-arrow-indicator"></div>
                                <span className="leopard-moving-handler-text">
                  {dataView.dataViewName}
                </span>
                            </div>
                            <div className="leopard-templateview-cell-content">
                                {
                                    (LDH.IsObjectNull(dataView.columnFieldList) || LDH.IsObjectNull(dataView.columnFieldList.fullColumns) ||
                                        dataView.columnFieldList.fullColumns.length === 0) ? "" :
                                        <LeopardChildGridView gridViewId={item.definition.dashboardItemId}
                                                              gridDefinition={gridDefinition}
                                                              gridViewName={dataView.dataViewName}
                                                              gridViewHeight={"auto"}
                                                              viewOptionsText={dataView.dataViewName}
                                                              setGridViewInstance={that.setGridViewInstance}
                                                              dataSourceUrl={dataSourceUrl}
                                                              columnFieldList={dataView.columnFieldList}
                                        />
                                }
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "chart") {
                dataView = null;
                for (var k = 0; k < dataViewList.length; k++) {
                    if (item.definition.dataViewId === dataViewList[k].dataViewId) {
                        dataView = dataViewList[k];
                        break;
                    }
                }
                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                dataViewNote = dataView.dataViewNote;
                dataSourceUrl = window.GetDataTableByName + "?tableName=" + dataView.dataSourceUrl;
                var dataViewNoteFromMenu = item.definition.dataViewNote;

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}>
                                <div className="topmenu-arrow-indicator"></div>
                                <span className="leopard-moving-handler-text">
                  {dataView.dataViewName}
                </span>
                            </div>
                            <div className="leopard-templateview-cell-content">
                                <LeopardChildChart dataSourceUrl={dataSourceUrl} definition={dataViewNote}
                                                   palette={['#c3a2cc', '#b7b5e0', '#e48cba']}
                                                   customizeTextYAxis={"#value#"}
                                                   seriesType={"bar"} useStateStore={true}
                                                   dataViewId={item.definition.dashboardItemId}
                                                   chartType={dataViewNoteFromMenu.chartType}
                                                   updateWindowDimensionsRequired={that.updateWindowDimensionsRequiredForChart}
                                                   setChartInstance={that.setChartInstance}/>
                            </div>
                        </div>
                    </div>);
            }
            return (<div key={item.i}></div>);
        });
        return result;
    };

    render() {
        if (this.state.dataViewList !== null && this.state.dataViewList.length > 0 &&
            this.state.templateLayout !== null && this.state.dashboardInitialized === true) {

            let showGridViewAdminToolbar = false;
            if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
                showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
            }

            return (
                <React.Fragment>
                    {
                        showGridViewAdminToolbar === false ? "" :
                            <div style={{height: "20px", position: "relative", top: "-5px"}}>
                                <span style={{padding: "0 10px 0 15px"}}>
                                  <Button text={"Save Layout"} className="leopard-buttonlink"
                                          onClick={(e) => this.onButtonSaveLayoutOnClick(e)}/>
                                </span>
                                <span className={"leopard-savelayout-loading leopard-loading-icon"} style={{
                                    marginLeft: "0px", position: "relative", top: "5px", float: "none",
                                    visibility: "hidden"
                                }}>
                                  <i className="fas fa-spinner fa-pulse" style={{
                                      color: "#FF8000", fontSize: "25px"
                                  }}></i>
                                </span>
                            </div>
                    }
                    <ReactGridLayout className="layout" layout={this.state.templateLayout} cols={36} rowHeight={30}
                                     autoSize={true} isDraggable={true}
                                     draggableHandle={".leopard-templateview-cell-topbar"}
                                     onDragStop={this.onResizeCallback} onResize={this.onResizeCallback}
                                     onResizeStop={this.onResizeCallback} useCSSTransforms={false}>
                        {this.initializeGridViewLayout()}
                    </ReactGridLayout>
                </React.Fragment>
            );
        }
        return this.renderLoadingProgressIndicator();
    }
}

const ReactGridLayout = WidthProvider(RGL);

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

export default connect(RetrieveDataFromReducer)(LeopardTemplateView);