import React, {Component} from 'react';
import {Button} from 'devextreme-react';
import LeopardColumnDesigner from '../datashaping/LeopardColumnDesigner';
import LeopardStyleBuilder from '../datashaping/LeopardStyleBuilder';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import $ from 'jquery';
import LDH from "../helpers/LeopardDataHelper";

const RenderLeopardGridViewAdministrativeToolbar =
    ({
         gridViewId, gridViewBuilderButtonOnClick, styleBuilderButtonOnClick, gridViewBuilderVisible, parentComp,
         styleBuilderVisible, dataSourceUrl
     }) => {
        if (LDH.IsValueEmpty(dataSourceUrl) === true) return null;

        let isBothHidden = !gridViewBuilderVisible && !styleBuilderVisible;
        let showGridViewAdminToolbar = false;
        if (parentComp.props.state.permissions !== undefined && parentComp.props.state.permissions !== null) {
            showGridViewAdminToolbar = parentComp.props.state.permissions.ShowGridViewAdminToolbar;
        }

        return (
            <React.Fragment>
                {showGridViewAdminToolbar === false || isBothHidden === false ? "" :
                    (<div className={"leopard-no-whitespace"}>
                        <span style={{padding: "0 10px 0 15px", display: "inline"}}>
                            <Button className="leopard-buttonlink leopard-gridview-admin-toolbar"
                                    text={"Configure Grid"}
                                    id={"GridView_Admin_EditColumns_" + gridViewId}
                                    onClick={(e) => gridViewBuilderButtonOnClick({
                                        e: e, gridViewId: gridViewId
                                    })}/>
                        </span>
                        <span style={{padding: "0 0 0 5px", display: "inline"}}>
                            <Button className="leopard-buttonlink leopard-gridview-admin-toolbar"
                                    text={"Edit Style"} id={"GridView_Admin_EditStyle_" + gridViewId}
                                    onClick={(e) => styleBuilderButtonOnClick({e: e, gridViewId: gridViewId})}/>
                        </span>
                        <span id={"gridViewAdminToolbar_" + gridViewId} className="leopard-admintoolbar-loading">
                            <i className="fas fa-spinner fa-pulse"
                               style={{color: "rgb(255, 128, 0)", fontSize: "18px"}}></i>
                        </span>
                    </div>)
                }
            </React.Fragment>
        );
    };

class LeopardGridViewAdministrativeTools extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceUrl: null,
            showGridViewBuilder: false,
            showStyleBuilder: false,
            allowEditingGridView: false,
            allowDraggingGridView: false,
            allowDroppingGridView: false,
            filterBuilderValueGridView: "",
            commandButtonsGridView: [],
            disabledFieldsForEditingGridView: [],
            hiddenFieldsForEditingGridView: [],
            columnResizingModeGridView: LeopardStaticUIConfig.GridView_ColumnResizingMode
        }
    }

    gridViewBuilderButtonOnClick = (data) => {
        $("#gridViewAdminToolbar_" + data.gridViewId).show();
        $("#GridView_Admin_EditColumns_" + data.gridViewId).addClass("leopard-ui-disabled");
        $("#GridView_Admin_EditStyle_" + data.gridViewId).addClass("leopard-ui-disabled");

        setTimeout(() => {
            this.setState({showGridViewBuilder: true});
        }, 100);
    };

    styleBuilderButtonOnClick = (data) => {
        $("#gridViewAdminToolbar_" + data.gridViewId).show();
        $("#GridView_Admin_EditColumns_" + data.gridViewId).addClass("leopard-ui-disabled");
        $("#GridView_Admin_EditStyle_" + data.gridViewId).addClass("leopard-ui-disabled");

        setTimeout(() => {
            this.setState({showStyleBuilder: true});
        }, 100);
    };

    styleBuilderCancelButtonOnClick = () => {
        this.setState({showGridViewBuilder: false});
        this.setState({showStyleBuilder: false});
    };

    gridViewBuilderCancelButtonOnClick = () => {
        this.setState({showGridViewBuilder: false});
        this.setState({showStyleBuilder: false});
    };

    render() {
        return (
            <React.Fragment>
                {
                    <RenderLeopardGridViewAdministrativeToolbar
                        gridViewId={this.props.gridViewId}
                        gridViewBuilderVisible={this.state.showGridViewBuilder}
                        styleBuilderVisible={this.state.showStyleBuilder}
                        parentComp={this.props.parentComp} dataSourceUrl={this.props.dataSourceUrl}
                        styleBuilderButtonOnClick={(e) => this.styleBuilderButtonOnClick(e)}
                        gridViewBuilderButtonOnClick={(e) => this.gridViewBuilderButtonOnClick(e)}
                    />
                }
                {
                    this.state.showGridViewBuilder === false ? "" :
                        <div className="leopard-column-designer" custom_attr_gridviewid={this.props.gridViewId}>
                            <LeopardColumnDesigner
                                gridViewId={this.props.gridViewId}
                                columnFieldList={this.props.columnFieldList}
                                gridViewDefinition={this.props.gridViewDefinition}
                                gridViewBuilderVisible={this.state.showGridViewBuilder}
                                gridViewBuilderCancelButtonOnClick={() => this.gridViewBuilderCancelButtonOnClick()}
                                applyGridViewConfigurationOnDataView={this.props.applyGridViewConfigurationOnDataView}
                                updateGridViewDefinitionOnSingleDataView={this.props.updateGridViewDefinitionOnSingleDataView}/>
                        </div>
                }
                {
                    this.state.showStyleBuilder === false ? "" :
                        <div className="leopard-style-builder" custom_attr_gridviewid={this.props.gridViewId}>
                            <LeopardStyleBuilder
                                gridViewId={this.props.gridViewId}
                                styleBuilderVisible={this.state.showStyleBuilder}
                                custom_attr_gridviewid={this.props.gridViewId}
                                gridViewDefinition={this.props.gridViewDefinition}
                                styleBuilderCancelButtonOnClick={() => this.styleBuilderCancelButtonOnClick()}
                                styleDefinition={this.props.styleDefinition}/>
                        </div>
                }
            </React.Fragment>
        );
    }
}

export default LeopardGridViewAdministrativeTools;