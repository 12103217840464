import React from 'react';
import $ from 'jquery';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {confirm} from 'devextreme/ui/dialog';
import {API} from "aws-amplify";
import LDH from './LeopardDataHelper';
import LRH from './LeopardReactHelper';
import notify from 'devextreme/ui/notify';
import LeopardTestHelper from './LeopardTestHelper';
import LeopardSecurity from '../security/LeopardSecurity';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';

class LeopardReactHelper extends React.Component {
    static ConsoleLog = (comp, name, args) => {
        if (localStorage.getItem("ConsoleLog") === "lite") {
            console.log(name, args);
        } else if (localStorage.getItem("ConsoleLog") === "detail") {
            console.log(name, args, "##### Component #####", comp);
        }
    };

    static ShowToast = (text, type, displayTime) => {
        notify(text, type, displayTime);
    };

    static ShowDialog = (text, title, callbackOK, callbackCancel) => {
        confirm(text, title).then((dialogResult) => {
            if (dialogResult === true) {
                callbackOK();
            }
            if (callbackCancel !== undefined && callbackCancel !== null) {
                callbackCancel();
            }
        });
    };

    static ShowOrHideMenuLoadingProgress = (show) => {
        if (show) {
            setTimeout(() => {
                $(".leopard-screen-menucover").show();
            }, 100);
        } else {
            setTimeout(() => {
                $(".leopard-screen-menucover").hide();
            }, 100);
        }
    };

    static ShowUnhandledExceptionForMasterContent = () => {
        $(".leopard-right-panel-container .leopard-loading-icon").hide();
    };

    static IsInternetExplorerBrowser = () =>{
        return /*@cc_on!@*/false || !!document.documentMode;
    };

    static ShowOrHideApplicationBackgroundCover = (show) => {
        if (show) {
            setTimeout(() => {
                $(".leopard-screen-cover").show();
            }, 100);
        } else {
            setTimeout(() => {
                $(".leopard-screen-cover").hide();
            }, 100);
        }
    };

    static IsControlCentreInTestMode = () => {
        return LDH.IsValueTrue(localStorage.getItem("IsTestMode"));
    };

    static BindValidationRulesToUIObject = (data, uiObjectInstance) => {
        let validationResult = true;
        let defaultBlankValue = data.defaultBlankValue;
        let instance = uiObjectInstance[data.input];

        if (instance === undefined || instance === null) {
            return validationResult;
        }
        if (instance.option("visible") === false) {
            return validationResult;
        }

        for (var i = 0; i < data.rules.length; i++) {
            if (data.rules[i].rule === "required") {
                data.e.rule.message = "This field cannot be blank";
                if (LDH.IsValueEmpty(defaultBlankValue) === false) {
                    if (data.e.value === defaultBlankValue) {
                        validationResult = false;
                        break;
                    }
                } else {
                    if (LDH.IsValueEmpty(data.e.value) === true) {
                        validationResult = false;
                        break;
                    }
                }
            }
            if (data.rules[i].rule === "safeinput") {
                data.e.rule.message = "This value is not safe";
                if (LeopardSecurity.IsInputValueDangerous(data.e.value)) {
                    validationResult = false;
                    break;
                }
            }
        }
        return validationResult;
    };

    static GetOperationByLoadOptions(loadOptions) {
        let operation = "";
        if (LDH.IsValueEmpty(loadOptions.group) === false &&
            LDH.IsValueEmpty(loadOptions.sort) === true &&
            LDH.IsValueEmpty(loadOptions.skip) === false &&
            LDH.IsValueEmpty(loadOptions.take) === false &&
            LDH.IsValueEmpty(loadOptions.filter) === false) {
            operation = "filtering";
        } else if (LDH.IsValueEmpty(loadOptions.group) === false &&
            LDH.IsValueEmpty(loadOptions.skip) === false &&
            LDH.IsValueEmpty(loadOptions.take) === false &&
            LDH.IsValueEmpty(loadOptions.sort) === true &&
            LDH.IsValueEmpty(loadOptions.filter) === true) {
            operation = "filtering";
        } else if (LDH.IsValueEmpty(loadOptions.group) === false &&
            LDH.IsValueEmpty(loadOptions.skip) === true &&
            LDH.IsValueEmpty(loadOptions.take) === true &&
            LDH.IsValueEmpty(loadOptions.sort) === true &&
            LDH.IsValueEmpty(loadOptions.filter) === false) {
            operation = "grouping";
        } else if (LDH.IsValueEmpty(loadOptions.group) === false &&
            LDH.IsValueEmpty(loadOptions.skip) === true &&
            LDH.IsValueEmpty(loadOptions.take) === true &&
            LDH.IsValueEmpty(loadOptions.sort) === true &&
            LDH.IsValueEmpty(loadOptions.filter) === true) {
            operation = "grouping";
        } else if (LDH.IsValueEmpty(loadOptions.sort) === true &&
            LDH.IsValueEmpty(loadOptions.skip) === false &&
            LDH.IsValueEmpty(loadOptions.take) === false &&
            LDH.IsValueEmpty(loadOptions.filter) === false &&
            LDH.IsValueEmpty(loadOptions.group) === true) {
            operation = "searching";
        } else if (LDH.IsValueEmpty(loadOptions.sort) === false &&
            LDH.IsValueEmpty(loadOptions.skip) === false &&
            LDH.IsValueEmpty(loadOptions.take) === false &&
            LDH.IsValueEmpty(loadOptions.filter) === true &&
            LDH.IsValueEmpty(loadOptions.group) === true) {
            operation = "sorting";
        } else if (LDH.IsValueEmpty(loadOptions.skip) === false &&
            LDH.IsValueEmpty(loadOptions.take) === false &&
            LDH.IsValueEmpty(loadOptions.sort) === true &&
            LDH.IsValueEmpty(loadOptions.filter) === true &&
            LDH.IsValueEmpty(loadOptions.group) === true) {
            operation = "paging";
        } else {
            operation = "other";
        }
        return operation;
    }

    static InitCustomStoreForChartView(url, httpType, callbackFunc) {
        return new DataSource(new CustomStore({
            loadMode: 'raw',
            load: () => {
                // --------------------- For Test Mode Only --------------------------
                if (LRH.IsControlCentreInTestMode() === true) {
                    return $.ajax({type: httpType, url: ""}).then(function () {
                        let data = LeopardTestHelper.GetTestDataTableForChart();
                        callbackFunc(data);
                        return data;
                    });
                }
                // ------------------------------------------------------------------

                return $.ajax({type: httpType, url: url}).then(function (result) {
                    let dataFromServer = result.data;
                    callbackFunc(dataFromServer);
                    return dataFromServer;
                })
            }
        }));
    }

    static InitCustomStoreForAutoComplete(url, gridViewId, autoCompleteOperation) {
        return new DataSource({
            store: new CustomStore({
                load: function (loadOptions) {
                    if (LDH.IsValueEmpty(loadOptions.searchValue) || loadOptions.searchValue === "null") {
                        return [];
                    }
                    let params = "";
                    params += "&offset=" + loadOptions.skip;
                    params += "&limit=" + loadOptions.take;

                    if (autoCompleteOperation === "startswith") {
                        params += "&filter=" + encodeURIComponent("{\"" + loadOptions.searchExpr + "\"" +
                            ":{\"Op_like\": \"" + loadOptions.searchValue + "%\"}}");
                    } else if (autoCompleteOperation === "contains") {
                        params += "&filter=" + encodeURIComponent("{\"" + loadOptions.searchExpr + "\"" +
                            ":{\"Op_like\": \"%" + loadOptions.searchValue + "%\"}}");
                    }

                    params += "&group=" + encodeURIComponent("[\"" + loadOptions.searchExpr + "\"]");
                    params += "&requireTotalCount=false";
                    LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, false);

                    return API.get("aws-api-gateway", url + params, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(data => {
                        LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
                        return data;
                    }).catch(() => {
                        LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
                        LRH.ShowToast("Unable to retrieve the data for the auto-complete.", "error", 5000);
                    });
                },
                byKey: function () {
                    return [];
                }
            })
        });
    }

    static InitCustomStoreForGridView(gridDefinition, gridViewId, url, httpType, filterData,
                                      limitedDataColumns, callbackFunc) {
        return new CustomStore({
            load: function (loadOptions) {
                $("#" + gridViewId + " .dx-datagrid-toolbar-button.dx-apply-button").hide();
                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, false);

                // --------------------- For Test Mode Only --------------------------
                if (LRH.IsControlCentreInTestMode() === true) {
                    return $.ajax({type: httpType, url: ""}).then(function () {
                        let data = LeopardTestHelper.GetTestDataTable();
                        let gridData = {data: data.items, totalCount: data.totalCount};
                        callbackFunc(gridData);
                        return gridData;
                    });
                }
                // ------------------------------------------------------------------

                let operation = LRH.GetOperationByLoadOptions(loadOptions);
                if (operation === "filtering" || operation === "grouping") {
                    let groupedColumnName = loadOptions.group[0].selector;
                    if (filterData.column !== groupedColumnName) {
                        filterData = {records: [], column: groupedColumnName};
                    }
                }

                let params = "";
                if (LDH.IsValueEmpty(loadOptions.filter) === false) {
                    let filterResult = LDH.ConvertDevExtremeFilteringParameterFormat(
                        loadOptions.filter);
                    filterResult = encodeURIComponent(filterResult);
                    params += "&filter=" + filterResult;
                }

                if (LDH.IsValueEmpty(loadOptions.group) === false) {
                    let groupData = "";
                    for (var i = 0; i < loadOptions.group.length; i++) {
                        groupData = loadOptions.group[i].selector;
                    }
                    params += "&group=" + encodeURIComponent("[\"" + groupData + "\"]");
                }

                if (LDH.IsValueEmpty(loadOptions.sort) === false) {
                    let sortData = "[";
                    for (var f = 0; f < loadOptions.sort.length; f++) {
                        let order = loadOptions.sort[f].desc ? "DESC" : "ASC";
                        sortData += "[\"" + loadOptions.sort[f].selector + "\", \"" + order + "\"]";
                        if (f < loadOptions.sort.length - 1) {
                            sortData += ",";
                        }
                    }
                    sortData += "]";
                    params += "&order=" + encodeURIComponent(sortData);
                }

                if (LDH.IsValueEmpty(loadOptions.skip) === false) {
                    params += "&offset=" + loadOptions.skip;
                }
                if (LDH.IsValueEmpty(loadOptions.take) === false) {
                    params += "&limit=" + loadOptions.take;
                }

                let optimizePagerForLargeDataset = gridDefinition.optimizePagerForLargeDataset;
                if (LDH.IsValueEmpty(optimizePagerForLargeDataset)) {
                    optimizePagerForLargeDataset = true;
                }

                if (optimizePagerForLargeDataset === true) {
                    params += "&requireTotalCount=false";
                } else {
                    params += "&requireTotalCount=true";
                }

                if (!LDH.IsObjectNull(limitedDataColumns) && limitedDataColumns.length > 0) {
                    params += "&attributes=" + encodeURIComponent("{\"exclude\":" +
                        JSON.stringify(limitedDataColumns) + "}");
                }

                let totalItemCount = LeopardStaticUIConfig.Global_GridViewTotalItemCount;

                if (operation === "grouping" && totalItemCount > 1000) {
                    LRH.ShowToast("Warning: Your data must be less than 100,000 rows in order to use this filter.", "warning", 5000);
                    LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
                    return null;
                }

                return API.get("aws-api-gateway", url + params, {
                    headers: {"Content-Type": "application/json"}
                }).then(data => {
                    if (LDH.IsValueEmpty(data.data.isFulfilled) === false) {
                        data = {data: [], totalCount: 0};
                    }

                    if (optimizePagerForLargeDataset === true) data.totalCount = 1000000000;

                    let gridData = {data: data.data, totalCount: data.totalCount};
                    LeopardStaticUIConfig.Global_GridViewTotalItemCount = data.totalCount;

                    if (operation === "filtering" && filterData.records.data === undefined &&
                        data.data.length > 0 && data.totalCount > 0) {
                        gridData = [];
                        for (var i = 0; i < data.data.length; i++) {
                            gridData.push({key: data.data[i][filterData.column]});
                        }
                        filterData.records = gridData;
                        callbackFunc(filterData, "filtering");
                        return filterData.records;
                    } else if (operation === "filtering" && filterData.data !== undefined &&
                        filterData.records.data !== null &&
                        filterData.records.data.length > 0 && data.data.length > 0 &&
                        data.totalCount > 0) {
                        for (var index = 0; index < data.data.length; index++) {
                            filterData.records.data.push(data.data[index]);
                        }
                        callbackFunc(filterData, "filtering");
                        return filterData.records;
                    } else if (operation === "grouping" && filterData.records.data === undefined &&
                        data.data.length > 0 && data.totalCount > 0) {
                        gridData = [];
                        for (var s = 0; s < data.data.length; s++) {
                            let formatData = "";
                            let value = data.data[s][filterData.column];
                            if (value !== null && value !== "") {
                                formatData = moment(value).format("YYYY/MM/DD");
                            }
                            let found = false;
                            for (var v = 0; v < gridData.length; v++) {
                                if (gridData[v].key === formatData) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found === false) gridData.push({key: formatData});
                        }
                        filterData.records = gridData;
                        callbackFunc(filterData, "filtering");
                        return filterData.records;
                    }

                    callbackFunc(gridData, "other");
                    return gridData;
                }).catch(() => {
                    LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
                    LRH.ShowToast("Unable to retrieve the data for the Grid View.", "error", 5000);
                });
            },
            update: function () {
                LRH.ShowToast("Sorry, this feature is currently under development. " +
                    "It will be available in the next major release.", "error", 5000);
                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
            },
            remove: function () {
                LRH.ShowToast("Sorry, this feature is currently under development. " +
                    "It will be available in the next major release.", "error", 5000);
                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
            },
            insert: function () {
                LRH.ShowToast("Sorry, this feature is currently under development. " +
                    "It will be available in the next major release.", "error", 5000);
                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
            }
        });
    }

    static EnableOrDisableGridViewToolbarButtons = (gridViewId, enabled) => {
        if (enabled === true) {
            $("#gridViewToobar_" + gridViewId).hide();
            $("#GridView_TopBar_Refresh_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_Export_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ViewOptions_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_AddRecord_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ApplyFilter_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ClearFilter_" + gridViewId).removeClass("leopard-ui-disabled");
            $(".leopard-pagination-blocker", $("#" + gridViewId)).hide();
            $("#GridViewPager_" + gridViewId).removeClass("leopard-ui-disabled");
        } else {
            $("#gridViewToobar_" + gridViewId).show();
            $("#GridView_TopBar_Refresh_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_Export_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ViewOptions_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_AddRecord_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ApplyFilter_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ClearFilter_" + gridViewId).addClass("leopard-ui-disabled");
            $(".leopard-pagination-blocker", $("#" + gridViewId)).show();
            $("#GridViewPager_" + gridViewId).addClass("leopard-ui-disabled");
        }
    };

    static TriggerWindowResizeEvent() {
        if (typeof (Event) === 'function') {
            window.dispatchEvent(new Event('resize'));
        } else {
            var evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }
    }

    static DownloadStringToFile(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    static DestoryDraggingOnGridView(gridViewId) {
        $('.ui-draggable.gridViewId_' + gridViewId, $("#" + gridViewId + " .dx-datagrid-content"))
            .not(".dx-pointer-events-target").each(function () {
            // TODO: needs to be destroyed to prevent memory leak.
            //$(this).draggable("destroy");
        });
    }

    static ApplyCustomStyle(styleDefinition, version) {
        if (styleDefinition !== undefined && styleDefinition !== null &&
            styleDefinition.length > 0) {
            var defaultStyle = '<link id="leopard-default-style" rel="stylesheet" type="text/css" href="/css/custom.css?v=' + version + '" />';
            var customStyle = '<style id="leopard-custom-style" type="text/css">' + styleDefinition + '</style>';

            $("#leopard-default-style").remove();
            $("head").append(defaultStyle);

            $("#leopard-custom-style").remove();
            $("head").append(customStyle);
        } else {
            $("#leopard-custom-style").remove();
        }
    }

    static InitDraggingOnGridView(gridViewId, gridViewInstance, allowDragging) {
        if (allowDragging === undefined || allowDragging === false) {
            return;
        }
        $('.draggable.gridViewId_' + gridViewId, $("#" + gridViewId + " .dx-datagrid-content"))
            .not(".dx-pointer-events-target").draggable({
            helper: 'clone',
            start: function (ev, ui) {
                var $drag = $(ui.helper);
                var $gridview = $("#" + gridViewId + " .dx-datagrid-content")
                    .not(".dx-pointer-events-target");
                var $hover = $(".dx-state-hover.gridViewId_" + gridViewId, $gridview);

                if ($drag.hasClass("dragging-object") === false) {
                    $drag.addClass("dragging-object");
                }

                var $rows = null;
                var count = 0;
                if (ev.altKey === false && ev.ctrlKey === false && ev.metaKey === false) {
                    if ($hover.length > 0 && !$hover.hasClass("dx-selection")) {
                        gridViewInstance.clearSelection();
                        $("#" + gridViewId + "_DragAndDropSelectionCount").text(1);
                    } else {
                        $rows = $(".dx-selection.gridViewId_" + gridViewId, $gridview);
                        count = $rows.not(".dragging-object").length;
                        $("#" + gridViewId + "_DragAndDropSelectionCount").text(count);
                    }
                } else {
                    if ($hover.length > 0 && !$hover.hasClass("dx-selection")) {
                        $rows = $(".dx-selection.gridViewId_" + gridViewId, $gridview);
                        count = $rows.length;
                        if ($rows.hasClass(".dragging-object") === false) {
                            count = $rows.length + 1;
                        }
                        $("#" + gridViewId + "_DragAndDropSelectionCount").text(count);
                    } else {
                        $rows = $(".dx-selection.gridViewId_" + gridViewId, $gridview);
                        count = $rows.not(".dragging-object").length;
                        $("#" + gridViewId + "_DragAndDropSelectionCount").text(count);
                    }
                }

                if ($drag.hasClass("dx-selection") === false) {
                    $drag.addClass("dx-selection");
                }
                var width = $("#" + gridViewId).width();
                var columnCount = $("td", $drag).not(".dx-command-adaptive").length;
                $drag.width(width).addClass("leopard-bgcolor-orange");
                $("td", $drag).not(".dx-command-adaptive").width(width / columnCount);
                $("#" + gridViewId + "_DragAndDropSelectionCount").hide();
            },
            stop: function () {
                var $gridview = $("#" + gridViewId + " .dx-datagrid-content")
                    .not(".dx-datagrid-content-fixed");
                var $row = $(".dragging-object", $gridview);

                if ($row.hasClass("dragging-object")) {
                    $row.removeClass("dragging-object");
                }
                $("#" + gridViewId + "_DragAndDropSelectionCount").hide();
            },
            drag: function (ev) {
                if (!$("#" + gridViewId + "_DragAndDropSelectionCount").is(":visible")) {
                    $("#" + gridViewId + "_DragAndDropSelectionCount").show();
                }
                $("#" + gridViewId + "_DragAndDropSelectionCount").css({
                    top: ev.pageY, left: ev.pageX
                });
            }
        });
    }
}

export default LeopardReactHelper;