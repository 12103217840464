import {Component} from 'react';
import LDH from '../helpers/LeopardDataHelper';

class LeopardStaticUIConfig extends Component {
    static GridView_ShowBorder = true;
    static GridView_CacheEnabled = false;
    static GridView_AllowEditing = true;
    static GridView_EditingMode = "row";
    static GridView_AllowColumnResizing = true;
    static GridView_ColumnResizingMode = "widget";
    static GridView_ExplicitFilterOption = "auto";
    static GridView_ColumnMinWidth = 50;
    static GridView_ColumnAutoWidth = true;
    static GridView_RepaintChangesOnly = true;
    static GridView_HighlightChanges = true;
    static GridView_AllowColumnReordering = true;
    static GridView_HoverStateEnabled = false;
    static GridView_GroupingEnabled = true;
    static GridView_RowAlternationEnabled = true;
    static GridView_FilterRow = true;
    static GridView_DefaultFilterValue = "";
    static ColumnChooser_Enabled = false;
    static Selection_Mode = "multiple";
    static LoadPanel_ShowPane = false;
    static LoadPanel_ShowIndicator = false;
    static Selection_SelectAllMode = "page";
    static Selection_ShowCheckBoxesMode = "onLongTap";
    static Sorting_Mode = "multiple";
    static HeaderFilter_Visible = true;
    static ColumnFixing_Enabled = true;
    static RemoteOperations_Sorting = true;
    static RemoteOperations_Paging = true;
    static RemoteOperations_Summary = true;
    static RemoteOperations_Grouping = true;
    static RemoteOperations_GroupPaging = true;
    static RemoteOperations_Filtering = true;
    static Paging_DefaultPageSize = 10;
    static Pager_allowedPageSizes = [10, 25, 50];
    static FilterOperationsForString = ["contains", "notcontains", "startswith", "endswith", "=", "<>"];
    static FilterOperationsForNonString = ["=", "<>", "<", ">", "<=", ">=", "between"];
    static AllFilterOperationsForString = ["contains", "notcontains", "startswith", "endswith", "=", "<>", "<", ">", "<=", ">=", "between"];

    static StateStoring_Enabled = false;
    static StateStoring_Type = "localStorage";

    static CssVersion = 18;
    static GridViewConfigVersion = 1;
    static ChartConfigVersion = 1;
    static DashboardConfigVersion = 1;
    static DataViewConfigVersion = 1;
    static LeftMenuItemVersion = 1;

    static ControlCentreVersion = "1.5.0.48";
    static ControlCentreReleaseTime = "2019-07-23 04:23:59";
    static ControlCentreReleaseBranch = "master";
    static ControlCentreCommitID = "c3193a14fd37faf485cd1de249676d7222d9fb8d";

    static Global_GridViewTotalItemCount = 0;
    static Global_GridViewCurrentEditRowData = null;

    static StaticList_CommandButtonList = [{
        value: "modify-row",
        text: "Modify Row"
    }, {
        value: "add-row",
        text: "Add Row"
    }, {
        value: "delete-row",
        text: "Delete Row"
    }];

    static GridViewDefaultDefinition = {
        columnDefinition: [],
        adaptiveColumn: false,
        allowEditing: false,
        allowDragging: false,
        allowDropping: false,
        optimizePagerForLargeDataset: true,
        explicitFilterOption: LeopardStaticUIConfig.GridView_ExplicitFilterOption,
        filterBuilderValue: LeopardStaticUIConfig.GridView_DefaultFilterValue,
        gridViewDefinitionVersion: LeopardStaticUIConfig.GridViewConfigVersion,
        columnResizingMode: LeopardStaticUIConfig.GridView_ColumnResizingMode
    };

    static ChartDefaultDefinition = {
        argAxisAllowDecimals: true,
        argAxisCustomizeText: undefined,
        argAxisEnableAutoBreak: false,
        argAxisEndOnTick: false,
        argAxisInvertAxis: false,
        argAxisMaxAutoBreakCount: 4,
        argAxisMaxValueMargin: undefined,
        argAxisPosition: "bottom",
        argAxisShowZero: true,
        argAxisType: undefined,
        argAxisValueType: "string",
        chartAllowMouseWheel: true,
        chartAllowTouchGuestures: true,
        chartBarGroupPadding: 0.3,
        chartBarGroupWitdh: undefined,
        chartBgColorForExport: "#FFFFFF",
        chartContainerBgColor: "#FFFFFF",
        chartDragToZoom: false,
        chartEnableExport: false,
        chartMarginForExport: 10,
        chartMaxBubbleSize: 0.2,
        chartMinBubbleSize: 12,
        chartNegativeValuesAsZeroes: false,
        chartPaletteExtMode: "blend",
        chartPointSelectionMode: "single",
        chartRedrawOnResize: true,
        chartResolveLabelOverlapping: "none",
        chartScrollbarOffset: 5,
        chartScrollbarPosition: "bottom",
        chartSeriesSelectionMode: "single",
        chartShowScrollbar: false,
        labelAlignment: "left",
        labelArgumentFormat: undefined,
        labelBgColor: undefined,
        labelHorizontalOffset: 0,
        labelLabelFormat: undefined,
        labelPosition: "outside",
        labelRotationAngle: 0,
        labelShowForZero: true,
        labelVerticalOffset: 0,
        labelVisibility: true,
        legendBgColor: undefined,
        legendColumnCount: undefined,
        legendColumnItemSpacing: 20,
        legendHorizontalAlignment: "left",
        legendHoverMode: "includePoints",
        legendItemTextPosition: "left",
        legendItemsAlignment: "left",
        legendMargin: 10,
        legendMarkerSize: 20,
        legendOrientation: "horizontal",
        legendPaddingLeftRight: 10,
        legendPaddingTopBottom: 10,
        legendPosition: "outside",
        legendRowCount: undefined,
        legendRowItemSpacing: 8,
        legendVerticalAlignment: "bottom",
        legendVisibility: true,
        pointColor: undefined,
        pointCustomSymbol: "circle",
        pointHoverMode: "onlyPoint",
        pointSelectionMode: "onlyPoint",
        pointSizeOfPoint: 12,
        pointVisibility: true,
        seriesAxisBinding: undefined,
        seriesHoverMode: "onlyPoint",
        seriesIgnoreEmptyPoint: true,
        seriesSelectionMode: "onlyPoint",
        seriesStyleBarPadding: undefined,
        seriesStyleBarWidth: undefined,
        seriesStyleCornerRadius: 0,
        seriesStyleDashStyle: "solid",
        seriesStyleInnerColor: undefined,
        tooltipArgumentFormat: undefined,
        tooltipArrowLength: 10,
        tooltipColor: "#FFFFFF",
        tooltipEnabled: false,
        tooltipLocation: "center",
        tooltipOpacity: undefined,
        tooltipPaddingLeftRight: 10,
        tooltipPaddingTopBottom: 10,
        valueAxisAllowDecimals: true,
        valueAxisCustomizeText: undefined,
        valueAxisEnableAutoBreak: false,
        valueAxisEndOnTick: false,
        valueAxisInvertAxis: false,
        valueAxisMaxAutoBreakCount: 4,
        valueAxisMaxValueMargin: undefined,
        valueAxisPosition: "left",
        valueAxisShowZero: true,
        valueAxisType: undefined,
        valueAxisValueType: "numeric",
        valueAxisHighAverage: undefined,
        valueAxisHighAverageText: "High Average",
        valueAxisLowAverage: undefined,
        valueAxisLowAverageText: "Low Average",
        seriesSmallValueGroupingMode: "topN",
        seriesSmallValueGroupingTopCount: 8,
        chartPalette: "Soft Pastel",
        chartDefinitionVersion: LeopardStaticUIConfig.ChartConfigVersion
    };

    static APIEndpointAdapter = () => {
        if (window.location.hostname !== "localhost" &&
            LDH.IsValidIPAddress(window.location.hostname) === false) {
            return "https://api." + window.location.hostname;
        }
        return "https://api.ecosys.leopardcube.com.au";
    };

    // -----------------------------------------------------------------------------
    // -------------------- Ecosys Testing Configuration ---------------------------
    // -----------------------------------------------------------------------------
    static AmplifyAuthAndAPIConfig = {
        Auth: {
            identityPoolId: "ap-southeast-2:d4eae864-7ae6-46d3-bad5-d791b33b841d",
            region: "ap-southeast-2",
            userPoolId: "ap-southeast-2_ncLy65DtK",
            userPoolWebClientId: "2k0n18vpa887586r1gklr4015d"
        },
        API: {
            endpoints: [
                {
                    name: "aws-api-gateway",
                    endpoint: LeopardStaticUIConfig.APIEndpointAdapter(),
                    region: "ap-southeast-2"
                }
            ]
        }
    };

    // ----------------------------------------------------------------------------
    // --------------------- NewArch & Dev Configuration --------------------------
    // ----------------------------------------------------------------------------
    // static AmplifyAuthAndAPIConfig = {
    //     Auth: {
    //         identityPoolId: "ap-southeast-2:37b55a74-acb8-4603-9d6c-1f3b86455fd7",
    //         region: "ap-southeast-2",
    //         userPoolId: "ap-southeast-2_QVZRP2MLC",
    //         userPoolWebClientId: "7f3a89b91dnes5n2h483n8sqmt"
    //     },
    //     API: {
    //         endpoints: [
    //             {
    //                 name: "aws-api-gateway",
    //                 endpoint: "https://api.newarch.leopardcube.com.au",
    //                 region: "ap-southeast-2"
    //             }
    //         ]
    //     }
    // };

    // ----------------------------------------------------------------------------
    // ------------------------- Demo Configuration -------------------------------
    // ----------------------------------------------------------------------------
    // static AmplifyAuthAndAPIConfig = {
    //     Auth: {
    //         identityPoolId: "ap-southeast-2:897376f6-27a6-4ea6-867d-1c41b6f4e56e",
    //         region: "ap-southeast-2",
    //         userPoolId: "ap-southeast-2_H5TebpTKn",
    //         userPoolWebClientId: "2n3lhtcvd61esb191lv7tmvjk5"
    //     },
    //     API: {
    //         endpoints: [
    //             {
    //                 name: "aws-api-gateway",
    //                 endpoint: "https://api.demo.leopardcube.com.au",
    //                 region: "ap-southeast-2"
    //             }
    //         ]
    //     }
    // };
}

export default LeopardStaticUIConfig;