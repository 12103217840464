import React, { Component } from 'react';
import LeopardChartEngine from './LeopardChartEngine';

class LeopardChildChart extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardChartEngine dataSourceUrl={this.props.dataSourceUrl} palette={this.props.palette}
                    definition={this.props.definition} chartType={this.props.chartType}
                    useStateStore={this.props.useStateStore}
                    updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                    setChartInstance={this.props.setChartInstance} dataViewId={this.props.dataViewId} />
            </React.Fragment>
        );
    }
}

export default LeopardChildChart;