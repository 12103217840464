import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import DataSource from 'devextreme/data/data_source';
import {Validator, RequiredRule} from 'devextreme-react/validator';
import LeopardMenuDataViewList from '../components/LeopardMenuDataViewList';
import LeopardMenuTemplateList from '../components/LeopardMenuTemplateList';
import {SetLeftMenuItem, KeepGridViewDataById, KeepChartDataById}
    from '../foundation/LeopardActionCreators';

import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import {TextBox, Button, SelectBox, Switch, TagBox, FileUploader} from 'devextreme-react';
import LeopardDataHelper from '../helpers/LeopardDataHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardTooltipWithIcon from "../datashaping/LeopardTooltipWithIcon";

class LeopardMasterLeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = this.initializeDefaultState(false, false, []);

        this.dataViewMenuItems = null;
        this.systemViewMenuItems = null;
        this.templateMenuItems = null;
        this.setLeftMenu = false;
        this.leftMenuItemToBeSelected = null;
        this.uiObjectInstance = [];
        this.isDataSourceLoaded = false;

        this.dataViewTypeList = [{
            id: "datagrid",
            name: "Data Grid"
        }];

        this.chartTypeList = [{
            id: "spline-chart",
            name: "Spline Chart"
        }, {
            id: "scatter-chart",
            name: "Scatter Chart"
        }, {
            id: "doughnut-chart",
            name: "Doughnut Chart"
        }, {
            id: "bar-chart",
            name: "Bar Chart"
        }, {
            id: "line-chart",
            name: "Line Chart"
        }, {
            id: "area-chart",
            name: "Area Chart"
        }];
    }

    // =============== Component Behaviours ==================

    componentDidUpdate = () => {
        let that = this;
        let userProfile = this.props.state.userProfile;

        if (userProfile !== undefined && userProfile !== null &&
            this.state.isUserProfileLoaded === false) {
            this.setState({isUserProfileLoaded: true}, function () {
                that.loadLeftMenuItems(true, null);
            });
        }

        this.setLeftMenuItemToBeSelected();
    };

    componentWillUnmount = () => {
        this.dataViewMenuItems = null;
        this.systemViewMenuItems = null;
        this.templateMenuItems = null;
        this.setLeftMenu = false;
        this.leftMenuItemToBeSelected = null;
        this.resetOptionPanelStates(false, false);
    };

    // ================ State Behaviours ===================

    initializeDefaultState = (leftMenuItemsInitialized, isUserProfileLoaded,
                              availableDataViewListForCreate, availableDataViewListForEdit) => {
        return {
            showMenuOptionPanel: null,
            showChartTypeOption: false,
            showLoadingProgress: false,
            availableColumnNamesFromDataSourceWithoutBlank: [],
            validationInput: "",
            createDataViewType: "datagrid",
            createDataViewDataSourceUrl: 0,
            createDataViewDataSourceId: 0,
            createDataViewName: "",
            createDataViewChartType: "bar-chart",
            createDataViewChartValueField: null,
            createDataViewChartArgumentField: null,
            createDashboardName: "",
            availableDataSourceList: [],

            editDataViewName: "",
            editDataViewId: "",
            selectedDisabledFieldsForDataView: [],
            selectedCommandButtonsForDataView: [],
            selectedHiddenFieldsForDataView: [],
            disableEditingConfigurationFields: true,
            isShowSelectDataSourceID: false,
            dataViewJSONToExport: null,
            dataViewListToImport: [],

            editDashboardId: "",
            editDashboardInitialized: false,
            selectedDataViewsForDashboard: [],
            isUserProfileLoaded: isUserProfileLoaded,
            leftMenuItemsInitialized: leftMenuItemsInitialized,
            availableDataViewListForCreate: availableDataViewListForCreate,
            availableDataViewListForEdit: availableDataViewListForEdit,
            availableDataViewListForEditDataSource: null
        };
    };

    resetOptionPanelStates = (leftMenuItemsInitialized, isUserProfileLoaded,
                              availableDataViewListForCreate, availableDataViewListForEdit) => {
        this.isDataSourceLoaded = false;
        this.setState(this.initializeDefaultState(leftMenuItemsInitialized,
            isUserProfileLoaded, availableDataViewListForCreate,
            availableDataViewListForEdit));
    };

    loadLeftMenuItems = (selectDefaultMenuItem, callbackSuccess) => {
        let that = this;
        let userId = LeopardDataHelper.GetUserIdFromUserProfile(
            this.props.state.userProfile);
        LeopardAjaxHelper.GetLeftMenuItems(userId, function (items) {
            let filteredDataViewsList = [];
            let filteredSystemViewsList = [];
            let filteredTemplateList = [];
            let availableDataViewListForCreate = [{id: 0, name: "--- Empty ---", group: "Blank View"}];
            let availableDataViewListForEdit = [];

            for (var i = 0; i < items.length; i++) {
                if (LDH.IsValueEmpty(items[i].menuItemType) ||
                    items[i].menuItemType === "data-view") {
                    filteredDataViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Data Views"
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Data Views"
                    });
                } else if (items[i].menuItemType === "system-view") {
                    filteredSystemViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "System Views"
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "System Views"
                    });
                } else if (items[i].menuItemType === "dashboard") {
                    filteredTemplateList.push(items[i]);
                }
            }

            that.setState({
                availableDataViewListForCreate: availableDataViewListForCreate,
                availableDataViewListForEdit: availableDataViewListForEdit,
                leftMenuItemsInitialized: true
            });

            that.dataViewMenuItems = filteredDataViewsList;
            that.systemViewMenuItems = filteredSystemViewsList;
            that.templateMenuItems = filteredTemplateList;

            if (selectDefaultMenuItem === undefined || selectDefaultMenuItem) {
                if (LDH.IsObjectNull(localStorage.getItem("LoadFirstMenuItem")) ||
                    LDH.IsValueEmpty(localStorage.getItem("LoadFirstMenuItem")) ||
                    LDH.IsValueTrue(localStorage.getItem("LoadFirstMenuItem"))) {
                    that.props.SetLeftMenuItem(items[0]);
                } else {
                    localStorage.setItem("LoadFirstMenuItem", true);
                    that.props.SetLeftMenuItem(Object.create(null));
                }
            } else {
                let selectedItem = that.props.state.selectedLeftMenuItem;
                if (selectedItem !== undefined && selectedItem !== null) {
                    that.props.SetLeftMenuItem(selectedItem);
                } else {
                    if (LDH.IsObjectNull(localStorage.getItem("LoadFirstMenuItem")) ||
                        LDH.IsValueEmpty(localStorage.getItem("LoadFirstMenuItem")) ||
                        LDH.IsValueTrue(localStorage.getItem("LoadFirstMenuItem"))) {
                        that.props.SetLeftMenuItem(items[0]);
                    } else {
                        localStorage.setItem("LoadFirstMenuItem", true);
                        that.props.SetLeftMenuItem(Object.create(null));
                    }
                }
            }
            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                callbackSuccess();
            }
        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to get left menu items.", "error", 5000);
            }
            that.setState({leftMenuItemsInitialized: true});
        });
    };

    setLeftMenuItemToBeSelected = () => {
        let item = this.leftMenuItemToBeSelected;
        if (this.setLeftMenu && this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null && this.leftMenuItemToBeSelected !== null &&
            this.props.state.selectedLeftMenuItem.menuItemId === null) {
            this.setLeftMenu = false;
            this.props.SetLeftMenuItem(item);
            this.leftMenuItemToBeSelected = null;
        }
    };

    // ==================== UI Events ========================

    displayMenuOptionByType(data) {
        let that = this;
        if (data !== undefined && data !== null && data.option === "add_template") {
            return this.buildMenuOptionPanelCreateTemplate();
        }

        if (data !== undefined && data !== null && data.option === "edit_template") {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(
                that.props.state.userProfile);

            let dashboardId = that.state.editDashboardId;
            LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                let dataViewList = [];
                for (var i = 0; i < dashboard.dashboardLayout.length; i++) {
                    let definition = dashboard.dashboardLayout[i].definition;
                    let menuItem = null;

                    for (var j = 0; j < that.dataViewMenuItems.length; j++) {
                        if (that.dataViewMenuItems[j].dataViewId === definition.dataViewId) {
                            menuItem = that.dataViewMenuItems[j];
                            break;
                        }
                    }

                    for (var v = 0; v < that.systemViewMenuItems.length; v++) {
                        if (that.systemViewMenuItems[v].dataViewId === definition.dataViewId) {
                            menuItem = that.systemViewMenuItems[v];
                            break;
                        }
                    }

                    let dataViewItem = {
                        selectedValue: definition.dataViewName,
                        selectedId: definition.dataViewId,
                        index: parseInt(dashboard.dashboardLayout[i].i),
                        item: menuItem
                    };
                    dataViewList.push(dataViewItem);
                }
                if (that.state.editDashboardInitialized === false) {
                    that.setState({
                        selectedDataViewsForDashboard: dataViewList,
                        editDashboardInitialized: true
                    }, function () {
                        that.props.updateWindowDimensionsRequired();
                    });
                }
            }, function (error, sessionTimeout) {
                if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) &&
                    !LDH.IsValueEmpty(error.message) &&
                    error.message.indexOf("Cannot read property '") !== -1 &&
                    error.message.indexOf("' of undefined") !== -1) {
                    that.createDashboardDeleteButtonOnClick("Your Dashboard could not be found. <br/>" +
                        "Do you want to delete the reference(s) to it from the database?");
                } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to initialize Dashboard settings. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                    that.state.availableDataViewListForEdit);
            });
            return this.buildMenuOptionPanelEditTemplate();
        }

        if (data !== undefined && data !== null && data.option === "add_dataview_options") {
            return this.buildMenuOptionPanelDataViewOptions();
        }

        if (data !== undefined && data !== null && data.option === "export_dataview") {
            return this.buildMenuOptionPanelExportDataView();
        }

        if (data !== undefined && data !== null && data.option === "import_dataview") {
            return this.buildMenuOptionPanelImportDataView();
        }

        if (data !== undefined && data !== null && data.option === "clone_dataview") {
            return this.buildMenuOptionPanelCloneDataView();
        }

        if (data !== undefined && data !== null && data.option === "add_dataview") {
            if (that.isDataSourceLoaded === false) {
                that.isDataSourceLoaded = true;

                setTimeout(function () {
                    LeopardAjaxHelper.GetDataSourceList(function (result) {
                        that.setState({availableDataSourceList: result});
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve data sources from the database. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                    });
                }, 100);
            }
            return this.buildMenuOptionPanelCreateDataView();
        }

        if (data !== undefined && data !== null && data.option === "edit_dataview") {
            return this.buildMenuOptionPanelEditDataView();
        }
        return null;
    }

    showMenuOptionPanelStatusOnChange = (option) => {
        let that = this;

        this.setState({
            showMenuOptionPanel: option,
            selectedDataViewsForDashboard: [{
                selectedValue: "none", index: 0, item: null, selectedId: null
            }]
        }, function () {
            that.props.updateWindowDimensionsRequired();
        });
    };

    menuItemSettingsButtonOnClick = (option) => {
        let that = this;

        if (option.option === "edit_dataview") {
            this.setState({
                showMenuOptionPanel: option,
                editDataViewId: option.item.dataViewId,
                editDataViewName: option.item.menuItemName,
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
        } else if (option.option === "edit_template") {
            this.setState({
                showMenuOptionPanel: option,
                editDashboardId: option.item.dashboardId,
                editDataViewName: option.item.menuItemName
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
        }
    };

    dataViewOrTemplateMenuItemOnClick = (data) => {
        let that = this;
        that.leftMenuItemToBeSelected = data.item;
        that.setLeftMenu = true;

        let emptyItem = {dataSourceUrl: null, contentPageType: null, menuItemId: null, dataViewType: null};
        that.props.SetLeftMenuItem(emptyItem);

        if (data.item !== undefined && data.item !== null) {
            let id = data.item.menuItemId;
            that.props.KeepGridViewDataById(id);
            that.props.KeepChartDataById(id);
        } else {
            that.props.KeepGridViewDataById("");
            that.props.KeepChartDataById("");
        }
    };

    selectDataViewValueOnChange = (data) => {
        let dataViews = this.state.selectedDataViewsForDashboard;
        let clonedDataViewList = LDH.DeepClone(dataViews);
        let addNewEntry = true;

        for (var i = 0; i < clonedDataViewList.length; i++) {
            if (clonedDataViewList[i].index === data.index) {
                clonedDataViewList[i].selectedValue = "none";
                clonedDataViewList[i].selectedId = null;
                clonedDataViewList[i].item = null;

                for (var j = 0; j < this.dataViewMenuItems.length; j++) {
                    if (this.dataViewMenuItems[j].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.dataViewMenuItems[j];
                        clonedDataViewList[i].selectedId = this.dataViewMenuItems[j].dataViewId;
                        clonedDataViewList[i].selectedValue = this.dataViewMenuItems[j].menuItemName;
                        break;
                    }
                }

                for (var v = 0; v < this.systemViewMenuItems.length; v++) {
                    if (this.systemViewMenuItems[v].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.systemViewMenuItems[v];
                        clonedDataViewList[i].selectedId = this.systemViewMenuItems[v].dataViewId;
                        clonedDataViewList[i].selectedValue = this.systemViewMenuItems[v].menuItemName;
                        break;
                    }
                }
            }
            if (clonedDataViewList[i].selectedValue === "none") {
                addNewEntry = false;
            }
        }

        if (addNewEntry === false || this.state.showMenuOptionPanel.option === "edit_template") {
            this.setState({...this.state, selectedDataViewsForDashboard: clonedDataViewList});
        } else {
            clonedDataViewList.push({
                selectedValue: "none",
                index: clonedDataViewList.length,
                item: null,
                selectedId: null
            });
            this.setState({...this.state, selectedDataViewsForDashboard: clonedDataViewList});
        }
    };

    createDataViewSelectDataViewTypeOnValueChanged = (e) => {
        let show = false;
        if (e.value !== null && e.value !== "" && e.value === "chart") {
            show = true;
        }
        this.setState({
            createDataViewType: e.value,
            showChartTypeOption: show
        });

        // --------- DevExtreme validation bug workaround -------------
        let random = LDH.GenerateGuid();
        this.uiObjectInstance.validationInput.option("value", random);
        // ------------------------------------------------------------
    };

    createDataViewSelectChartTypeOnValueChanged = (e) => {
        this.setState({createDataViewChartType: e.value});
    };

    createDataViewValueFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartValueField: e.value});
    };

    createDataViewArgumentFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartArgumentField: e.value});
    };

    createDataViewDataSourceUrlOnValueChanged = (e) => {
        let that = this;
        this.setState({createDataViewDataSourceUrl: e.value, showLoadingProgress: true});

        let tableName = e.value;
        let url = window.ReportsUrlParameter + e.value;

        LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(url, tableName, [], function (data) {
            let dataSourceIds = [];
            for (var i = 0; i < data.fullColumns.length; i++) {
                dataSourceIds.push({id: data.fullColumns[i], name: data.fullColumns[i]});
            }
            that.setState({
                availableColumnNamesFromDataSourceWithoutBlank: dataSourceIds,
                showLoadingProgress: false,
                selectedDisabledFieldsForDataView: [],
                selectedHiddenFieldsForDataView: []
            });
        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your data source. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            that.setState({showLoadingProgress: false});
        });
    };

    createDataViewDataSourceIdOnValueChanged = (e) => {
        this.setState({createDataViewDataSourceId: e.value});
    };

    createDataViewNameOnValueChanged = (e) => {
        this.setState({createDataViewName: e.value});
    };

    createDashboardNameInputOnValueChanged = (e) => {
        this.setState({createDashboardName: e.value});
    };

    validationInputOnValueChanged = (e) => {
        this.setState({validationInput: e.value});
    };

    editDataViewNameOnValueChanged = (e) => {
        this.setState({editDataViewName: e.value});
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    dataEditingCheckboxOnValueChanged = (e) => {
        this.setState({disableEditingConfigurationFields: !e.value});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;
    };

    // ==================== Data Views =======================

    cloneDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (LDH.IsObjectNull(this.state.dataViewJSONToExport) ||
            LDH.IsValueEmpty(this.state.dataViewJSONToExport.dataViewName)) {
            LRH.ShowToast("Please select a data view first.", "error", 5000);
            return;
        }

        that.setState({showLoadingProgress: true}, function () {
            let dataToImport = that.state.dataViewJSONToExport;
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            dataToImport.dataViewId = LDH.GenerateGuid();

            LeopardAjaxHelper.CreateDataView(organizationId, that.state.createDataViewName,
                dataToImport.dataViewType, dataToImport.dataViewNote, dataToImport.dataSourceUrl,
                dataToImport.dataViewId, function () {
                    // Then create menu item for that data view.
                    LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId,
                        LDH.GenerateGuid(), that.state.createDataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                        dataToImport.dataViewType, {chartType: ""}, dataToImport.dataSourceUrl, dataToImport.dataViewId, function () {
                            LRH.ShowToast("Your Data View has been successfully cloned.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit);

                            that.loadLeftMenuItems(false, null);
                            that.setState({showLoadingProgress: false});
                        }, function () {
                            LRH.ShowToast("Failed to update the left-hand side menu item. " +
                                "Please check your network connection status and try again.", "error", 5000);
                            LeopardAjaxHelper.DeleteDataView(organizationId,
                                dataToImport.dataViewId, function () {
                                    return;
                                });
                            that.setState({showLoadingProgress: false});
                        });
                }, function () {
                    LRH.ShowToast("Failed to clone your Data View. " +
                        "Please check your network connection status and try again.", "error", 5000);
                    that.setState({showLoadingProgress: false});
                });
        });
    }

    exportDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();

        if (LDH.IsObjectNull(this.state.dataViewJSONToExport) ||
            LDH.IsValueEmpty(this.state.dataViewJSONToExport.dataViewName)) {
            LRH.ShowToast("Please select a data view first.", "error", 5000);
            return;
        }

        let dataToExport = JSON.stringify(this.state.dataViewJSONToExport);
        let dataView = this.state.dataViewJSONToExport.dataViewName + ".json";
        LRH.DownloadStringToFile(dataView, dataToExport);
        this.createDataViewCancelButtonOnClickWithoutConfirm();
    }

    importDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (LDH.IsObjectNull(that.state.dataViewListToImport) ||
            that.state.dataViewListToImport.length === 0) {
            LRH.ShowToast("Please select a file on your PC or device.", "error", 5000);
            return;
        }

        that.setState({showLoadingProgress: true}, function () {
            try {
                let userProfile = that.props.state.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                for (var i = 0; i < that.state.dataViewListToImport.length; i++) {
                    let dataToImport = JSON.parse(that.state.dataViewListToImport[i]);
                    if (LDH.IsValueEmpty(dataToImport.dataViewName) || LDH.IsValueEmpty(dataToImport.dataViewType) ||
                        LDH.IsValueEmpty(dataToImport.dataSourceUrl) || LDH.IsValueEmpty(dataToImport.dataViewId)) {
                        LRH.ShowToast("Unable to import Data View. The data you specified is invalid.", "error", 5000);
                        that.setState({showLoadingProgress: false});
                        return;
                    }
                }
                that.recurrsiveDataViewImportProcess(userId, organizationId, 0, that.state.dataViewListToImport);
            } catch {
                LRH.ShowToast("Unable to import Data View. Please check for the data format before proceeding.", "error", 5000);
                that.setState({showLoadingProgress: false});
            }
        });
    }

    recurrsiveDataViewImportProcess = (userId, organizationId, processedIndex, dataViewListToImport) => {
        let that = this;
        let dataToImport = JSON.parse(dataViewListToImport[processedIndex]);
        dataToImport.dataViewId = LDH.GenerateGuid();

        LeopardAjaxHelper.CreateDataView(organizationId, dataToImport.dataViewName,
            dataToImport.dataViewType, dataToImport.dataViewNote, dataToImport.dataSourceUrl,
            dataToImport.dataViewId, function () {
                // Then create menu item for that data view.
                LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId, LDH.GenerateGuid(),
                    dataToImport.dataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                    dataToImport.dataViewType, {chartType: ""}, dataToImport.dataSourceUrl,
                    dataToImport.dataViewId, function () {
                        if (that.state.dataViewListToImport.length === processedIndex + 1) {
                            LRH.ShowToast("Your Data Views have been successfully imported.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit);

                            that.loadLeftMenuItems(false, null);
                            that.setState({showLoadingProgress: false});
                        }
                        if (dataViewListToImport.length > processedIndex + 1) {
                            that.recurrsiveDataViewImportProcess(userId, organizationId, processedIndex + 1,
                                dataViewListToImport);
                        }
                    }, function () {
                        LRH.ShowToast("Failed to update the left-hand side menu item. " +
                            "Please check your network connection status and try again.", "error", 5000);
                        LeopardAjaxHelper.DeleteDataView(organizationId,
                            dataToImport.dataViewId, function () {
                                return;
                            });
                        that.setState({showLoadingProgress: false});
                    });
            }, function () {
                LRH.ShowToast("Failed to import your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
    }

    createDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        that.setState({showLoadingProgress: true}, function () {
            let dataViewId = LDH.GenerateGuid();
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let definition = null;
            let devExtremeChartType = null;

            if (that.state.createDataViewChartType === "bar-chart") {
                devExtremeChartType = "bar";
            }
            if (that.state.createDataViewChartType === "line-chart") {
                devExtremeChartType = "line";
            }
            if (that.state.createDataViewChartType === "spline-chart") {
                devExtremeChartType = "spline";
            }
            if (that.state.createDataViewChartType === "scatter-chart") {
                devExtremeChartType = "scatter";
            }
            if (that.state.createDataViewChartType === "area-chart") {
                devExtremeChartType = "area";
            }

            if (that.state.createDataViewType === "chart") {
                definition = {
                    chartType: that.state.createDataViewChartType,
                    chartDefinition: {
                        ...LeopardStaticUIConfig.ChartDefaultDefinition,
                        chartTitle: that.state.createDataViewName,
                        seriesArgumentField: that.state.createDataViewChartArgumentField,
                        seriesValueField: that.state.createDataViewChartValueField,
                        seriesTypeOfSeries: devExtremeChartType
                    }
                };
            }

            if (that.state.createDataViewType === "datagrid") {
                definition = {
                    gridViewDefinition: LeopardStaticUIConfig.GridViewDefaultDefinition,
                    styleDefinition: null
                };

                let userProfile = that.props.state.userProfile;
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                let defaultFilter = LeopardStaticUIConfig.GridView_DefaultFilterValue;

                if (LDH.IsValueEmpty(defaultFilter) === false && defaultFilter.length >= 3) {
                    defaultFilter[2] = defaultFilter[2].replace("[VALUE]", organizationId);
                }
                definition.gridViewDefinition.filterBuilderValue = defaultFilter;
                definition.gridViewDefinition.hiddenFieldsForEditing = that.state.selectedHiddenFieldsForDataView;
                definition.gridViewDefinition.disabledFieldsForEditing = that.state.selectedDisabledFieldsForDataView;
                definition.gridViewDefinition.commandButtons = that.state.selectedCommandButtonsForDataView;
                definition.gridViewDefinition.dataSourceId = that.state.createDataViewDataSourceId;
            }

            LeopardAjaxHelper.CreateDataView(organizationId, that.state.createDataViewName,
                that.state.createDataViewType, definition, that.state.createDataViewDataSourceUrl,
                dataViewId, function () {
                    LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId, LDH.GenerateGuid(),
                        that.state.createDataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                        that.state.createDataViewType, {chartType: that.state.createDataViewChartType},
                        that.state.createDataViewDataSourceUrl, dataViewId, function () {
                            LRH.ShowToast("Your Data View has been successfully created.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit);

                            that.loadLeftMenuItems(false, null);
                            that.setState({showLoadingProgress: false});
                        }, function () {
                            LRH.ShowToast("Failed to update the left-hand side menu item. " +
                                "Please check your network connection status and try again.", "error", 5000);

                            LeopardAjaxHelper.DeleteDataView(organizationId, dataViewId, function () {
                                return;
                            });
                            that.setState({showLoadingProgress: false});
                        });
                }, function () {
                    LRH.ShowToast("Failed to create your Data View. " +
                        "Please check your network connection status and try again.", "error", 5000);
                    that.setState({showLoadingProgress: false});
                });
        });
    };

    createDataViewDeleteButtonOnClick = () => {
        let that = this;

        LRH.ShowDialog("Are you sure you want to delete this Data View? <br/>This will impact any Dashboards that are linked to it.",
            "Confirm Delete", function () {
                that.setState({showLoadingProgress: true}, function () {
                    let dataViewId = that.state.editDataViewId;
                    let userProfile = that.props.state.userProfile;
                    let userId = LDH.GetUserIdFromUserProfile(userProfile);
                    let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                    LeopardAjaxHelper.DeleteLeftMenuItemForDataView(userId, organizationId,
                        dataViewId, function () {
                            LRH.ShowToast("Your Data View has been successfully deleted.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit);

                            that.loadLeftMenuItems(true);
                            that.dataViewOrTemplateMenuItemOnClick({item: null});
                            that.setState({showLoadingProgress: false});
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to delete your Data View. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        });
                });
            });
    };

    editDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        this.setState({showLoadingProgress: true}, function () {
            let dataViewId = that.state.editDataViewId;
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            // Update data view first.
            LeopardAjaxHelper.UpdateDataView(userId, organizationId, that.state.editDataViewName,
                dataViewId, function () {
                    LeopardAjaxHelper.UpdateLeftMenuItemForDataView(userId, organizationId,
                        that.state.editDataViewName, dataViewId, function (menuItem) {
                            LRH.ShowToast("Your Data View has been successfully updated.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit);

                            that.loadLeftMenuItems(false, function () {
                                that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                that.setState({showLoadingProgress: false});
                            });
                        }, function () {
                            LRH.ShowToast("Failed to update your Data View. " +
                                "Please check your network connection status and try again.", "error", 5000);
                            that.setState({showLoadingProgress: false});
                        });
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to update your Data View. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                });
        });
    };

    createDataViewCancelButtonOnClick = () => {
        let that = this;
        LRH.ShowDialog("Unsaved data will be lost. Do you want to continue?",
            "Confirm Cancel", function () {
                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                    that.state.availableDataViewListForEdit);
            });
    };

    createDataViewCancelButtonOnClickWithoutConfirm = () => {
        let that = this;
        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
            that.state.availableDataViewListForEdit);
    };

    // ==================== Dashboards =======================

    createDashboardCancelButtonOnClick = () => {
        let that = this;
        LRH.ShowDialog("Unsaved data will be lost. Do you want to continue?",
            "Confirm Cancel", function () {
                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                    that.state.availableDataViewListForEdit);
            });
    };

    createDashboardApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        that.setState({showLoadingProgress: true}, function () {
            let dashboardId = LDH.GenerateGuid();
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            let dataViewIds = [];
            let dashboardLayout = [];
            let xValue = -12;
            let yValue = 0;

            for (var i = 0; i < this.state.selectedDataViewsForDashboard.length; i++) {
                let dataView = this.state.selectedDataViewsForDashboard[i];
                if (dataView.selectedValue === "none" || dataView.item === null) continue;
                dataViewIds.push({dataViewId: dataView.selectedValue, index: dataView.index});

                if (xValue + 12 > 24) {
                    xValue = 0;
                    yValue += 1;
                } else {
                    xValue += 12;
                }

                dashboardLayout.push({
                    i: i.toString(), x: xValue, y: yValue, w: 12, h: 12, minW: 3, minH: 8, definition: {
                        dashboardType: dataView.item.dataViewType,
                        dataViewId: dataView.item.dataViewId,
                        dataViewName: dataView.item.menuItemName,
                        color: "leopard-bgcolor-gray",
                        dashboardItemId: LDH.GenerateGuid(),
                        dataViewNote: dataView.item.dataViewNote,
                        dataSourceUrl: dataView.item.dataSourceUrl,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    }
                });
            }
            if (dataViewIds.length === 0 || dashboardLayout.length === 0) {
                LRH.ShowToast("You must add at least one Data View for your dashboard.", "error", 5000);
                that.setState({showLoadingProgress: false});
                return;
            }

            LeopardAjaxHelper.CreateDashboard(organizationId, this.state.createDashboardName, null,
                dashboardId, dataViewIds, dashboardLayout, function () {
                    // Then create menu item for that data view.
                    LeopardAjaxHelper.CreateLeftMenuItemForDashboard(userId, organizationId, LDH.GenerateGuid(),
                        that.state.createDashboardName, dashboardId, function () {
                            LRH.ShowToast("Your Dashboard has been successfully created.", "success", 5000);
                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit);

                            that.loadLeftMenuItems(false, null);
                            that.setState({showLoadingProgress: false});
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to create your Dashboard. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            LeopardAjaxHelper.DeleteDashboard(organizationId, dashboardId, function () {
                                return;
                            });
                            that.setState({showLoadingProgress: false});
                        });
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to create your Dashboard. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                });
        });
    };

    editDashboardApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        that.setState({showLoadingProgress: true}, function () {
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let dataViewIds = [];
            let dashboardLayout = [];

            for (var i = 0; i < this.state.selectedDataViewsForDashboard.length; i++) {
                let dataView = this.state.selectedDataViewsForDashboard[i];
                if (dataView.selectedValue === "none" || dataView.item === null) continue;
                dataViewIds.push({dataViewId: dataView.selectedValue, index: dataView.index});

                dashboardLayout.push({
                    i: i.toString(), definition: {
                        dashboardType: dataView.item.dataViewType,
                        dataViewId: dataView.item.dataViewId,
                        dataViewName: dataView.item.menuItemName,
                        color: "leopard-bgcolor-gray",
                        dashboardItemId: LDH.GenerateGuid(),
                        dataViewNote: dataView.item.dataViewNote,
                        dataSourceUrl: dataView.item.dataSourceUrl,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    }
                });
            }
            if (dataViewIds.length === 0 || dashboardLayout.length === 0) {
                LRH.ShowToast("You must add at least one Data View for your dashboard.", "error", 5000);
                that.setState({showLoadingProgress: false});
                return;
            }

            LeopardAjaxHelper.UpdateDashboard(userId, organizationId, that.state.editDashboardId, dashboardLayout,
                that.state.editDataViewName, dashboardLayout, function () {
                    // Then update menu item for that dashboard.
                    LeopardAjaxHelper.UpdateLeftMenuItemForDashboard(userId, organizationId, that.state.editDataViewName,
                        that.state.editDashboardId, function (menuItem) {
                            LRH.ShowToast("Your Dashboard has been successfully updated.", "success", 5000);
                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit);

                            that.loadLeftMenuItems(false, function () {
                                that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                that.setState({showLoadingProgress: false});
                            });
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to update your Dashboard. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        });
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to update your Dashboard. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                });
        });
    };

    createDashboardDeleteButtonOnClick = (dialogMessage, dashboardIdToDelete) => {
        let that = this;

        if (LDH.IsValueEmpty(dialogMessage) === true) {
            dialogMessage = "Are you sure you want to delete this Dashboard?";
        }
        if (LDH.IsValueEmpty(dashboardIdToDelete) === true) {
            dashboardIdToDelete = that.state.editDashboardId;
        }

        LRH.ShowDialog(dialogMessage, "Confirm Delete", function () {
            that.setState({showLoadingProgress: true}, function () {
                let dashboardId = dashboardIdToDelete;
                let userProfile = that.props.state.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                LeopardAjaxHelper.DeleteLeftMenuItemForDashboard(userId, organizationId,
                    dashboardId, function () {
                        LRH.ShowToast("Your Dashboard has been successfully deleted.", "success", 5000);
                        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                            that.state.availableDataViewListForEdit);

                        that.loadLeftMenuItems(true, null);
                        that.dataViewOrTemplateMenuItemOnClick({item: null});
                        that.setState({showLoadingProgress: false});
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to delete your Dashbaord. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        that.setState({showLoadingProgress: false});
                    });
            });
        });
    };

    commandButtonsTagBoxOnValueChanged = (e) => {
        let isShowSelectDataSourceID = false;
        if (!LDH.IsObjectNull(e.value) && e.value.length > 0) {
            isShowSelectDataSourceID = true;
        }
        this.setState({
            selectedCommandButtonsForDataView: e.value,
            isShowSelectDataSourceID: isShowSelectDataSourceID
        });
    };

    disabledFieldsTagBoxOnValueChanged = (e) => {
        this.setState({selectedDisabledFieldsForDataView: e.value});
    };

    hiddenFieldsTagBoxOnValueChanged = (e) => {
        this.setState({selectedHiddenFieldsForDataView: e.value});
    };

    cloneDataViewSelectInputOnChanged = (e) => {
        let that = this;
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(
                this.props.state.userProfile);

            LeopardAjaxHelper.GetDataViewById(userId, e.value, function (result) {
                that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
            });
        });
    }

    exportDataViewSelectInputOnChanged = (e) => {
        let that = this;
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(
                this.props.state.userProfile);

            LeopardAjaxHelper.GetDataViewById(userId, e.value, function (result) {
                that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
            });
        });
    }

    // ================= Render Templates ====================

    buildLeopardMenuDataViews = (dataViewMenuItems) => {
        if (dataViewMenuItems !== null &&
            this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null) {
            return (
                <React.Fragment>
                    <LeopardMenuDataViewList menuItems={dataViewMenuItems}
                                             menuItemOnClick={(e) => this.dataViewOrTemplateMenuItemOnClick(e)}
                                             selectedMenuItem={this.props.state.selectedMenuItem}
                                             menuItemSettingsButtonOnClick={(e) => this.menuItemSettingsButtonOnClick(e)}
                                             selectedId={this.props.state.selectedLeftMenuItem.menuItemId}/>
                </React.Fragment>
            )
        }
        return null;
    };

    buildLeopardMenuTemplates = () => {
        if (this.templateMenuItems !== null &&
            this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null) {
            return (
                <React.Fragment>
                    <LeopardMenuTemplateList menuItems={this.templateMenuItems}
                                             menuItemOnClick={(e) => this.dataViewOrTemplateMenuItemOnClick(e)}
                                             selectedMenuItem={this.props.state.selectedMenuItem}
                                             menuItemSettingsButtonOnClick={(e) => this.menuItemSettingsButtonOnClick(e)}
                                             selectedId={this.props.state.selectedLeftMenuItem.menuItemId}/>
                </React.Fragment>
            )
        }
        return null;
    };

    buildMenuOptionPanelEditDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Edit Data View
                </div>
                <form onSubmit={this.editDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Rename data view</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                            title={"Rename data view"}
                                                            text={"Give a name to your data view. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox defaultValue={this.state.editDataViewName} maxLength={50}
                                         disabled={this.state.showLoadingProgress} id={"dataViewNameInput"}
                                         ref={(e) => this.setInputFieldInstance({e: e, input: "dataViewNameInput"})}
                                         onValueChanged={(e) => this.editDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{marginRight: "15px"}}>
                <Button className="leopard-button" text={'Delete'} id={"createDataViewDeleteButton"}
                        disabled={this.state.showLoadingProgress} onClick={
                    (e) => this.createDataViewDeleteButtonOnClick(e)}/>
              </span>
                            <span style={{marginRight: "15px"}}>
                <Button className="leopard-button" text={'Cancel'} id={"createDataViewCancelButton"}
                        disabled={this.state.showLoadingProgress} onClick={
                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
              </span>
                            <span style={{marginRight: "20px"}}>
                <Button className="leopard-button" text={'Apply'} id={"createDataViewApplyButton"}
                        disabled={this.state.showLoadingProgress} useSubmitBehavior={true}/>
              </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDataViewOptions = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Select an option
                </div>
                <div className="leopard-option-panel-content" style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "add_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "add_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                Create a new data view
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "clone_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "clone_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                Clone an existing data view
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "import_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "import_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                Import data view from files
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "export_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "export_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                Export data view to a file
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showMenuOptionPanel.option !== "add_dataview_options" ? "" :
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                        <span style={{marginRight: "20px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClickWithoutConfirm(e)}/>
                              </span>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    };

    onSelectedFilesChanged = (e) => {
        $(".dx-fileuploader-button.dx-fileuploader-upload-button").remove();
        let dataToImport = [];
        for (let i = 0; i < e.value.length; i++) {
            var reader = new FileReader()
            reader.onload = function (event) {
                dataToImport.push(event.target.result);
            }
            reader.readAsText(e.value[i]);
        }
        this.setState({dataViewListToImport: dataToImport});
    }

    buildMenuOptionPanelImportDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Import data view from files
                </div>
                <form onSubmit={this.importDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select files to import</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"selectFilesToImport_help"}
                                                            title={"Select files to import"}
                                                            text={"You can import a data view by selecting a on your PC, you can import multiple data views at once. Please note that the file must be in JSON format and is compatible with Control Centre."}/>
                                    </span>
                            </div>
                            <div>
                                <FileUploader multiple={true} uploadMode={"useButtons"}
                                              readyToUploadMessage={"Ready to import"}
                                              accept={'.json'}
                                              onValueChanged={this.onSelectedFilesChanged}/>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{marginRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{marginRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress}
                                    useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCloneDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Clone an existing data view
                </div>
                <form onSubmit={this.cloneDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data view to clone</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"selectFilesToClone_help"}
                                                            title={"Select data view to clone"}
                                                            text={"You can clone a data view by selecting an existing data view in Control Centre."}/>
                                    </span>
                            </div>
                            <div>
                                <SelectBox dataSource={new DataSource({
                                    store: this.state.availableDataViewListForEdit, key: 'id', group: 'group'
                                })} displayExpr={'name'} grouped={true}
                                           valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                           id={"cloneDataViewSelectInput"}
                                           onValueChanged={(e) => this.cloneDataViewSelectInputOnChanged(e)}
                                           ref={(e) => this.setInputFieldInstance({
                                               e: e,
                                               input: "cloneDataViewSelectInput"
                                           })}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "cloneDataViewSelectInput", rules: [
                                                    {rule: "required"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Name of data view</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                            title={"Name of data view"}
                                                            text={"Give a name to your data view. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                         id={"dataViewNameInput"}
                                         ref={(e) => this.setInputFieldInstance({
                                             e: e,
                                             input: "dataViewNameInput"
                                         })}
                                         onValueChanged={(e) => this.createDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{marginRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"createDataViewCancelButton"}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{marginRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress}
                                    useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelExportDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Export data view to a file
                </div>
                <form onSubmit={this.exportDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data view to export</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"selectFilesToExport_help"}
                                                            title={"Select data view to export"}
                                                            text={"You can export any data view to a file in JSON format, so you can import it at any time in the future."}/>
                                    </span>
                            </div>
                            <div>
                                <SelectBox dataSource={new DataSource({
                                    store: this.state.availableDataViewListForEdit, key: 'id', group: 'group'
                                })} displayExpr={'name'} valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                           id={"exportDataViewSelectInput"} grouped={true}
                                           onValueChanged={(e) => this.exportDataViewSelectInputOnChanged(e)}
                                           ref={(e) => this.setInputFieldInstance({
                                               e: e,
                                               input: "exportDataViewSelectInput"
                                           })}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "exportDataViewSelectInput", rules: [
                                                    {rule: "required"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </SelectBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{marginRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"createDataViewCancelButton"}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{marginRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress}
                                    useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCreateDataView = () => {
        return (
            <React.Fragment>
                <div>
                    <div className="leopard-option-panel-title">
                        Create a new data view
                    </div>
                    <form onSubmit={this.createDataViewApplyButtonOnClick}>
                        <div className="leopard-option-panel-content">
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">1.</i>
                                    <span>Select type of data view</span>
                                    <span style={{marginLeft: "5px"}}>
                                        <LeopardTooltipWithIcon elementId={"selectDataViewTypeInput_help"}
                                                                title={"Select type of data view"}
                                                                text={"There are many types of data view that the Control Centre supports. Administrators can create a data view with data grids, charts and maps."}/>
                                    </span>
                                </div>
                                <div>
                                    <SelectBox dataSource={this.dataViewTypeList} displayExpr={'name'}
                                               valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                               id={"selectDataViewTypeInput"}
                                               ref={(e) => this.setInputFieldInstance({
                                                   e: e,
                                                   input: "selectDataViewTypeInput"
                                               })}
                                               onValueChanged={
                                                   (e) => this.createDataViewSelectDataViewTypeOnValueChanged(e)
                                               }>
                                        <Validator>
                                            <RequiredRule type="custom" validationCallback={
                                                (e) => this.customValidationRuleCallback({
                                                    e: e, input: "selectDataViewTypeInput", rules: [
                                                        {rule: "required"}
                                                    ]
                                                })
                                            }/>
                                        </Validator>
                                    </SelectBox>
                                </div>
                            </div>
                            {
                                this.state.showChartTypeOption === false ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Select type of chart</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.chartTypeList} displayExpr={'name'}
                                                       disabled={this.state.showLoadingProgress}
                                                       visible={this.state.showChartTypeOption}
                                                       id={"selectChartTypeInput"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e: e,
                                                           input: "selectChartTypeInput"
                                                       })}
                                                       valueExpr={'id'}
                                                       defaultValue={this.state.createDataViewChartType}
                                                       onValueChanged={
                                                           (e) => this.createDataViewSelectChartTypeOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e: e, input: "selectChartTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {this.state.showChartTypeOption ? "3." : "2."}</i>
                                    <span>Select data source</span>
                                    <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"selectChartTypeInput_help"}
                                                            title={"Select data source"}
                                                            text={"Select a data source for the data view to access, so the user will be able to view or edit data on that data source."}/>
                                    </span>
                                </div>
                                <div>
                                    <SelectBox dataSource={this.state.availableDataSourceList} displayExpr={'name'}
                                               id={"selectDataSourceInput"}
                                               ref={(e) => this.setInputFieldInstance({
                                                   e: e,
                                                   input: "selectDataSourceInput"
                                               })}
                                               valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                               onValueChanged={
                                                   (e) => this.createDataViewDataSourceUrlOnValueChanged(e)
                                               }>
                                        <Validator>
                                            <RequiredRule type="custom" validationCallback={
                                                (e) => this.customValidationRuleCallback({
                                                    e: e, input: "selectDataSourceInput", rules: [
                                                        {rule: "required"}
                                                    ]
                                                })
                                            }/>
                                        </Validator>
                                    </SelectBox>
                                </div>
                            </div>

                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {this.state.showChartTypeOption ? "4." : "3."}</i>
                                    <span>Add command buttons to grid view</span>
                                    <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"selectedCommandButtonsForDataView_help"}
                                                            title={"Add command buttons"}
                                                            text={"Specify whether allow user to modify, add and delete data row on the grid view."}/>
                                    </span>
                                </div>
                                <div>
                                    <TagBox dataSource={LeopardStaticUIConfig.StaticList_CommandButtonList}
                                            showSelectionControls={true} displayExpr={'text'} valueExpr={'value'}
                                            id={"commandButtonTagbox"} multiline={false}
                                            value={this.state.selectedCommandButtonsForDataView}
                                            disabled={this.state.showLoadingProgress}
                                            onValueChanged={(e) => this.commandButtonsTagBoxOnValueChanged(e)}
                                            applyValueMode={'useButtons'}/>
                                </div>
                                {
                                    this.state.isShowSelectDataSourceID === false ? "" :
                                        <div>
                                            <div className={"leopard-leftmenu-settings-section"}>
                                                <span>Specify a data source ID:</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"dataSourceIDForCreateOrEditDataView_help"}
                                                                            title={"Specify a data source ID"}
                                                                            text={"Specify what field is the primary data source ID for modify, add and delete data row on the grid view."}/>
                                                    </span>
                                            </div>
                                            <div>
                                                {this.renderDataSourceIDForCreateOrEditDataView()}
                                            </div>
                                        </div>
                                }
                            </div>

                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex" style={{float: "left"}}>
                                        {this.state.showChartTypeOption ? "5." : "4."}</i>
                                    <div style={{position: "relative", top: "9px", height: "30px"}}>
                                        <span>Customise data edit form</span>
                                        <span style={{float: "right"}}>
                                        <Switch value={!this.state.disableEditingConfigurationFields} width={"80px"}
                                                switchedOffText={"DEFAULT"} switchedOnText={"CUSTOM"}
                                                id={"disableEditingConfigurationFieldsSwitch"}
                                                disabled={this.state.showLoadingProgress}
                                                onValueChanged={(e) => this.dataEditingCheckboxOnValueChanged(e)}
                                        />
                                    </span>
                                    </div>
                                </div>
                                {this.state.disableEditingConfigurationFields ? "" :
                                    <div>
                                        <div className={"leopard-leftmenu-settings-section"}>
                                            <span>Disable the following fields:</span>
                                            <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"disabledFieldsTagbox_help"}
                                                            title={"Disable fields"}
                                                            text={"Specifies which columns on the grid view should be disabled when a user editing a data row."}/>
                                    </span>
                                        </div>
                                        <div>
                                            <TagBox
                                                dataSource={this.state.availableColumnNamesFromDataSourceWithoutBlank}
                                                showSelectionControls={true} displayExpr={'name'} valueExpr={'id'}
                                                value={this.state.selectedDisabledFieldsForDataView}
                                                id={"disabledFieldsTagbox"} multiline={false}
                                                noDataText={"Please select a data source first.<br/>There is no data available to display."}
                                                onValueChanged={(e) => this.disabledFieldsTagBoxOnValueChanged(e)}
                                                disabled={this.state.showLoadingProgress}
                                                applyValueMode={'useButtons'}/>
                                        </div>
                                        <div className={"leopard-leftmenu-settings-section"}>
                                            <span>Hide the following fields:</span>
                                            <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"hiddenFieldsTagbox_help"}
                                                            title={"Hide fields"}
                                                            text={"Specifies which columns on the grid view should be hidden when a user editing a data row."}/>
                                    </span>
                                        </div>
                                        <div>
                                            <TagBox
                                                dataSource={this.state.availableColumnNamesFromDataSourceWithoutBlank}
                                                showSelectionControls={true} displayExpr={'name'} valueExpr={'id'}
                                                value={this.state.selectedHiddenFieldsForDataView}
                                                id={"hiddenFieldsTagbox"} multiline={false}
                                                noDataText={"Please select a data source first.<br/>There is no data available to display."}
                                                onValueChanged={(e) => this.hiddenFieldsTagBoxOnValueChanged(e)}
                                                disabled={this.state.showLoadingProgress}
                                                applyValueMode={'useButtons'}/>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                this.state.showChartTypeOption === false ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "10px"}}>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Configure basic settings</span>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Select a column for x-axis value</span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <SelectBox
                                                    dataSource={this.state.availableColumnNamesFromDataSourceWithoutBlank}
                                                    displayExpr={'name'} id={"selectArgumentFieldInput"}
                                                    visible={this.state.showChartTypeOption}
                                                    defaultValue={this.state.createDataViewChartArgumentField}
                                                    ref={(e) => this.setInputFieldInstance({
                                                        e: e,
                                                        input: "selectArgumentFieldInput"
                                                    })}
                                                    valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                    onValueChanged={
                                                        (e) => this.createDataViewArgumentFieldOnValueChanged(e)
                                                    }>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e: e, input: "selectArgumentFieldInput", rules: [
                                                                    {rule: "required"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div style={{paddingLeft: "24px"}}>
                                            <span>Select a column for y-axis value</span>
                                        </div>
                                        <div style={{paddingLeft: "24px"}}>
                                            <SelectBox dataSource={this.state.availableDataSourceIds}
                                                       displayExpr={'name'} id={"selectValueFieldInput"}
                                                       visible={this.state.showChartTypeOption}
                                                       defaultValue={this.state.createDataViewChartValueField}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e: e,
                                                           input: "selectValueFieldInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.createDataViewValueFieldOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e: e, input: "selectValueFieldInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">
                                        {this.state.showChartTypeOption ? "7." : "5."}</i>
                                    <span>Name of data view</span>
                                    <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                            title={"Name of data view"}
                                                            text={"Give a name to your data view. The length of this field is limited to 50 characters."}/>
                                    </span>
                                </div>
                                <div>
                                    <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                             id={"dataViewNameInput"}
                                             ref={(e) => this.setInputFieldInstance({
                                                 e: e,
                                                 input: "dataViewNameInput"
                                             })}
                                             onValueChanged={(e) => this.createDataViewNameOnValueChanged(e)}>
                                        <Validator>
                                            <RequiredRule type="custom" validationCallback={
                                                (e) => this.customValidationRuleCallback({
                                                    e: e, input: "dataViewNameInput", rules: [
                                                        {rule: "required"},
                                                        {rule: "safeinput"}
                                                    ]
                                                })
                                            }/>
                                        </Validator>
                                    </TextBox>
                                    <div style={{display: "none"}}>
                                        <TextBox
                                            ref={(e) => this.setInputFieldInstance({
                                                e: e,
                                                input: "validationInput"
                                            })}
                                            onValueChanged={(e) => this.validationInputOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e: e, input: "validationInput", rules: [
                                                            {rule: "required"},
                                                            {rule: "safeinput"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                {
                                    this.state.showLoadingProgress === false ? "" :
                                        <span className={"leopard-loading-icon"} style={{
                                            marginRight: "15px", marginLeft: "30px"
                                        }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                                }
                                <span style={{marginRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                                <span style={{marginRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress} useSubmitBehavior={true}/>
                          </span>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCreateTemplate = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Create Dashboard
                </div>
                <form onSubmit={this.createDashboardApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data views</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dataViewSelectBoxListForCreateOrEditDashboard_help"}
                                                            title={"Select data views"}
                                                            text={"A dashboard contains a collection of data views. When you create a dashboard, you'll need to specify what data views should be included in the dashboard."}/>
                                    </span>
                            </div>
                            {this.renderDataViewSelectBoxListForCreateOrEditDashboard()}
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Name of dashboard</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dashboardNameInput_help"}
                                                            title={"Name of dashboard"}
                                                            text={"Give a name to your dashboard. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "dashboardNameInput"})}
                                    disabled={this.state.showLoadingProgress} maxLength={50}
                                    id={"dashboardNameInput"}
                                    onValueChanged={(e) => this.createDashboardNameInputOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "dashboardNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                    <i className="fas fa-spinner fa-pulse" style={{
                        color: "#FF8000", fontSize: "25px"
                    }}></i>
                  </span>
                            }
                            <span style={{marginRight: "15px"}}>
                <Button className="leopard-button" text={'Cancel'}
                        disabled={this.state.showLoadingProgress}
                        id={"createDashboardCancelButton"}
                        onClick={(e) => this.createDashboardCancelButtonOnClick(e)}/>
              </span>
                            <span style={{marginRight: "20px"}}>
                <Button className="leopard-button" text={'Apply'} useSubmitBehavior={true}
                        id={"createDashboardApplyButton"}
                        disabled={this.state.showLoadingProgress}/>
              </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelEditTemplate = () => {
        if (this.state.editDashboardInitialized === false) {
            return this.renderProgressLoadingPanel(true);
        }
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Edit Dashboard
                </div>
                <form onSubmit={this.editDashboardApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data views</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dataViewSelectBoxListForCreateOrEditDashboard_help"}
                                                            title={"Select data views"}
                                                            text={"A dashboard contains a collection of data views. When you create a dashboard, you'll need to specify what data views should be included in the dashboard."}/>
                                    </span>
                            </div>
                            {this.renderDataViewSelectBoxListForCreateOrEditDashboard()}
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Rename dashboard</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                            title={"Rename dashboard"}
                                                            text={"Give a name to your dashboard. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox maxLength={50}
                                         ref={(e) => this.setInputFieldInstance({e: e, input: "dataViewNameInput"})}
                                         disabled={this.state.showLoadingProgress}
                                         id={"dataViewNameInput"}
                                         defaultValue={this.state.editDataViewName}
                                         onValueChanged={(e) => this.editDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                    <i className="fas fa-spinner fa-pulse" style={{
                        color: "#FF8000", fontSize: "25px"
                    }}></i>
                  </span>
                            }
                            <span style={{marginRight: "15px"}}>
                <Button className="leopard-button" text={'Delete'}
                        id={"createDashboardDeleteButton"}
                        disabled={this.state.showLoadingProgress} onClick={
                    () => this.createDashboardDeleteButtonOnClick()}/>
              </span>
                            <span style={{marginRight: "15px"}}>
                <Button className="leopard-button" text={'Cancel'}
                        id={"createDashboardCancelButton"}
                        disabled={this.state.showLoadingProgress}
                        onClick={(e) => this.createDashboardCancelButtonOnClick(e)}/>
              </span>
                            <span style={{marginRight: "20px"}}>
                <Button className="leopard-button" text={'Apply'} useSubmitBehavior={true}
                        id={"createDashboardApplyButton"}
                        disabled={this.state.showLoadingProgress}/>
              </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    renderDataSourceIDForCreateOrEditDataView = () => {
        return (
            <React.Fragment>
                <div style={{marginBottom: "8px"}}>
                    <SelectBox dataSource={this.state.availableColumnNamesFromDataSourceWithoutBlank}
                               displayExpr={'name'} valueExpr={'id'}
                               ref={(e) => this.setInputFieldInstance({e: e, input: "dataViewDataSourceIdInput"})}
                               disabled={this.state.showLoadingProgress} id={"dataViewDataSourceIdInput"}
                               defaultValue={this.state.createDataViewDataSourceId}
                               noDataText={"Please select a data source first.<br/>There is no data available to display."}
                               onValueChanged={(e) => this.createDataViewDataSourceIdOnValueChanged(e)}>
                        <Validator>
                            <RequiredRule type="custom" validationCallback={
                                (e) => this.customValidationRuleCallback({
                                    e: e, input: "dataViewDataSourceIdInput", rules: [
                                        {rule: "required"}
                                    ], defaultBlankValue: 0
                                })
                            }/>
                        </Validator>
                    </SelectBox>
                </div>
            </React.Fragment>
        );
    }

    renderDataViewSelectBoxListForCreateOrEditDashboard = () => {
        let that = this;
        if (that.state.availableDataViewListForCreate.length === 0) {
            return null;
        }
        let result = this.state.selectedDataViewsForDashboard.map(function (item, i) {
            if (that.state.showMenuOptionPanel.option === "add_template") {
                return (
                    <React.Fragment key={"LeopardCreateTemplateSelectedDataView_" + i}>
                        <div style={{marginBottom: "8px"}}>
                            <SelectBox dataSource={new DataSource({
                                store: that.state.availableDataViewListForCreate, key: 'id', group: 'group'
                            })} className={"leopard-grouped-selectbox"} displayExpr={'name'} valueExpr={'id'}
                                       disabled={that.state.showLoadingProgress}
                                       grouped={true} defaultValue={item.selectedId} id={"selectedDataView_" + i}
                                       onValueChanged={(e) => that.selectDataViewValueOnChange({
                                           e: e, index: i, item: item
                                       })}/>
                        </div>
                    </React.Fragment>
                );
            }
            if (that.state.showMenuOptionPanel.option === "edit_template") {
                return (
                    <React.Fragment key={"LeopardCreateTemplateSelectedDataView_" + i}>
                        <div style={{marginBottom: "8px"}}>
                            <SelectBox dataSource={new DataSource({
                                store: that.state.availableDataViewListForEdit, key: 'id', group: 'group'
                            })} className={"leopard-grouped-selectbox"} displayExpr={'name'} valueExpr={'id'}
                                       disabled={that.state.showLoadingProgress}
                                       grouped={true} defaultValue={item.selectedId} id={"selectedDataView_" + i}
                                       onValueChanged={(e) => that.selectDataViewValueOnChange({
                                           e: e, index: i, item: item
                                       })}/>
                        </div>
                    </React.Fragment>
                );
            }
            return null;
        });
        return result;
    };

    renderProgressLoadingPanel = (showPanel) => {
        if (showPanel === false) return null;

        return (
            <React.Fragment>
        <span className={"leopard-loading-icon"} style={{
            width: "100%", height: "100%", marginLeft: "0px", paddingTop: "40%"
        }}>
          <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
          <span className="leopard-largeloading-progress-text">Loading data...</span>
        </span>
            </React.Fragment>
        );
    };

    renderAddDashboardButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_template"
               })}/>
        );
    };

    renderAddDataViewButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "data-view"
               })}></i>
        );
    };

    renderAddSystemViewButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "system-view"
               })}></i>
        );
    };

    renderLeftMenuItemsPanel = (showPanel) => {
        if (showPanel === false) return null;

        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        return (
            <React.Fragment>
                <div className="leopard-leftmenu-category-container">
                    <div className="leopard-screen-cover" style={{display: "none"}}></div>
                    <span className="leopard-screen-menucover" style={{display: "none"}}></span>
                    <div className={"leopard-leftmenu-category"}>
                        <div className="leopard-leftmenu-category-text">Dashboards</div>
                        {this.renderAddDashboardButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuTemplates()}
                    <div className={"leopard-leftmenu-category"}>
                        <div className="leopard-leftmenu-category-text">Data Views</div>
                        {this.renderAddDataViewButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuDataViews(this.dataViewMenuItems)}
                    <div className={"leopard-leftmenu-category"}>
                        <div className="leopard-leftmenu-category-text">System Views</div>
                        {this.renderAddSystemViewButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuDataViews(this.systemViewMenuItems)}
                    <div className={"leopard-leftmenu-category"} style={{opacity: "0.3", display: "none"}}>
                        {"MANAGEMENT"}
                    </div>
                    <div className={"leopard-leftmenu-item"} style={{opacity: "0.3", display: "none"}}>
                        <div className={"leopard-leftmenu-item-text"}>Users & Roles</div>
                    </div>
                    <div className={"leopard-leftmenu-item"} style={{opacity: "0.3", display: "none"}}>
                        <div className={"leopard-leftmenu-item-text"}>Permissions</div>
                    </div>

                    <div className={"leopard-leftmenu-category"} style={{opacity: "0.3", display: "none"}}>
                        {"SYSTEM INFO"}
                    </div>
                    <div className={"leopard-leftmenu-item"} style={{opacity: "0.3", display: "none"}}>
                        <div className={"leopard-leftmenu-item-text"}>Service Status</div>
                    </div>
                    <div className={"leopard-leftmenu-item"} style={{opacity: "0.3", display: "none"}}>
                        <div className={"leopard-leftmenu-item-text"}>About</div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    render() {
        let showOption = this.state.showMenuOptionPanel !== null;
        let showPanel = this.state.leftMenuItemsInitialized;
        LRH.ShowOrHideApplicationBackgroundCover(showOption);

        if (this.state.showMenuOptionPanel === undefined || this.state.showMenuOptionPanel === null ||
            showOption === false) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            this.state.showMenuOptionPanel.option === "add_dataview_options" && showOption === true) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px"}}>
                            {this.displayMenuOptionByType({option: "add_dataview_options"})}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            showOption === true && (this.state.showMenuOptionPanel.option === "add_dataview" ||
                this.state.showMenuOptionPanel.option === "export_dataview" ||
                this.state.showMenuOptionPanel.option === "import_dataview" ||
                this.state.showMenuOptionPanel.option === "clone_dataview")) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px", zIndex: "11"}}>
                            {this.displayMenuOptionByType({option: "add_dataview_options"})}
                        </div>
                        <div className="leopard-leftmenu-option-panel"
                             style={{minHeight: "160px", left: "695px", zIndex: "12"}}>
                            {this.displayMenuOptionByType(this.state.showMenuOptionPanel)}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className={"leopard-left-panel-container"}>
                    {
                        showOption === false ? "" :
                            <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px"}}>
                                {this.displayMenuOptionByType(this.state.showMenuOptionPanel)}
                            </div>
                    }
                    {this.renderProgressLoadingPanel(!showPanel)}
                    {this.renderLeftMenuItemsPanel(showPanel)}
                    <br/>
                </div>
            </React.Fragment>
        );
    }
}

const
    RetrieveDataFromReducer = (state) => {
        return {state: state};
    };

const
    SendDataToReducer = (dispatch) => {
        return {
            SetLeftMenuItem: (data) => {
                dispatch(SetLeftMenuItem(data));
            },
            KeepGridViewDataById: (id) => {
                dispatch(KeepGridViewDataById(id));
            },
            KeepChartDataById: (id) => {
                dispatch(KeepChartDataById(id));
            }
        };
    };

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardMasterLeftMenu);