import React from 'react';
import moment from 'moment';
import LDH from './LeopardDataHelper';

class LeopardDataHelper extends React.Component {
    static GetColumnFromJSON(json) {
        if (!LeopardDataHelper.IsObjectNull(json) && json.length > 0) {
            var columns = [];
            for (var key in json[0]) {
                if (json[0].hasOwnProperty(key)) {
                    columns.push({columnName: key});
                }
            }
            return columns;
        }
        return [];
    }

    static IsObjectNull(obj) {
        return obj === undefined || obj === null;
    }

    static IsValueEmpty(value) {
        return value === undefined || value === null || value === "";
    }

    static IsValidIPAddress(ipAddress) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress)) {
            return true;
        }
        return false;
    }

    static ConvertJsonDataNullOrEmptyValues(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        var clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (var key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (clonedJsonData[key] === undefined) {
                    clonedJsonData[key] = "[UNDEFINED]";
                } else if (clonedJsonData[key] === null) {
                    clonedJsonData[key] = "[NULL]";
                } else if (clonedJsonData[key] === "") {
                    clonedJsonData[key] = "[EMPTY]";
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.ConvertJsonDataNullOrEmptyValues(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static UnconvertJsonDataNullOrEmptyValues(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        var clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (var key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (clonedJsonData[key] === "[UNDEFINED]") {
                    clonedJsonData[key] = undefined;
                } else if (clonedJsonData[key] === "[NULL]") {
                    clonedJsonData[key] = null;
                } else if (clonedJsonData[key] === "[EMPTY]") {
                    clonedJsonData[key] = "";
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.UnconvertJsonDataNullOrEmptyValues(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static IsValueTrue(value) {
        if (value === undefined || value === null || value === "") {
            return false;
        }
        return value.toString().toLowerCase() === "true";

    }

    static DeepClone(obj) {
        if (this.IsObjectNull(obj)) return null;
        return JSON.parse(JSON.stringify(obj));
    }

    static GenerateGuid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }

    static IsIsoDateFormat(str) {
        try {
            if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
                return false;
            }
            var d = new Date(str);
            return d.toISOString() === str;
        } catch {
            return false;
        }
    }

    static TraverseJSONObject(jsonObj) {
        if (jsonObj !== null && typeof jsonObj == "object") {
            Object.entries(jsonObj).forEach(([key, value]) => {
                let v = LDH.TraverseJSONObject(value);
                jsonObj[key] = v;
            });
            return jsonObj
        } else {
            try {
                var date = new Date(jsonObj);
                if (!LDH.IsValueEmpty(date) &&
                    LDH.IsIsoDateFormat(jsonObj)) {
                    jsonObj = date;
                }
            } catch {
                return jsonObj
            }
        }
        return jsonObj
    }

    static ParseDevExtremeFilterString(filterValue) {
        if (this.IsObjectNull(filterValue)) return null;
        let clonedValue = LDH.DeepClone(filterValue);
        try {
            return LDH.TraverseJSONObject(filterValue);
        } catch {
            return clonedValue;
        }
    }

    static GetFilterOperator(operator) {
        let resultOperator = operator;
        if (operator === "contains") resultOperator = "Op_like";
        if (operator === "notcontains") resultOperator = "Op_notLike";
        if (operator === "startswith") resultOperator = "Op_startsWith";
        if (operator === "endswith") resultOperator = "Op_endsWith";
        if (operator === "=") resultOperator = "Op_eq";
        if (operator === "<>") resultOperator = "Op_ne";
        if (operator === "<") resultOperator = "Op_lt";
        if (operator === ">") resultOperator = "Op_gt";
        if (operator === "<=") resultOperator = "Op_lte";
        if (operator === ">=") resultOperator = "Op_gte";
        return resultOperator;
    }

    static ConvertDevExtremeFilteringParameterFormat(filterValue) {
        let finalResult = [];
        let currentResult = [];
        let operator = "";

        for (var i = 0; i < filterValue.length; i++) {
            if (filterValue[i] === "or" || filterValue[i] === "and" || filterValue[i] === "!") {
                operator = filterValue[i];
                break;
            }
        }
        if (filterValue.length === 2 && operator === "" && Array.isArray(filterValue[0]) &&
            Array.isArray(filterValue[1])) {
            operator = "and";
            filterValue.splice(1, 0, "and");
        }
        LDH.FilterConcatenator(filterValue, finalResult, currentResult, 0);

        if (finalResult.length === 0 && currentResult.length === 1) {
            return currentResult[0].filter;
        }

        let count = 0;
        let resultToReturn = null;
        for (var j = 0; j < finalResult.length; j++) {
            if (finalResult[j].level > -1) {
                resultToReturn = finalResult[j].filter;
                count++;
            }
        }

        if (count === 1) return resultToReturn;
        resultToReturn = LDH.ConstructFilterWithOperator(finalResult, operator);
        return resultToReturn;
    }

    static FilterConcatenator(filterArray, finalResult, currentResult, level) {
        let hasChildArray = false;
        let operatorForCurrentLevel = "";

        for (var i = 0; i < filterArray.length; i++) {
            if (filterArray[i] === "or" || filterArray[i] === "and" ||
                filterArray[i] === "!") {
                operatorForCurrentLevel = filterArray[i];
                hasChildArray = true;
                break;
            }
        }
        if (hasChildArray === true) {
            currentResult = [];
            for (var j = 0; j < filterArray.length; j++) {
                if (filterArray[j] === "or" || filterArray[j] === "and" ||
                    filterArray[j] === "!") {
                    continue;
                }
                LDH.FilterConcatenator(filterArray[j], finalResult,
                    currentResult, level + 1);
            }
        } else {
            currentResult.push({
                filter: LDH.ConstructLowestLevelFilter(filterArray),
                level: level
            });
        }

        if (operatorForCurrentLevel === "and" || operatorForCurrentLevel === "or" ||
            operatorForCurrentLevel === "!") {
            if (currentResult.length > 0) {
                let filterWithOperator = LDH.ConstructFilterWithOperator(
                    currentResult, operatorForCurrentLevel);
                finalResult.push({filter: filterWithOperator, level: level});
            } else if (currentResult.length === 0 && finalResult.length > 0) {
                let listToConvert = [];
                let newFinalResult = [];
                for (var f = 0; f < finalResult.length; f++) {
                    if (finalResult[f]["level"] === level + 1) {
                        listToConvert.push(LDH.DeepClone(finalResult[f]));
                        finalResult[f]["level"] = -1;
                    } else {
                        newFinalResult.push(finalResult[f]);
                    }
                }
                if (listToConvert.length > 0) {
                    finalResult.push({
                        filter: LDH.ConstructFilterWithOperator(listToConvert,
                            operatorForCurrentLevel), level: level
                    });
                }
            }
        }
    }

    static ConstructFilterWithOperator(filterResultList, operator) {
        if (operator === "!") operator = "not";

        let result = "";
        for (var k = 0; k < filterResultList.length; k++) {
            if (filterResultList[k]["level"] === -1) {
                continue;
            }
            result += filterResultList[k]["filter"];
            if (k < filterResultList.length - 1) {
                result += ",";
            }
        }
        result = "{\"Op_" + operator + "\": [" + result + "]}";
        return result;
    }

    static ConstructLowestLevelFilter(filterArray) {
        if (filterArray.length === 1 && filterArray[0].length >= 3 &&
            LDH.IsValueEmpty(filterArray[0][1]) === false) {
            filterArray = filterArray[0];
        }
        let operator = LDH.GetFilterOperator(filterArray[1]);
        let like = "";
        if (operator === "Op_like" || operator === "Op_notLike") {
            like = "%";
        }

        let value = filterArray[2];
        try {
            let isValidDate = !LDH.IsValueEmpty(value) && moment(value).isValid();
            let isNumber = !LDH.IsValueEmpty(value) && Number.isInteger(parseInt(value));
            let isFloat = !LDH.IsValueEmpty(value) && Number.isInteger(parseFloat(value));
            let isDate = !LDH.IsValueEmpty(value) && Date.parse(value);
            let isFullDate = !LDH.IsValueEmpty(value) && value.toString().indexOf("(") > -1 &&
                value.toString().indexOf(")") > -1 && value.toString().indexOf("GMT") > -1;

            if (!LDH.IsValueEmpty(value) && isValidDate && !isNumber &&
                !isFloat && !isNaN(isDate) && isFullDate) {
                value = new Date(value).toISOString();
            }
        } catch {
            value = filterArray[2];
        }

        return "{\"" + filterArray[0] +
            "\": {\"" + operator +
            "\": \"" + like + value + like + "\"}}";
    }

    static GetOrganizationIdFromUserProfile(userProfile) {
        if (userProfile === undefined || userProfile === null ||
            userProfile.data === undefined || userProfile.data === null) {
            return null;
        }
        return userProfile.data.Parent;
    }

    static GetUserIdFromUserProfile(userProfile) {
        if (userProfile === undefined || userProfile === null ||
            userProfile.data === undefined || userProfile.data === null) {
            return null;
        }
        return userProfile.data.ID;
    }
}

export default LeopardDataHelper;