import React, {Component} from 'react';
import {connect} from 'react-redux';
import Box, {Item} from 'devextreme-react/box';
import $ from 'jquery';
import {Button, CheckBox, NumberBox, SelectBox, TextBox} from 'devextreme-react';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import TabPanel from 'devextreme-react/tab-panel';
import FilterBuilder from 'devextreme-react/filter-builder';
import {UpdateGridViewDefinition} from '../foundation/LeopardActionCreators';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardDesignerTagBox from "./LeopardDesignerTagBox";
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LeopardTooltipWithText from "./LeopardTooltipWithText";

class LeopardColumnDesigner extends Component {
    constructor(props) {
        super(props);

        this.customGridDefinitionValue = "";
        this.filterFields = [];
        this.filterBuilderPreviewValue = "";
        this.columnDefinitionToSave = [];
        this.hasColumnFilterChanged = false;
        this.filterBuilderValue = "";
        this.hiddenFieldsForEditing = [];
        this.disabledFieldsForEditing = [];
        this.commandButtons = [];
        this.enableAdaptiveColumnOption = false;
        this.optimizePagerForLargeDataset = true;
        this.allowDraggingOption = false;
        this.allowDroppingOption = false;
        this.gridViewId = null;
        this.columnFieldList = [];
        this.columnFieldListForDropdown = [];
        this.dataSourceId = "";
        this.uiObjectInstances = [];
        this.explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
        this.columnResizingMode = LeopardStaticUIConfig.GridView_ColumnResizingMode;

        this.columnTypeDataSource = [
            {text: "String", value: "string"},
            {text: "Number", value: "number"},
            {text: "Date", value: "date"},
            {text: "Date Time", value: "datetime"},
            {text: "Boolean", value: "boolean"}
        ];

        this.columnAlignmentDataSource = [
            {text: "Left", value: "left"},
            {text: "Center", value: "center"},
            {text: "Right", value: "right"}
        ];

        this.sortOrderDataSource = [
            {text: "None", value: "none"},
            {text: "Ascending", value: "asc"},
            {text: "Descending", value: "desc"}
        ];

        this.filterOperationSource = [{
            value: "contains",
            text: "Contains"
        }, {
            value: "notcontains",
            text: "Not Contains"
        }, {
            value: "startswith",
            text: "Starts With"
        }, {
            value: "endswith",
            text: "Ends With"
        }, {
            value: "=",
            text: "="
        }, {
            value: "<>",
            text: "<>"
        }, {
            value: "<",
            text: "<"
        }, {
            value: ">",
            text: ">"
        }, {
            value: "<=",
            text: "<="
        }, {
            value: ">=",
            text: ">="
        }, {
            value: "between",
            text: "Between"
        }];

        this.autoCompleteOperationSource = [{
            value: "contains",
            text: "Contains"
        }, {
            value: "startswith",
            text: "Starts With"
        }];

        this.tabPanelDataSource = [{
            tabId: 0,
            tabTitle: 'Columns'
        }, {
            tabId: 1,
            tabTitle: "Grid View"
        }, {
            tabId: 2,
            tabTitle: "Advanced Filter"
        }];

        this.selectionYesNo = [{
            value: false,
            text: "No"
        }, {
            value: true,
            text: "Yes"
        }];

        this.columnResizingModeSource = [{
            value: "nextColumn",
            text: "Next Column"
        }, {
            value: "widget",
            text: "Grid View"
        }];

        this.filterOptionSource = [{
            value: "auto",
            text: "No"
        }, {
            value: "onClick",
            text: "Yes"
        }];
    }

    componentDidMount() {
        let definition = this.props.gridViewDefinition;
        this.customGridDefinitionValue = JSON.stringify(definition, null, 2);
        this.columnDefinitionToSave = definition.columnDefinition;
        this.filterBuilderValue = definition.filterBuilderValue;
        this.hiddenFieldsForEditing = definition.hiddenFieldsForEditing;
        this.disabledFieldsForEditing = definition.disabledFieldsForEditing;
        this.commandButtons = definition.commandButtons;
        this.dataSourceId = definition.dataSourceId;
        this.enableAdaptiveColumnOption = definition.adaptiveColumn;

        this.optimizePagerForLargeDataset = definition.optimizePagerForLargeDataset;
        if (LDH.IsValueEmpty(this.optimizePagerForLargeDataset)) {
            this.optimizePagerForLargeDataset = true;
        }

        this.allowDraggingOption = definition.allowDragging;
        this.columnResizingMode = definition.columnResizingMode;

        this.explicitFilterOption = definition.explicitFilterOption;
        if (LDH.IsValueEmpty(this.explicitFilterOption)) {
            this.explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
        }

        this.gridViewId = this.props.gridViewId;
        this.columnFieldList = this.props.columnFieldList.fullColumns;

        this.columnFieldListForDropdown = [];
        for (var i = 0; i < this.columnFieldList.length; i++) {
            this.columnFieldListForDropdown.push({
                value: this.columnFieldList[i],
                text: this.columnFieldList[i]
            });
        }
    }

    addUIObjectInstance = (data) => {
        if (data.ref === null || data.ref.instance === null) return;
        this.uiObjectInstances[data.uiObjectName] = data.ref.instance;
    };

    previewButtonOnClick = (callback, filter) => {
        $(".leopard-columndesigner-loading-progress").css("visibility", "visible");
        let that = this;

        setTimeout(() => {
            that.filterBuilderValue = filter;

            let gridDefinition = {
                columnDefinition: that.columnDefinitionToSave,
                adaptiveColumn: that.enableAdaptiveColumnOption,
                optimizePagerForLargeDataset: that.optimizePagerForLargeDataset,
                columnResizingMode: that.columnResizingMode,
                explicitFilterOption: that.explicitFilterOption,
                filterBuilderValue: that.filterBuilderValue,
                hiddenFieldsForEditing: that.hiddenFieldsForEditing,
                disabledFieldsForEditing: that.disabledFieldsForEditing,
                commandButtons: that.commandButtons,
                dataSourceId: that.dataSourceId,
                allowDragging: that.allowDraggingOption,
                allowDropping: that.allowDroppingOption,
            };
            that.props.UpdateGridViewDefinition(gridDefinition, that.gridViewId);
            that.props.updateGridViewDefinitionOnSingleDataView(gridDefinition);

            that.props.applyGridViewConfigurationOnDataView({
                columnResizingMode: that.columnResizingMode,
                explicitFilterOption: that.explicitFilterOption,
                filterBuilderValue: that.filterBuilderValue,
                allowDragging: that.allowDraggingOption,
                allowDropping: that.allowDroppingOption,
                hiddenFieldsForEditing: that.hiddenFieldsForEditing,
                disabledFieldsForEditing: that.disabledFieldsForEditing,
                commandButtons: that.commandButtons,
                dataSourceId: that.dataSourceId
            });

            if (!LDH.IsObjectNull(that.commandButtons) && that.commandButtons.length > 0) {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").show();
            } else {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").hide();
            }

            if (callback !== undefined && callback !== null) {
                callback();
            }

            that.cancelButtonOnClick();
            $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
        }, 100);
    };

    saveButtonOnClick = () => {
        let that = this;
        this.filterBuilderValue = this.filterBuilderPreviewValue;

        let columnDefinition = this.columnDefinitionToSave;
        let userProfile = this.props.state.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
        $(".leopard-columndesigner-loading-progress").css("visibility", "visible");

        for (var i = 0; i < this.columnDefinitionToSave.length; i++) {
            this.columnDefinitionToSave[i].hasSorted = false;
        }

        setTimeout(function () {
            LeopardAjaxHelper.UpdateGridViewDefintion(userId, organizationId, that.gridViewId, {
                columnDefinition: columnDefinition,
                adaptiveColumn: that.enableAdaptiveColumnOption,
                optimizePagerForLargeDataset: that.optimizePagerForLargeDataset,
                hiddenFieldsForEditing: that.hiddenFieldsForEditing,
                disabledFieldsForEditing: that.disabledFieldsForEditing,
                commandButtons: that.commandButtons,
                dataSourceId: that.dataSourceId,
                columnResizingMode: that.columnResizingMode,
                explicitFilterOption: that.explicitFilterOption,
                filterBuilderValue: that.filterBuilderValue,
                allowDragging: that.allowDraggingOption,
                allowDropping: that.allowDroppingOption,
            }, function () {
                LRH.ShowToast("Your Grid View configuration has been successfully saved.", "success", 5000);
                $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
                $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                }
                $(".leopard-columndesigner-loading-progress").css("visibility", "hidden")
            });
        }, 100);
    };

    cancelButtonOnClick = () => {
        let that = this;
        $(".leopard-columndesigner-loading-progress").css("visibility", "visible");
        setTimeout(function () {
            that.props.gridViewBuilderCancelButtonOnClick();
        }, 100);
    };

    enableAdaptiveColumnsOnChange = (e) => {
        this.enableAdaptiveColumnOption = e.value;

        this.uiObjectInstances["columnResizingMode"]
            .option("disabled", e.value);

        if (this.columnResizingMode === "widget") {
            this.uiObjectInstances["columnResizingMode"]
                .option("value", "nextColumn");
        }
    };

    optimizePagerForLargeDatasetOnChange = (e) => {
        this.optimizePagerForLargeDataset = e.value;
    };

    columnResizingModeOnChange = (e) => {
        this.columnResizingMode = e.value;
    };

    explicitFilterOptionOnChange = (e) => {
        this.explicitFilterOption = e.value;
    };

    dataSourceIdOnChange = (e) => {
        this.dataSourceId = e.value;
    };

    filterBuilderOnValueChanged = (e) => {
        this.filterBuilderPreviewValue = e.value;
    };

    leopardDesignerTagBoxValueOnChange = (e) => {
        if (e.fieldType === "hidden-fields") {
            this.hiddenFieldsForEditing = e.fieldValue;
        } else if (e.fieldType === "disabled-fields") {
            this.disabledFieldsForEditing = e.fieldValue;
        } else if (e.fieldType === "command-buttons") {
            this.commandButtons = e.fieldValue;

            if (!LDH.IsObjectNull(e.fieldValue) && e.fieldValue.length > 0) {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").show();
            } else {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").hide();
            }
        }
    };

    updateColumnDefinition = (data) => {
        let updateType = data.updateType;
        let clonedColumnDef = LDH.DeepClone(this.columnDefinitionToSave);

        let column = clonedColumnDef.filter(c => {
            return c.columnName === data.column.columnName;
        });
        if (column === undefined || column === null || column.length === 0) {
            if (data.e.fieldType === "filterOperations") {
                data.column[updateType] = data.e.fieldValue;
            } else {
                data.column[updateType] = data.e.value;
            }
            clonedColumnDef.push(data.column);
        } else {
            if (data.e.fieldType === "filterOperations") {
                column[0][updateType] = data.e.fieldValue;
            } else {
                column[0][updateType] = data.e.value;
            }
        }

        this.columnDefinitionToSave = clonedColumnDef;
        if (updateType === "allowFiltering" && data.e.value === false) {
            let userProfile = this.props.state.userProfile;
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let defaultFilter = LeopardStaticUIConfig.GridView_DefaultFilterValue;

            if (LDH.IsValueEmpty(defaultFilter) === false && defaultFilter.length >= 3) {
                defaultFilter[2] = defaultFilter[2].replace("[VALUE]", organizationId);
            }
            this.hasColumnFilterChanged = true;
            this.filterBuilderValue = defaultFilter;
            this.filterBuilderPreviewValue = "";
            $(".leopard-settings-warning-text").show();
        }

        let dataColumn = null;
        if ((column === undefined || column === null || column.length === 0) &&
            !LDH.IsObjectNull(data.column)) {
            dataColumn = data.column;
        } else {
            dataColumn = column[0];
        }
        if (LDH.IsObjectNull(dataColumn)) return;

        if (updateType === "columnType") {
            let instanceAC = this.uiObjectInstances["allowAutoComplete" + dataColumn.columnName];
            if (LDH.IsObjectNull(instanceAC)) return;

            if (!LDH.IsObjectNull(data.e) && (data.e.value === "string" || data.e.value === "number")) {
                instanceAC.option("value", true);
                instanceAC.option("disabled", false);
            } else {
                instanceAC.option("value", false);
                instanceAC.option("disabled", true);
            }
        }

        if (updateType === "allowAutoComplete") {
            let instanceFO = this.uiObjectInstances["filterOperations" + dataColumn.columnName];
            let instanceDO = this.uiObjectInstances["defaultOperation" + dataColumn.columnName];
            let instanceACO = this.uiObjectInstances["autoCompleteOperation" + dataColumn.columnName];
            if (LDH.IsObjectNull(instanceFO) || LDH.IsObjectNull(instanceDO) ||
                LDH.IsObjectNull(instanceACO)) {
                return;
            }
            if (data.e.value === true) {
                instanceFO.option("value", ["="]);
                instanceDO.option("value", "=");
            }
            instanceFO.option("disabled", data.e.value);
            instanceDO.option("disabled", data.e.value);
            instanceACO.option("disabled", !data.e.value);
        }
    };

    onOptionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            if (!LDH.IsObjectNull(this.commandButtons) && this.commandButtons.length > 0) {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").show();
            } else {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").hide();
            }
        }
    };

    onItemRendered = () => {
        if (!LDH.IsObjectNull(this.commandButtons) && this.commandButtons.length > 0) {
            $(".leopard-settings-panel-contentgroup.data-source-id-field").show();
        } else {
            $(".leopard-settings-panel-contentgroup.data-source-id-field").hide();
        }
    };

    tabItemTitleRender = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        let that = this;

        if (data.e.tabId === 0) {
            setTimeout(function () {
                for (var j = 0; j < that.props.gridViewDefinition.columnDefinition.length; j++) {
                    let columnType = that.props.gridViewDefinition.columnDefinition[j].columnType;
                    let columnName = that.props.gridViewDefinition.columnDefinition[j].columnName;
                    let instance = that.uiObjectInstances["allowAutoComplete" + columnName];
                    if (LDH.IsObjectNull(instance)) continue;

                    if (LDH.IsValueEmpty(columnType) || columnType === "string" || columnType === "number") {
                        instance.option("disabled", false);
                    } else {
                        instance.option("disabled", true);
                    }

                    let allowAutoComplete = that.props.gridViewDefinition.columnDefinition[j].allowAutoComplete;
                    let instanceFO = that.uiObjectInstances["filterOperations" + columnName];
                    let instanceDO = that.uiObjectInstances["defaultOperation" + columnName];
                    let instanceACO = that.uiObjectInstances["autoCompleteOperation" + columnName];
                    if (LDH.IsObjectNull(instanceFO) || LDH.IsObjectNull(instanceDO) ||
                        LDH.IsObjectNull(instanceDO)) {
                        continue;
                    }

                    if (LDH.IsValueEmpty(allowAutoComplete) || allowAutoComplete === false) {
                        instanceFO.option("disabled", false);
                        instanceDO.option("disabled", false);
                        instanceACO.option("disabled", true);
                    } else {
                        instanceFO.option("disabled", true);
                        instanceDO.option("disabled", true);
                        instanceACO.option("disabled", false);
                    }
                }
            }, 100);
            return (
                <React.Fragment>
                    <div style={{overflowY: "auto"}}>
                        <table className="leopard-config-table">
                            <thead>
                            <tr>
                                <th className={"leopard-config-table-header"}>Column Name</th>
                                <th className={"leopard-config-table-header"}>Column Type</th>
                                <th className={"leopard-config-table-header"}>Alignment</th>
                                <th className={"leopard-config-table-header"}>Min Width</th>
                                <th className={"leopard-config-table-header"}>Width</th>
                                <th className={"leopard-config-table-header"} style={{minWidth: "110px"}}>
                                    <span>Order Index</span>
                                    <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                </th>
                                <th className={"leopard-config-table-header"} style={{minWidth: "130px"}}>
                                    <span>Filter Operations</span>
                                </th>
                                <th className={"leopard-config-table-header"} style={{minWidth: "130px"}}>
                                    <span>Default Operation</span>
                                </th>
                                <th className={"leopard-config-table-header"} style={{minWidth: "130px"}}>
                                    <LeopardTooltipWithText elementId={"autoCompleteOperationHeader_help"}
                                                            labelText={"AC Operation"} width={200}
                                                            text={"Auto-Complete Operation"}/>
                                    <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                </th>
                                <th className={"leopard-config-table-header"}
                                    style={{width: "100%", minWidth: "150px"}}>Custom Heading
                                </th>
                                <th className={"leopard-config-table-header"}><span>Sort Order</span>
                                    <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                </th>
                                <th className={"leopard-config-table-header"}>
                                    <LeopardTooltipWithText elementId={"autoCompleteHeader_help"}
                                                            labelText={"AC"} width={150}
                                                            text={"Auto-Complete"}/>
                                    <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                </th>
                                <th className={"leopard-config-table-header"}>
                                    <LeopardTooltipWithText elementId={"resizingHeader_help"}
                                                            labelText={"RE"} width={100}
                                                            text={"Resizing"}/>
                                </th>
                                <th className={"leopard-config-table-header"}>
                                    <LeopardTooltipWithText elementId={"sortingHeader_help"}
                                                            labelText={"SO"} width={100}
                                                            text={"Sorting"}/>
                                </th>
                                <th className={"leopard-config-table-header"}>
                                    <LeopardTooltipWithText elementId={"columnFilterHeader_help"}
                                                            labelText={"CF"} width={100}
                                                            text={"Column Filter"}/>
                                </th>
                                <th className={"leopard-config-table-header"}>
                                    <LeopardTooltipWithText elementId={"headerFilterHeader_help"}
                                                            labelText={"HF"} width={150}
                                                            text={"Header Filter"}/>
                                </th>
                                <th className={"leopard-config-table-header"}>
                                    <LeopardTooltipWithText elementId={"orderingHeader_help"}
                                                            labelText={"OR"} width={100}
                                                            text={"Ordering"}/>
                                </th>
                                <th className={"leopard-config-table-header"}>
                                    <LeopardTooltipWithText elementId={"fixedColumnHeader_help"}
                                                            labelText={"FC"} width={100}
                                                            text={"Fixed Column"}/>
                                </th>
                                <th className={"leopard-config-table-header"}>
                                    <LeopardTooltipWithText elementId={"visibleHeader_help"}
                                                            labelText={"VI"} width={100}
                                                            text={"Visible"}/>
                                </th>
                                <th className={"leopard-config-table-header"}>
                                    <LeopardTooltipWithText elementId={"enabledHeader_help"}
                                                            labelText={"EN"} width={100}
                                                            text={"Enabled"}/>
                                    <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <RenderGridViewColumnDefinition gridColumns={this.columnFieldList} state={this.state}
                                                            updateColumnDefinition={(e) => this.updateColumnDefinition(e)}
                                                            thisComp={this}/>
                            </tbody>
                        </table>
                        <div className="leopard-settings-warning-text" style={{display: "none"}}>
                            Warning: You have changed the Filter settings. The Advanced Filter has now been reset.
                        </div>
                    </div>
                    <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> Preview is either
                        not supported or not function properly. You'll need to save the configuration and reload the
                        Data View to get the best experience.
                    </div>
                </React.Fragment>
            );
        }
        if (data.e.tabId === 1) {
            setTimeout(function () {
                if (!LDH.IsObjectNull(that.uiObjectInstances["columnResizingMode"])) {
                    that.uiObjectInstances["columnResizingMode"].option(
                        "disabled", that.enableAdaptiveColumnOption);
                }
            }, 100);

            return (
                <React.Fragment>
                    <Box direction={'row'} width={'100%'}>
                        <Item baseSize={300}>
                            <div className="leopard-settings-panel-cell">
                                <div className="leopard-settings-panel-title">Adaptivity</div>
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"enableAdaptiveColumnOption_help"}
                                                                title={"Enable adaptive columns"}
                                                                text={"The grid view can adapt its layout to narrow screens. " +
                                                        "If screen width is not enough to display all columns, the widget hides them according to the specified priority. " +
                                                        "To view values of hidden columns, click the ellipsis button in the last column of the required row."}/>
                                        <span>Enable adaptive columns:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={this.selectionYesNo} displayExpr={'text'}
                                                   valueExpr={'value'} defaultValue={this.enableAdaptiveColumnOption}
                                                   onValueChanged={(e) => this.enableAdaptiveColumnsOnChange(e)}/>
                                    </div>
                                </div>
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"optimizePagerForLargeDataset_help"}
                                                                title={"Optimize pager for large dataset"}
                                                                text={"The grid view allows user to navigate data page by page and prevents user from jumping to a large page number. " +
                                                        "It is recommended to turn on this feature if you have 500,000+ rows of data."}/>
                                        <span>Optimize pager for large dataset:</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={this.selectionYesNo} displayExpr={'text'}
                                                   valueExpr={'value'} defaultValue={this.optimizePagerForLargeDataset}
                                                   onValueChanged={(e) => this.optimizePagerForLargeDatasetOnChange(e)}/>
                                    </div>
                                </div>
                            </div>
                        </Item>
                        <Item baseSize={300}>
                            <div className="leopard-settings-panel-cell">
                                <div className="leopard-settings-panel-title">General</div>
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"commandButtonsOnGridView_help"}
                                                                title={"Command buttons on grid view"}
                                                                text={"Enabling this feature will allow user to do CRUD (Create, Read, Update and Delete) operations on the grid view."}/>
                                        <span>Command buttons on grid view:</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div>
                                        <LeopardDesignerTagBox
                                            dropdownList={LeopardStaticUIConfig.StaticList_CommandButtonList}
                                            fieldValue={this.commandButtons} uiRef={null}
                                            fieldType={"command-buttons"}
                                            leopardDesignerTagBoxValueOnChange={
                                                (e) => this.leopardDesignerTagBoxValueOnChange(e)
                                            }/>
                                    </div>
                                </div>
                                <div className="leopard-settings-panel-contentgroup data-source-id-field">
                                    <div className="leopard-settings-panel-content-label">
                                        Specify a data source ID:
                                    </div>
                                    <div>
                                        <SelectBox dataSource={this.columnFieldListForDropdown} displayExpr={'text'}
                                                   valueExpr={'value'} defaultValue={this.dataSourceId}
                                                   onValueChanged={(e) => this.dataSourceIdOnChange(e)}/>
                                    </div>
                                </div>
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"columnResizingMode_help"}
                                                                title={"Column resizing mode"}
                                                                text={"The grid view allows a user to resize columns at runtime. They can be resized in two different modes: " +
                                                        "by changing the width of the next column or the width of the grid view."}/>
                                        <span>Column resizing mode:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={this.columnResizingModeSource} displayExpr={'text'}
                                                   valueExpr={'value'} defaultValue={this.columnResizingMode}
                                                   ref={(e) => this.addUIObjectInstance({
                                                       uiObjectName: "columnResizingMode", ref: e
                                                   })}
                                                   onValueChanged={(e) => this.columnResizingModeOnChange(e)}/>
                                    </div>
                                </div>
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"explicitFilterOption_help"}
                                                                title={"Explicit data filtering"}
                                                                text={"The grid view allows a user to filter values on multiple columns, and sends the " +
                                                        "request to the server by pressing the Enter key or clicking on Apply Filter button."}/>
                                        <span>Explicit data filtering:</span>
                                    </div>
                                    <div>
                                        <SelectBox dataSource={this.filterOptionSource} displayExpr={'text'}
                                                   valueExpr={'value'} defaultValue={this.explicitFilterOption}
                                                   onValueChanged={(e) => this.explicitFilterOptionOnChange(e)}/>
                                    </div>
                                </div>
                            </div>
                        </Item>
                        <Item baseSize={300}>
                            <div className="leopard-settings-panel-cell">
                                <div className="leopard-settings-panel-title">
                                    <span>Data Popup Editor</span>
                                    <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                </div>
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"disabledFieldsForPopupEditor_help"}
                                                                title={"Disabled fields"}
                                                                text={"Specifies which columns on the grid view should be disabled when a user editing a data row."}/>
                                        <span>Disabled fields:</span>
                                    </div>
                                    <div>
                                        <LeopardDesignerTagBox
                                            dropdownList={this.columnFieldListForDropdown}
                                            fieldValue={this.disabledFieldsForEditing}
                                            fieldType={"disabled-fields"} uiRef={null}
                                            leopardDesignerTagBoxValueOnChange={
                                                (e) => this.leopardDesignerTagBoxValueOnChange(e)
                                            }/>
                                    </div>
                                </div>
                                <div className="leopard-settings-panel-contentgroup">
                                    <div className="leopard-settings-panel-content-label">
                                        <LeopardTooltipWithIcon elementId={"hiddenFieldsForPopupEditor_help"}
                                                                title={"Hidden fields"}
                                                                text={"Specifies which columns on the grid view should be hidden when a user editing a data row."}/>
                                        <span>Hidden fields:</span>
                                    </div>
                                    <div>
                                        <LeopardDesignerTagBox
                                            dropdownList={this.columnFieldListForDropdown}
                                            fieldValue={this.hiddenFieldsForEditing}
                                            fieldType={"hidden-fields"} uiRef={null}
                                            leopardDesignerTagBoxValueOnChange={
                                                (e) => this.leopardDesignerTagBoxValueOnChange(e)
                                            }/>
                                    </div>
                                </div>
                            </div>
                        </Item>
                    </Box>
                    <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> Preview is either
                        not supported or not function properly. You'll need to save the configuration and reload the
                        Data View to get the best experience.
                    </div>
                </React.Fragment>
            );
        }
        if (data.e.tabId === 2) {
            let that = this;
            if (this.hasColumnFilterChanged === true || this.filterFields.length === 0) {
                that.filterFields = [];
                for (var i = 0; i < that.columnFieldList.length; i++) {
                    let gridColumnName = that.columnFieldList[i];
                    let columnDataType = "string";
                    let allowFiltering = true;

                    for (var j = 0; j < that.columnDefinitionToSave.length; j++) {
                        let columnName = that.columnDefinitionToSave[j].columnName;
                        if (columnName !== gridColumnName) continue;
                        if (that.columnDefinitionToSave[j].columnType !== undefined &&
                            that.columnDefinitionToSave[j].columnType !== "") {
                            columnDataType = that.columnDefinitionToSave[j].columnType;
                        }
                        if (that.columnDefinitionToSave[j].allowFiltering !== undefined &&
                            that.columnDefinitionToSave[j].allowFiltering === false) {
                            allowFiltering = false;
                        }
                    }

                    if (allowFiltering === true) {
                        if (columnDataType === "date") {
                            that.filterFields.push({
                                dataField: that.columnFieldList[i],
                                dataType: columnDataType,
                                format: "dd/MM/yyyy"
                            });
                        } else if (columnDataType === "datetime") {
                            that.filterFields.push({
                                dataField: that.columnFieldList[i],
                                dataType: columnDataType,
                                format: "dd/MM/yyyy HH:mm:ss"
                            });
                        } else {
                            that.filterFields.push({
                                dataField: that.columnFieldList[i],
                                dataType: columnDataType
                            });
                        }
                    }
                }
                this.hasColumnFilterChanged = false;
            }

            this.filterBuilderPreviewValue = this.filterBuilderValue;
            return (
                <React.Fragment>
                    <div style={{padding: "20px"}}>
                        <FilterBuilder fields={this.filterFields} defaultValue={this.filterBuilderValue}
                                       onValueChanged={(e) => this.filterBuilderOnValueChanged(e)}
                                       groupOperations={["and", "or"]} maxGroupLevel={0}/>
                    </div>
                </React.Fragment>
            )
        }
        return null;
    };

    render() {
        let gridViewState = this.props.state.gridViewState;
        let currentState = gridViewState.filter(gridView => {
            return gridView.gridViewId === this.props.gridViewId;
        });

        if (this.props.state.gridViewState.length === 0 || currentState === undefined ||
            this.props.gridViewBuilderVisible === false) {
            return ("");
        }
        currentState = currentState[0];
        return (
            <React.Fragment>
                <div style={{width: "100%", overflowX: "auto"}}>
                    <div className={"leopard-editgridview-title"}>Configure Grid</div>
                    <div className={"leopard-gray-panel-container"} style={{marginTop: "5px"}}>
                        <TabPanel dataSource={this.tabPanelDataSource} onOptionChanged={this.onOptionChanged}
                                  loop={true} itemTitleRender={this.tabItemTitleRender}
                                  onItemRendered={this.onItemRendered}
                                  itemComponent={(e) => this.tabItemContent({e: e, currentState: currentState})}
                                  animationEnabled={true} swipeEnabled={false} showNavButtons={true}
                        />
                    </div>
                </div>
                <div style={{padding: "10px 0px 10px 10px", minWidth: "900px", clear: "both"}}>
                    <span style={{padding: "0 10px 0 0"}}>
                        <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Preview"}
                                onClick={() => this.previewButtonOnClick(null, this.filterBuilderPreviewValue)}/>
                    </span>
                    <span style={{padding: "0 10px 0 0"}}>
                        <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Save"}
                                onClick={() => this.saveButtonOnClick()}></Button>
                    </span>
                    <span style={{padding: "0 0 0 0"}}>
                        <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Close"}
                                onClick={(e) => this.cancelButtonOnClick(e)}/>
                    </span>
                    <span className="leopard-columndesigner-loading-progress"
                          style={{
                              marginRight: "15px",
                              marginLeft: "30px",
                              position: "relative",
                              top: "5px",
                              visibility: "hidden"
                          }}>
                        <i className="fas fa-spinner fa-pulse" style={{color: "#FF8000", fontSize: "25px"}}></i>
                    </span>
                </div>
                <hr/>
                <div className={"leopard-editgridview-title"} style={{marginBottom: "10px", marginTop: "20px"}}>
                    Preview Layout
                </div>
            </React.Fragment>
        );
    }
}

const RenderGridViewColumnDefinition = ({gridColumns, state, updateColumnDefinition, thisComp}) => {
    let columnsToBind = [];

    for (var i = 0; i < gridColumns.length; i++) {
        columnsToBind.push({columnName: gridColumns[i]});
    }
    let returnHtml = columnsToBind.map(column => {
        let columnDef = thisComp.columnDefinitionToSave.filter(c => {
            return c.columnName === column.columnName;
        });

        let allowAutoComplete = false;
        if (columnDef.length > 0 && columnDef[0].allowAutoComplete !== undefined) {
            allowAutoComplete = columnDef[0].allowAutoComplete;
        }

        let allowResizing = true;
        if (columnDef.length > 0 && columnDef[0].allowResizing !== undefined) {
            allowResizing = columnDef[0].allowResizing;
        }

        let allowSorting = true;
        if (columnDef.length > 0 && columnDef[0].allowSorting !== undefined) {
            allowSorting = columnDef[0].allowSorting;
        }

        let allowFiltering = true;
        if (columnDef.length > 0 && columnDef[0].allowFiltering !== undefined) {
            allowFiltering = columnDef[0].allowFiltering;
        }

        let allowHeaderFilter = true;
        if (columnDef.length > 0 && columnDef[0].allowHeaderFilter !== undefined) {
            allowHeaderFilter = columnDef[0].allowHeaderFilter;
        }

        let allowReordering = true;
        if (columnDef.length > 0 && columnDef[0].allowReordering !== undefined) {
            allowReordering = columnDef[0].allowReordering;
        }

        let isFixed = false;
        if (columnDef.length > 0 && columnDef[0].isFixed !== undefined) {
            isFixed = columnDef[0].isFixed;
        }

        let isVisible = true;
        if (columnDef.length > 0 && columnDef[0].isVisible !== undefined) {
            isVisible = columnDef[0].isVisible;
        }

        let isEnabled = true;
        if (columnDef.length > 0 && columnDef[0].isEnabled !== undefined) {
            isEnabled = columnDef[0].isEnabled;
        }

        let visibleIndex = null;
        if (columnDef.length > 0 && columnDef[0].visibleIndex !== undefined) {
            visibleIndex = columnDef[0].visibleIndex;
        }

        let columnCustomHeader = "";
        if (columnDef.length > 0 && columnDef[0].columnCustomHeader !== undefined) {
            columnCustomHeader = columnDef[0].columnCustomHeader;
        }

        let minimumWidth = 50;
        if (columnDef.length > 0 && columnDef[0].minimumWidth !== undefined) {
            minimumWidth = columnDef[0].minimumWidth;
        }

        let width = null;
        if (columnDef.length > 0 && columnDef[0].width !== undefined) {
            width = columnDef[0].width;
        }

        let columnAlign = "left";
        if (columnDef.length > 0 && columnDef[0].columnAlign !== undefined) {
            columnAlign = columnDef[0].columnAlign;
        }

        let columnType = "string";
        if (columnDef.length > 0 && columnDef[0].columnType !== undefined) {
            columnType = columnDef[0].columnType;
        }

        let sortOrder = "none";
        if (columnDef.length > 0 && columnDef[0].sortOrder !== undefined) {
            sortOrder = columnDef[0].sortOrder;
        }

        let defaultOperation = "contains";
        if (columnDef.length > 0 && columnDef[0].defaultOperation !== undefined) {
            defaultOperation = columnDef[0].defaultOperation;
        }

        let autoCompleteOperation = "startswith";
        if (columnDef.length > 0 && columnDef[0].autoCompleteOperation !== undefined) {
            autoCompleteOperation = columnDef[0].autoCompleteOperation;
        }

        let filterOperations = [];
        if (columnDef.length > 0 && columnDef[0].filterOperations !== undefined) {
            filterOperations = columnDef[0].filterOperations;
        }
        return (
            <tr key={column.columnName} className={"leopard-configure-grid-tablerow"}>
                <td className={"leopard-table-column-columnname"} title={column.columnName}
                    style={{paddingLeft: "10px"}}>
                    {column.columnName}
                </td>
                <td>
                    <SelectBox dataSource={thisComp.columnTypeDataSource} valueExpr={'value'} displayExpr={'text'}
                               defaultValue={columnType} width={100}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "columnType"
                               })}/>
                </td>
                <td><SelectBox dataSource={thisComp.columnAlignmentDataSource} valueExpr={'value'} displayExpr={'text'}
                               defaultValue={columnAlign}
                               width={90}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "columnAlign"
                               })}/></td>

                <td><NumberBox min={10} defaultValue={minimumWidth} showSpinButtons={true} width={90}
                               placeholder={"Min 10"}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "minimumWidth"
                               })}/></td>

                <td><NumberBox min={10} defaultValue={width} showSpinButtons={true} width={90} placeholder={"Min 10"}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "width"
                               })}/></td>

                <td><NumberBox defaultValue={visibleIndex} showSpinButtons={true} placeholder={"From 0"} min={0}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "visibleIndex"
                               })}/></td>

                <td><LeopardDesignerTagBox dropdownList={thisComp.filterOperationSource}
                                           fieldValue={filterOperations} fieldType={"filterOperations"}
                                           addUIObjectInstance={(e) => thisComp.addUIObjectInstance(e)}
                                           columnName={column.columnName}
                                           leopardDesignerTagBoxValueOnChange={
                                               (e) => updateColumnDefinition({
                                                   e: e,
                                                   column: column,
                                                   updateType: "filterOperations"
                                               })
                                           }/></td>

                <td><SelectBox dataSource={thisComp.filterOperationSource} valueExpr={'value'} displayExpr={'text'}
                               defaultValue={defaultOperation} width={130} ref={(e) => thisComp.addUIObjectInstance({
                    uiObjectName: "defaultOperation" + column.columnName, ref: e
                })}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "defaultOperation"
                               })}/></td>

                <td><SelectBox dataSource={thisComp.autoCompleteOperationSource} valueExpr={'value'}
                               displayExpr={'text'}
                               defaultValue={autoCompleteOperation} width={130}
                               ref={(e) => thisComp.addUIObjectInstance({
                                   uiObjectName: "autoCompleteOperation" + column.columnName, ref: e
                               })}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "autoCompleteOperation"
                               })}/></td>

                <td><TextBox defaultValue={columnCustomHeader} maxLength={50}
                             onValueChanged={(e) => updateColumnDefinition({
                                 e: e,
                                 column: column,
                                 updateType: "columnCustomHeader"
                             })}/></td>

                <td><SelectBox dataSource={thisComp.sortOrderDataSource} valueExpr={'value'} displayExpr={'text'}
                               defaultValue={sortOrder} width={100}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "sortOrder"
                               })}/>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox defaultValue={allowAutoComplete} ref={(e) => thisComp.addUIObjectInstance({
                        uiObjectName: "allowAutoComplete" + column.columnName, ref: e
                    })} onValueChanged={(e) => updateColumnDefinition({
                        e: e,
                        column: column,
                        updateType: "allowAutoComplete"
                    })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox defaultValue={allowResizing}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowResizing"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox defaultValue={allowSorting}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowSorting"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox defaultValue={allowFiltering}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowFiltering"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox defaultValue={allowHeaderFilter}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowHeaderFilter"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox defaultValue={allowReordering}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowReordering"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox defaultValue={isFixed}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "isFixed"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox defaultValue={isVisible}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "isVisible"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox defaultValue={isEnabled}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "isEnabled"
                              })}/></td>
            </tr>);
    });

    return (<React.Fragment>{returnHtml}</React.Fragment>);
};

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

const SendDataToReducer = (dispatch) => {
    return {
        UpdateGridViewDefinition: (data, id) => {
            dispatch(UpdateGridViewDefinition(data, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardColumnDesigner);