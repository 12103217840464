import React from 'react';

class LeopardTestHelper extends React.Component {

    static GetTestUserProfile = () => {
        return {
            "Type": "User",
            "Roles": [
                {
                    "Type": "UserRole",
                    "ID": "e7110d11-acbe-4f44-bd0d-6ff7a49c1400",
                    "Parent": "5EE9778C-0890-4607-ADD0-E2C20DABD33E",
                    "Name": "CC-Admin"
                }
            ],
            "Addresses": [
                {
                    "Type": "Address",
                    "LastActionTime": "2019-03-13 00:00:01",
                    "Suburb": "Sydney",
                    "CreatedTime": "2019-03-13 00:00:01",
                    "Country": "AU",
                    "Region": "NSW",
                    "PostCode": "2000",
                    "State": "NSW",
                    "ID": "e7110d11-acbe-4f44-bd0d-6ff7a49c1400",
                    "Parent": "VIC",
                    "Name": "P1Address"
                }
            ],
            "LastLoginTime": "2019-04-16T05:11:55.727Z",
            "CreatedTime": "2019-04-04T23:54:27.532Z",
            "Status": "Offline",
            "ID": "e7110d11-acbe-4f44-bd0d-6ff7a49c1400",
            "Parent": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
            "Name": "demo_alex",
            "ParentGroup": {
                "Type": "Group",
                "Configs": [
                    {
                        "AppConfig": {
                            "contentPageType": "dashboard-view",
                            "dashboardLayout": [
                                {
                                    "static": false,
                                    "minH": 8,
                                    "minW": 3,
                                    "w": 24,
                                    "moved": false,
                                    "h": 12,
                                    "x": 0,
                                    "y": 0,
                                    "i": "0",
                                    "definition": {
                                        "dataViewId": "24b25bbc78dd8d914d078ae0de21b38f",
                                        "dataViewNote": {
                                            "chartType": "bar-chart"
                                        },
                                        "dashboardItemId": "f86c5e9771d846856c24a39cf81b83cb",
                                        "dataSourceUrl": "Jobs",
                                        "color": "leopard-bgcolor-gray",
                                        "dataViewName": "Jobs View",
                                        "dashboardType": "datagrid"
                                    }
                                },
                                {
                                    "static": false,
                                    "minH": 8,
                                    "minW": 3,
                                    "w": 12,
                                    "moved": false,
                                    "h": 12,
                                    "x": 24,
                                    "y": 0,
                                    "i": "1",
                                    "definition": {
                                        "dataViewId": "129730a4854ea9b993edc48de9325062",
                                        "dataViewNote": {
                                            "chartType": "bar-chart"
                                        },
                                        "dashboardItemId": "e8fd9794f33ad754273040f4ccaf353c",
                                        "dataSourceUrl": "Jobs",
                                        "color": "leopard-bgcolor-gray",
                                        "dataViewName": "Bar Chart",
                                        "dashboardType": "chart"
                                    }
                                }
                            ],
                            "dashboardId": "39bbb9b4b8b2226b601d3f41ecee753e",
                            "dashboardNote": null,
                            "dashboardName": "My Dashboard"
                        },
                        "ID": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Type": "Config",
                        "Parent": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Name": "CC_Dashboard_39bbb9b4b8b2226b601d3f41ecee753e"
                    },
                    {
                        "AppConfig": {
                            "dataViewId": "129730a4854ea9b993edc48de9325062",
                            "dataViewNote": {
                                "chartType": "bar-chart",
                                "chartDefinition": {
                                    "chartBgColorForExport": "#FFFFFF",
                                    "chartAllowMouseWheel": true,
                                    "labelHorizontalOffset": 0,
                                    "legendItemTextPosition": "left",
                                    "argAxisShowZero": true,
                                    "legendItemsAlignment": "left",
                                    "seriesHoverMode": "onlyPoint",
                                    "chartContainerBgColor": "#FFFFFF",
                                    "valueAxisShowZero": true,
                                    "argAxisEnableAutoBreak": false,
                                    "chartMaxBubbleSize": 0.2,
                                    "legendPaddingLeftRight": 10,
                                    "legendHorizontalAlignment": "left",
                                    "chartRedrawOnResize": true,
                                    "tooltipPaddingTopBottom": 10,
                                    "labelShowForZero": true,
                                    "seriesArgumentField": "Name",
                                    "chartMinBubbleSize": 12,
                                    "seriesTypeOfSeries": "bar",
                                    "chartDragToZoom": false,
                                    "chartScrollbarOffset": 5,
                                    "seriesIgnoreEmptyPoint": true,
                                    "pointVisibility": true,
                                    "seriesSmallValueGroupingTopCount": 8,
                                    "seriesSmallValueGroupingMode": "topN",
                                    "valueAxisValueType": "numeric",
                                    "valueAxisHighAverageText": "High Average",
                                    "chartBarGroupPadding": 0.3,
                                    "argAxisMaxAutoBreakCount": 4,
                                    "valueAxisEndOnTick": false,
                                    "tooltipArrowLength": 10,
                                    "argAxisValueType": "string",
                                    "pointSizeOfPoint": 12,
                                    "chartScrollbarPosition": "bottom",
                                    "argAxisEndOnTick": false,
                                    "valueAxisPosition": "left",
                                    "chartPalette": "Soft Pastel",
                                    "argAxisAllowDecimals": true,
                                    "pointSelectionMode": "onlyPoint",
                                    "valueAxisLowAverageText": "Low Average",
                                    "chartPointSelectionMode": "single",
                                    "chartSeriesSelectionMode": "single",
                                    "legendMargin": 10,
                                    "seriesValueField": "Completed",
                                    "legendColumnItemSpacing": 20,
                                    "legendVisibility": true,
                                    "chartNegativeValuesAsZeroes": false,
                                    "chartResolveLabelOverlapping": "none",
                                    "legendMarkerSize": 20,
                                    "pointHoverMode": "onlyPoint",
                                    "tooltipEnabled": false,
                                    "valueAxisEnableAutoBreak": false,
                                    "seriesSelectionMode": "onlyPoint",
                                    "tooltipPaddingLeftRight": 10,
                                    "legendRowItemSpacing": 8,
                                    "valueAxisAllowDecimals": true,
                                    "legendOrientation": "horizontal",
                                    "labelPosition": "outside",
                                    "chartTitle": "Bar Chart",
                                    "argAxisPosition": "bottom",
                                    "chartShowScrollbar": false,
                                    "labelVerticalOffset": 0,
                                    "chartEnableExport": false,
                                    "seriesStyleCornerRadius": 0,
                                    "labelRotationAngle": 0,
                                    "legendPaddingTopBottom": 10,
                                    "chartMarginForExport": 10,
                                    "valueAxisInvertAxis": false,
                                    "valueAxisMaxAutoBreakCount": 4,
                                    "chartPaletteExtMode": "blend",
                                    "legendHoverMode": "includePoints",
                                    "tooltipLocation": "center",
                                    "seriesStyleDashStyle": "solid",
                                    "labelAlignment": "left",
                                    "tooltipColor": "#FFFFFF",
                                    "labelVisibility": true,
                                    "legendPosition": "outside",
                                    "chartAllowTouchGuestures": true,
                                    "legendVerticalAlignment": "bottom",
                                    "argAxisInvertAxis": false,
                                    "pointCustomSymbol": "circle"
                                }
                            },
                            "dataSourceId": null,
                            "contentPageType": "single-data-view",
                            "dataSourceUrl": "Jobs",
                            "dataViewName": "Bar Chart",
                            "dataViewType": "chart"
                        },
                        "ID": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Type": "Config",
                        "Parent": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Name": "CC_DataView_129730a4854ea9b993edc48de9325062"
                    },
                    {
                        "AppConfig": {
                            "dataViewId": "24b25bbc78dd8d914d078ae0de21b38f",
                            "dataViewNote": {
                                "styleDefinition": null,
                                "gridViewDefinition": {
                                    "allowEditing": false,
                                    "columnResizingMode": "nextColumn",
                                    "styleDefinition": null,
                                    "allowDropping": false,
                                    "allowDragging": false,
                                    "columnDefinition": [],
                                    "version": 1,
                                    "adaptiveColumn": true
                                }
                            },
                            "dataSourceId": null,
                            "contentPageType": "single-data-view",
                            "dataSourceUrl": "Jobs",
                            "dataViewName": "Jobs View",
                            "dataViewType": "datagrid"
                        },
                        "ID": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Type": "Config",
                        "Parent": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Name": "CC_DataView_24b25bbc78dd8d914d078ae0de21b38f"
                    },
                    {
                        "AppConfig": [
                            {
                                "parentMenuItemName": "Data Views",
                                "dataViewId": "24b25bbc78dd8d914d078ae0de21b38f",
                                "dataViewNote": {
                                    "chartType": "bar-chart"
                                },
                                "menuItemType": "data-view",
                                "contentPageType": "single-data-view",
                                "dataSourceUrl": "Jobs",
                                "menuItemName": "Jobs View",
                                "dataViewType": "datagrid",
                                "menuItemId": "92e377c1982269b781ca46d38da21aa0"
                            },
                            {
                                "parentMenuItemName": "Data Views",
                                "dataViewId": "129730a4854ea9b993edc48de9325062",
                                "dataViewNote": {
                                    "chartType": "bar-chart"
                                },
                                "menuItemType": "data-view",
                                "contentPageType": "single-data-view",
                                "dataSourceUrl": "Jobs",
                                "menuItemName": "Bar Chart",
                                "dataViewType": "chart",
                                "menuItemId": "d30e50f61c1d13c2b9946ddba7a90640"
                            },
                            {
                                "parentMenuItemName": "Dashboards",
                                "menuItemType": "dashboard",
                                "contentPageType": "dashboard-view",
                                "dashboardId": "39bbb9b4b8b2226b601d3f41ecee753e",
                                "menuItemId": "2c10935abcf1eebc09968c283f015ae0",
                                "menuItemName": "My Dashboard"
                            }
                        ],
                        "ID": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Type": "Config",
                        "Parent": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Name": "CC_Main_LeftMenuItems"
                    },
                    {
                        "AppConfig": {
                            "Test": "TEst",
                            "Test2": "Test2"
                        },
                        "ID": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Type": "Config",
                        "Parent": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                        "Name": "CC_config_Test1"
                    }
                ],
                "LastActionTime": "2019-03-13 00:00:01",
                "CreatedTime": "2019-03-13 00:00:01",
                "AppConfig": {
                    "Settings1": "A",
                    "Settings2": "D",
                    "Settings3": "C"
                },
                "Description": "ACME Demo Organisation",
                "ID": "378B2BBA-DDC5-47FD-A4D9-03EB4B32182A",
                "Parent": "23D47947-35A2-4669-80EE-D70C469176B4",
                "Name": "LEOP-DIST-ACMEDEMO",
                "ParentGroup": {
                    "Type": "Group",
                    "Configs": [
                        {
                            "AppConfig2": "asfasfasfa",
                            "AppConfig": {
                                "Setting1": "asfasfsf",
                                "Setting2": 21212
                            },
                            "ID": "23D47947-35A2-4669-80EE-D70C469176B4",
                            "Type": "Config",
                            "Parent": "23D47947-35A2-4669-80EE-D70C469176B4",
                            "Name": "CC-UI-View79"
                        }
                    ],
                    "LastActionTime": "2019-03-13 00:00:01",
                    "CreatedTime": "2019-03-13 00:00:01",
                    "AppConfig": {
                        "Settings1": "A",
                        "Settings2": "B"
                    },
                    "Description": "Leopard Distribution Organisation",
                    "ID": "23D47947-35A2-4669-80EE-D70C469176B4",
                    "Parent": "00000000-0000-0000-0000-000000000000",
                    "Name": "LEOP-DIST"
                }
            }
        };
    };

    static GetTestDataTableColumns = () => {
        return ["JobNumber", "Name", "Description", "Driver", "LastStop", "Pending", "InProgress", "Completed", "Status"];
    };

    static GetTestDataTable = () => {
        return {
            "items": [
                {
                    "JobNumber": "J0001",
                    "Name": "Job 1",
                    "Description": "Description 1",
                    "Driver": "Driver 1",
                    "LastStop": "",
                    "Pending": "1",
                    "InProgress": "1",
                    "Completed": "3",
                    "Status": ""
                },
                {
                    "JobNumber": "J0002",
                    "Name": "Job 2",
                    "Description": "Description 2",
                    "Driver": "Driver 2",
                    "LastStop": "",
                    "Pending": "-2",
                    "InProgress": "3",
                    "Completed": "-5",
                    "Status": ""
                },
                {
                    "JobNumber": "J0003",
                    "Name": "Job 3",
                    "Description": "Description 3",
                    "Driver": "Driver 3",
                    "LastStop": "",
                    "Pending": "5",
                    "InProgress": "-3",
                    "Completed": "3",
                    "Status": ""
                },
                {
                    "JobNumber": "J0004",
                    "Name": "Job 4",
                    "Description": "Description 4",
                    "Driver": "Driver 4",
                    "LastStop": "",
                    "Pending": "3",
                    "InProgress": "2",
                    "Completed": "5",
                    "Status": ""
                },
                {
                    "JobNumber": "J0005",
                    "Name": "Job 5",
                    "Description": "Description 5",
                    "Driver": "Driver 5",
                    "LastStop": "",
                    "Pending": "-4",
                    "InProgress": "-5",
                    "Completed": "4",
                    "Status": ""
                },
                {
                    "JobNumber": "J0006",
                    "Name": "Job 6",
                    "Description": "Description 6",
                    "Driver": "Driver 6",
                    "LastStop": "",
                    "Pending": "-1",
                    "InProgress": "7",
                    "Completed": "-6",
                    "Status": ""
                },
                {
                    "JobNumber": "J0007",
                    "Name": "Job 7",
                    "Description": "Description 7",
                    "Driver": "Driver 7",
                    "LastStop": "",
                    "Pending": "7",
                    "InProgress": "4",
                    "Completed": "2",
                    "Status": ""
                },
                {
                    "JobNumber": "J0008",
                    "Name": "Job 8",
                    "Description": "Description 8",
                    "Driver": "Driver 8",
                    "LastStop": "",
                    "Pending": "3",
                    "InProgress": "-8",
                    "Completed": "-3",
                    "Status": ""
                },
                {
                    "JobNumber": "J0009",
                    "Name": "Job 9",
                    "Description": "Description 9",
                    "Driver": "Driver 9",
                    "LastStop": "",
                    "Pending": "5",
                    "InProgress": "5",
                    "Completed": "6",
                    "Status": ""
                },
                {
                    "JobNumber": "J0010",
                    "Name": "Job 10",
                    "Description": "Description 10",
                    "Driver": "Driver 10",
                    "LastStop": "",
                    "Pending": "-4",
                    "InProgress": "7",
                    "Completed": "3",
                    "Status": ""
                },
                {
                    "JobNumber": "J0011",
                    "Name": "Job 11",
                    "Description": "Description 11",
                    "Driver": "Driver 11",
                    "LastStop": "",
                    "Pending": "8",
                    "InProgress": "-4",
                    "Completed": "-6",
                    "Status": ""
                },
                {
                    "JobNumber": "J0012",
                    "Name": "Job 12",
                    "Description": "Description 12",
                    "Driver": "Driver 12",
                    "LastStop": "",
                    "Pending": "6",
                    "InProgress": "4",
                    "Completed": "8",
                    "Status": ""
                },
                {
                    "JobNumber": "J0013",
                    "Name": "Job 13",
                    "Description": "Description 13",
                    "Driver": "Driver 13",
                    "LastStop": "",
                    "Pending": "-4",
                    "InProgress": "-1",
                    "Completed": "0",
                    "Status": ""
                },
                {
                    "JobNumber": "J0014",
                    "Name": "Job 14",
                    "Description": "Description 14",
                    "Driver": "Driver 14",
                    "LastStop": "",
                    "Pending": "4",
                    "InProgress": "8",
                    "Completed": "6",
                    "Status": ""
                },
                {
                    "JobNumber": "J0015",
                    "Name": "Job 15",
                    "Description": "Description 15",
                    "Driver": "Driver 15",
                    "LastStop": "",
                    "Pending": "1",
                    "InProgress": "6",
                    "Completed": "-4",
                    "Status": ""
                },
                {
                    "JobNumber": "J0016",
                    "Name": "Job 16",
                    "Description": "Description 16",
                    "Driver": "Driver 16",
                    "LastStop": "",
                    "Pending": "-3",
                    "InProgress": "-1",
                    "Completed": "8",
                    "Status": ""
                },
                {
                    "JobNumber": "J0017",
                    "Name": "Job 17",
                    "Description": "Description 17",
                    "Driver": "Driver 17",
                    "LastStop": "",
                    "Pending": "6",
                    "InProgress": "3",
                    "Completed": "-2",
                    "Status": ""
                },
                {
                    "JobNumber": "J0018",
                    "Name": "Job 18",
                    "Description": "Description 18",
                    "Driver": "Driver 18",
                    "LastStop": "",
                    "Pending": "4",
                    "InProgress": "8",
                    "Completed": "6",
                    "Status": ""
                },
                {
                    "JobNumber": "J0019",
                    "Name": "Job 19",
                    "Description": "Description 19",
                    "Driver": "Driver 19",
                    "LastStop": "",
                    "Pending": "4",
                    "InProgress": "-9",
                    "Completed": "9",
                    "Status": ""
                },
                {
                    "JobNumber": "J0020",
                    "Name": "Job 20",
                    "Description": "Description 20",
                    "Driver": "Driver 20",
                    "LastStop": "",
                    "Pending": "3",
                    "InProgress": "8",
                    "Completed": "-3",
                    "Status": ""
                }
            ],
            "totalCount": 20
        };
    };

    static GetTestDataTableForChart = () => {
        return [
            {
                "JobNumber": "J0001",
                "Name": "Job 1",
                "Description": "Description 1",
                "Driver": "Driver 1",
                "LastStop": "",
                "Pending": "1",
                "InProgress": "1",
                "Completed": "3",
                "Status": ""
            },
            {
                "JobNumber": "J0002",
                "Name": "Job 2",
                "Description": "Description 2",
                "Driver": "Driver 2",
                "LastStop": "",
                "Pending": "-2",
                "InProgress": "3",
                "Completed": "-5",
                "Status": ""
            },
            {
                "JobNumber": "J0003",
                "Name": "Job 3",
                "Description": "Description 3",
                "Driver": "Driver 3",
                "LastStop": "",
                "Pending": "5",
                "InProgress": "-3",
                "Completed": "3",
                "Status": ""
            },
            {
                "JobNumber": "J0004",
                "Name": "Job 4",
                "Description": "Description 4",
                "Driver": "Driver 4",
                "LastStop": "",
                "Pending": "3",
                "InProgress": "2",
                "Completed": "5",
                "Status": ""
            },
            {
                "JobNumber": "J0005",
                "Name": "Job 5",
                "Description": "Description 5",
                "Driver": "Driver 5",
                "LastStop": "",
                "Pending": "-4",
                "InProgress": "-5",
                "Completed": "4",
                "Status": ""
            },
            {
                "JobNumber": "J0006",
                "Name": "Job 6",
                "Description": "Description 6",
                "Driver": "Driver 6",
                "LastStop": "",
                "Pending": "-1",
                "InProgress": "7",
                "Completed": "-6",
                "Status": ""
            },
            {
                "JobNumber": "J0007",
                "Name": "Job 7",
                "Description": "Description 7",
                "Driver": "Driver 7",
                "LastStop": "",
                "Pending": "7",
                "InProgress": "4",
                "Completed": "2",
                "Status": ""
            },
            {
                "JobNumber": "J0008",
                "Name": "Job 8",
                "Description": "Description 8",
                "Driver": "Driver 8",
                "LastStop": "",
                "Pending": "3",
                "InProgress": "-8",
                "Completed": "-3",
                "Status": ""
            },
            {
                "JobNumber": "J0009",
                "Name": "Job 9",
                "Description": "Description 9",
                "Driver": "Driver 9",
                "LastStop": "",
                "Pending": "5",
                "InProgress": "5",
                "Completed": "6",
                "Status": ""
            },
            {
                "JobNumber": "J0010",
                "Name": "Job 10",
                "Description": "Description 10",
                "Driver": "Driver 10",
                "LastStop": "",
                "Pending": "-4",
                "InProgress": "7",
                "Completed": "3",
                "Status": ""
            },
            {
                "JobNumber": "J0011",
                "Name": "Job 11",
                "Description": "Description 11",
                "Driver": "Driver 11",
                "LastStop": "",
                "Pending": "8",
                "InProgress": "-4",
                "Completed": "-6",
                "Status": ""
            },
            {
                "JobNumber": "J0012",
                "Name": "Job 12",
                "Description": "Description 12",
                "Driver": "Driver 12",
                "LastStop": "",
                "Pending": "6",
                "InProgress": "4",
                "Completed": "8",
                "Status": ""
            },
            {
                "JobNumber": "J0013",
                "Name": "Job 13",
                "Description": "Description 13",
                "Driver": "Driver 13",
                "LastStop": "",
                "Pending": "-4",
                "InProgress": "-1",
                "Completed": "0",
                "Status": ""
            },
            {
                "JobNumber": "J0014",
                "Name": "Job 14",
                "Description": "Description 14",
                "Driver": "Driver 14",
                "LastStop": "",
                "Pending": "4",
                "InProgress": "8",
                "Completed": "6",
                "Status": ""
            },
            {
                "JobNumber": "J0015",
                "Name": "Job 15",
                "Description": "Description 15",
                "Driver": "Driver 15",
                "LastStop": "",
                "Pending": "1",
                "InProgress": "6",
                "Completed": "-4",
                "Status": ""
            },
            {
                "JobNumber": "J0016",
                "Name": "Job 16",
                "Description": "Description 16",
                "Driver": "Driver 16",
                "LastStop": "",
                "Pending": "-3",
                "InProgress": "-1",
                "Completed": "8",
                "Status": ""
            },
            {
                "JobNumber": "J0017",
                "Name": "Job 17",
                "Description": "Description 17",
                "Driver": "Driver 17",
                "LastStop": "",
                "Pending": "6",
                "InProgress": "3",
                "Completed": "-2",
                "Status": ""
            },
            {
                "JobNumber": "J0018",
                "Name": "Job 18",
                "Description": "Description 18",
                "Driver": "Driver 18",
                "LastStop": "",
                "Pending": "4",
                "InProgress": "8",
                "Completed": "6",
                "Status": ""
            },
            {
                "JobNumber": "J0019",
                "Name": "Job 19",
                "Description": "Description 19",
                "Driver": "Driver 19",
                "LastStop": "",
                "Pending": "4",
                "InProgress": "-9",
                "Completed": "9",
                "Status": ""
            },
            {
                "JobNumber": "J0020",
                "Name": "Job 20",
                "Description": "Description 20",
                "Driver": "Driver 20",
                "LastStop": "",
                "Pending": "3",
                "InProgress": "8",
                "Completed": "-3",
                "Status": ""
            }
        ];
    };
}

export default LeopardTestHelper;